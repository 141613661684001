import headerStyles from "./Header.module.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { StyledMenu } from "./StyledMenu";
import { Router, Route, useNavigate } from "react-router-dom";
import { RootState } from "../store";
import meetus1 from "./meetus_1.webp";
import meetus2 from "./meetus_2.webp";
import meetus3 from "./meetus_3.webp";
import getinvolve1 from "./getinvolve_1.webp";
import getinvolve2 from "./getinvolve_2.webp";
import getinvolve3 from "./getinvolve_3.webp";
import { Colors } from "react-native/Libraries/NewAppScreen";

const useStylesForPointer: any = makeStyles({
  popOverRoot: {
    pointerEvents: "none",
  },
});

export default function CustomizedMenus(props: any) {
  let currentlyHovering: Boolean = false;
  const styles = useStylesForPointer();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let textDecoStyle: string = "none";
  const navigate = useNavigate();
  const child = props.lst[0];
  // console.log("child: ", child);
  const isPhone = window.matchMedia("(max-width: 768px)").matches;
  const [isMenuExpand2, setIsMenuExpand2] = useState<boolean>(false);
  const [isEng, setIsEng] = useState(true);
  const language = useSelector((state: RootState) => state.language.language);

  interface PhoneSubmenuImages {
    meetus1: string;
    meetus2: string;
    meetus3: string;
    getinvolve1: string;
    getinvolve2: string;
    getinvolve3: string;
  }

  const phoneSubmenuImg: PhoneSubmenuImages = {
    meetus1,
    meetus2,
    meetus3,
    getinvolve1,
    getinvolve2,
    getinvolve3,
  };
  // const phoneSubmenuImg: any = {
  //   meetus1,
  //   meetus2,
  //   meetus3,
  //   getinvolve1,
  //   getinvolve2,
  //   getinvolve3,
  // };
  let imgIndex = 0;
  const [lanString, setLanString] = useState([] as any);
  useEffect(() => {
    if (language === "en") {
      setIsEng(true);
      setLanString("/en");
    } else {
      setIsEng(false);
      setLanString("/ch");
    }
  }, [language]);

  if (open) {
    textDecoStyle = "underline";
  }

  const handleMouseHover = (event: any) => {
    if (child && anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
      // console.log("event.currentTarget: ", event.currentTarget);
    }
  };
  // click on link title (big title)
  const handleClick = (index: number) => {
    // console.log("== focus handleclick", index);
    navigate(props.href);
  };

  function handleHover() {
    currentlyHovering = true;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCloseHover() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }

  // console.log("props", props);
  // console.log("phoneSubmenuImg: ", phoneSubmenuImg.meetus1);

  return (
    <div>
      <Button
        aria-owns={
          anchorEl
            ? isPhone
              ? "expand-simple-menu"
              : "simple-menu"
            : undefined
        }
        aria-haspopup="true"
        disableElevation
        className={`menuBtn ${headerStyles.menuBtnStyle}`}
        onClick={() => {
          if (isPhone) {
            setIsMenuExpand2(!isMenuExpand2);
            if (!child) handleClick(props.index);
          } else {
            handleClick(props.index);
          }
        }}
        onMouseOver={handleMouseHover}
        onMouseLeave={handleCloseHover}
        variant="contained"
      >
        <span
          style={{
            // textDecoration: true ? "textDecoStyle" : "",
            textDecoration: "textDecoStyle",
            fontSize: isPhone ? "1rem" : "1rem",
            paddingRight: isPhone ? "" : "0.3rem",
            marginRight: isPhone ? "auto" : "",
            textTransform: "none",
            textAlign: isPhone ? "left" : "center",
            lineHeight: 1.4,
            // background: "red",
          }}
          onClick={() => {
            handleClick(props.index);
          }}
        >
          {props.name}
        </span>
        {!isPhone && child && (
          <span
            style={{
              color: "#E2C067",
              fontSize: "1.8rem",
              // height:"22.4px",
              lineHeight:0.8
            }}
          >
            ▾
          </span>
        )}
        {/* Responsive Design Menu */}
        {isPhone && isMenuExpand2 && (
          <span
            style={{
              color: "#DC894E",
              fontSize: "1.8rem",
              marginLeft: isPhone ? "35%" : "",
              visibility: child ? "visible" : "hidden",
            }}
          >
            ▴
          </span>
        )}
        {isPhone && !isMenuExpand2 && (
          <span
            style={{
              color: "#E2C067",
              fontSize: "1.8rem",
              marginLeft: isPhone ? "35%" : "",
              visibility: child ? "visible" : "hidden",
            }}
          >
            ▾
          </span>
        )}
      </Button>

      {isPhone ? (
        isMenuExpand2 && (
          <div id="expand-simple-menu">
            {props.lst.map((item: any, index: number) => {
              console.log(index);
              return (
                <div id="submenu">
                  {/* <button
                    onClick={() => {
                      console.log("item:", item);
                      console.log("imgIndex", imgIndex);
                    }}
                  >
                  </button> */}

                  <MenuItem
                    disableRipple
                    key={index}
                    onClick={() => navigate(lanString + item.url)}
                    className="expandMenuItem"
                  >
                    {item.title == "Team Head" ? (
                      <img
                        src={phoneSubmenuImg.meetus1}
                        className="subMenu-img"
                      />
                    ) : item.title == "Collaborators" ? (
                      <img
                        src={phoneSubmenuImg.meetus2}
                        className="subMenu-img"
                      />
                    ) : item.title == "Counsellors" ? (
                      <img
                        src={phoneSubmenuImg.meetus3}
                        className="subMenu-img"
                      />
                    ) : item.title == "Recruitment" ? (
                      <img
                        src={phoneSubmenuImg.getinvolve1}
                        className="subMenu-img"
                      />
                    ) : item.title == "Cooperation" ? (
                      <img
                        src={phoneSubmenuImg.getinvolve2}
                        className="subMenu-img"
                      />
                    ) : item.title == "Volunteer" ? (
                      <img
                        src={phoneSubmenuImg.getinvolve3}
                        className="subMenu-img"
                      />
                    ) : (
                      <></>
                    )}
                    {item[isEng ? "displayNameEng" : "displayName" /*"title"*/]}
                  </MenuItem>
                </div>
              );
            })}
          </div>
        )
      ) : (
        // Display of child section when mouseOver to title
        <div className="styled-menu-div">
          <StyledMenu
            id="expand-simple-menu"
            MenuListProps={{
              boxpos: props.boxpos,
              style: { pointerEvents: "auto" },
              onMouseEnter: handleHover,
              onMouseLeave: handleCloseHover,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            PopoverClasses={{
              root: styles.popOverRoot,
            }}
            disableScrollLock
          >
            {props.lst.map((item: any, index: number) => (
              <MenuItem
                className="expandMenuItem"
                disableRipple
                key={index}
                onClick={() => navigate(lanString + item.url)}
              >
                {item[isEng ? "displayNameEng" : "displayName" /*"title"*/]}
              </MenuItem>
            ))}
          </StyledMenu>
        </div>
      )}
    </div>
  );
}
