import React, { useState, useEffect } from "react";
import "./CounselorCard.css";
import defaultIcon from "./icon.webp";
import plus from "./open btn.webp";
import minus from "./Group 218.webp";
import phoneIcon from "./Group 2455.webp";
import emailIcon from "./Group 2456.webp";

const CounselorCard = (props: {
  counsellor: {
    icon: string;
    fullName: string;
    fullNameEng: string;
    position: string;
    positionEng: string;
    education: string;
    educationEng: string;
    email: string;
    phone: string;
    moreInfo: string;
    moreInfoEng: string;
    category: string;
  };
  language: string;
}) => {
  const {
    icon,
    fullName,
    fullNameEng,
    position,
    positionEng,
    education,
    educationEng,
    email,
    phone,
    moreInfo,
    moreInfoEng,
    category,
  } = props.counsellor;
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useEffect(() => {
    let textareaHeight = 0;
    let textareaHeightposition = 0;
    let textareaHeightname = 0;
    let textareaHeightnumber = 0;
    let textareaHeightemail = 0;

    const elements = document.getElementsByClassName(
      "counselor-text-education"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]?.clientHeight > textareaHeight) {
        textareaHeight = elements[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.height = `${textareaHeight}px`;
    }

    const elementstwo = document.getElementsByClassName(
      "counselor-text-position"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementstwo.length; i++) {
      if (elementstwo[i]?.clientHeight > textareaHeightposition) {
        textareaHeightposition = elementstwo[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementstwo.length; i++) {
      elementstwo[i].style.height = `${textareaHeightposition}px`;
    }

    const elementsthree = document.getElementsByClassName(
      "counselor-title"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementsthree.length; i++) {
      if (elementsthree[i]?.clientHeight > textareaHeightname) {
        textareaHeightname = elementsthree[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementsthree.length; i++) {
      elementsthree[i].style.height = `${textareaHeightname}px`;
    }

    const elementsfour = document.getElementsByClassName(
      "counselor-text-information"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementsfour.length; i++) {
      if (elementsfour[i]?.clientHeight > textareaHeightemail) {
        textareaHeightemail = elementsfour[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementsfour.length; i++) {
      elementsfour[i].style.height = `${textareaHeightemail}px`;
    }

    const elementsfive = document.getElementsByClassName(
      "counselor-text-number"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementsfive.length; i++) {
      if (elementsfive[i]?.clientHeight > textareaHeightnumber) {
        textareaHeightnumber = elementsfive[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementsfive.length; i++) {
      elementsfive[i].style.height = `${textareaHeightnumber}px`;
    }
  });

  return (
    <div className="counselor-card">
      {props.language === "zh_hant" && (
        <>
          <div className="counselor-card-upper">
            <img
              className="counselor-image"
              src={icon ? icon : defaultIcon}
              alt="icon"
            />
            <h2 className="counselor-title">{fullName}</h2>
            <h5 className="counselor-text-position">{position}</h5>
            <h5 className="counselor-text-education">{education}</h5>
            <h5 className="counselor-text-information">
              {email?.length > 0 && (
                <img
                  src={emailIcon}
                  alt="email"
                  className="counselor-card-icon"
                />
              )}
              <a href={"mailto:" + email} target="_blank">
                {email}
              </a>
            </h5>
            <h5 className="counselor-text-number">
              {phone?.length > 0 && (
                <img
                  src={phoneIcon}
                  alt="phone"
                  className="counselor-card-icon"
                />
              )}
              <a href={"tel:" + phone}>{phone}</a>
            </h5>
          </div>
          <div className="counselor-content-container">
            <div
              className="counselor-content-expansion"
              onClick={() => setShowMoreInfo(!showMoreInfo)}
            >
              <h5>更多資料</h5>
              {showMoreInfo ? (
                <img className="expand-icon" src={minus} alt="collapse" />
              ) : (
                <img className="expand-icon" src={plus} alt="expand" />
              )}
            </div>
            {showMoreInfo && (
              <div
                className="counselor-content"
                dangerouslySetInnerHTML={{ __html: moreInfo }}
              ></div>
            )}
          </div>
        </>
      )}
      {props.language === "en" && (
        <>
          <div className="counselor-card-upper">
            <img
              className="counselor-image"
              src={icon ? icon : defaultIcon}
              alt="icon"
            />
            <h2 className="counselor-title">{fullNameEng}</h2>
            <h5 className="counselor-text-position">{positionEng}</h5>
            <h5 className="counselor-text-education">{educationEng}</h5>
            <h5 className="counselor-text-information">
              {email?.length > 0 && (
                <img
                  src={emailIcon}
                  alt="email"
                  className="counselor-card-icon"
                />
              )}
              <a href={"mailto:" + email} target="_blank" style={{ color: "#306188" }}>
                {email}
              </a>
            </h5>
            <h5 className="counselor-text-number">
              {phone?.length > 0 && (
                <img
                  src={phoneIcon}
                  alt="phone"
                  className="counselor-card-icon"
                />
              )}
              <a href={"tel:" + phone} style={{ color: "#306188" }}>{phone}</a>
            </h5>
          </div>
          <div className="counselor-content-container">
            <div
              className="counselor-content-expansion"
              onClick={() => setShowMoreInfo(!showMoreInfo)}
            >
              <h5>Read More</h5>
              {showMoreInfo ? (
                <img className="expand-icon" src={minus} alt="collapse" />
              ) : (
                <img className="expand-icon" src={plus} alt="expand" />
              )}
            </div>
            {showMoreInfo && (
              <div
                className="counselor-content"
                dangerouslySetInnerHTML={{ __html: moreInfoEng }}
              ></div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CounselorCard;
