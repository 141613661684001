import React, { forwardRef, useEffect, useState } from "react";
import "./TrainingCard.css";
import test from "./Image.webp";
import alarm from "./alarm.webp";
import calendar from "./calendar-alt.webp";
import globe from "./globe.webp";
import pin from "./pin.webp";
import ticket from "./ticket.webp";
import openButton from "./open btn.webp";
import closeButton from "./Group 267.webp";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import { BaseRouter } from "@react-navigation/native";
import slugify from "slugify";

export type TrainingInfo = {
  id: string;
  info: {
    id: string;
    title: string;
    time: string;
    date: string;
    organization: string;
    specialty: string;
    event: string;
    description: string;
    image: string;
    innerImage: string;
    paragraph: string;
    link: string;
    url: string;
    isShowMore: boolean;
    pdf: string;
    languageLabel: number;
    position: number;
  };
};
export type TrainingCardMeta = {
  height: number|null;
  isCardDataUpdated: boolean;
}
interface TrainingCardProps {
  info: TrainingInfo;
}

const TrainingCard = ({
  props,
  baseRoute,
  cardMeta,
}: {
  props: TrainingInfo;
  baseRoute: string;
  cardMeta: TrainingCardMeta;
}) => {
  const navigate = useNavigate();
  const id = props.info.id;
  const [isShowMore, setIsShowMore] = useState(props.info.isShowMore); // UseState Hook (boolean type)

  const toggleIsShowMore = (id: string) => {
    if (id === props.info.id) {
      setIsShowMore(!isShowMore);
    }
  };

  const canRenderReadMore = () => {
    return (
      (props.info?.organization && props.info?.organization?.length > 0) ||
      (props.info?.specialty && props.info?.specialty?.length > 0) ||
      (props.info?.event && props.info?.event?.length > 0) ||
      (props.info?.description && props.info?.description?.length > 0)
    );
  };

  function getCardStyle() {
    if (cardMeta.height == null) return {}
    return { height: cardMeta.height }
  }

  useEffect(() => {
    setIsShowMore(false);
    // console.log("card title",slugify(props.info.link, { lower: true }))
  }, []);
  return (
    <div className={`training-card ${cardMeta.isCardDataUpdated ? 'fadeIn' : ''}`}>
      <Link
        to={
          baseRoute +
          "/" +
          slugify(props.info.link, { lower: true }) +
          "-" +
          props.info.id
        }
      >
        <div className="training-basic-info" style={getCardStyle()}>
          <div id="image-container">
            {/* <div className="training-image-inner-container"> */}
            <img
              className="training-image"
              src={props.info.image}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement;
                target.src = test;
              }}
              alt=""
            />
            {/* </div> */}
          </div>
          <div className="training-card-title">
            {/* {props.info.title.replace(/<[^>]*>/g, "")} */}
            {props.info?.title}
          </div>
          <div className="info-container">
            <div>
              {props.info.time !== null && props.info.time !== "" && (
                <>
                  <img className="training-card-icon" src={alarm} alt="" />
                  <div className="training-time">{props.info.time}</div>
                </>
              )}
            </div>
            <div>
              {props.info.date != null && props.info.date != "" && (
                <>
                  <img className="training-card-icon" src={calendar} alt="" />
                  <div className="training-date">{props.info.date}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="training-detail-info">
        {props.info.languageLabel === 0 && canRenderReadMore() && (
          <div
            className="training-info-expand"
            onClick={() => toggleIsShowMore(id)}
          >
            更多資料
            {isShowMore ? (
              <img className="expansion-button" src={closeButton} alt="" />
            ) : (
              <img className="expansion-button" src={openButton} alt="" />
            )}
          </div>
        )}
        {props.info.languageLabel === 1 && canRenderReadMore() && (
          <div
            className="training-info-expand"
            onClick={() => toggleIsShowMore(id)}
          >
            Read More
            {isShowMore ? (
              <img className="expansion-button" src={closeButton} alt="" />
            ) : (
              <img className="expansion-button" src={openButton} alt="" />
            )}
          </div>
        )}

        {isShowMore && (
          <div className="training-more-info-backdrop">
            <div className="training-more-info">
              <div className="expand-short-info">
                <div>
                  {props.info.organization !== null &&
                    props.info.organization !== "" &&
                    typeof props.info.organization !== "undefined" && (
                      <>
                        <img
                          src={globe}
                          alt=""
                          className="expand-training-card-icon"
                        />
                        <div className="training-organization">
                          {props.info.organization}
                        </div>
                      </>
                    )}
                </div>
                <div>
                  {props.info.specialty !== null &&
                    props.info.specialty !== "" &&
                    typeof props.info.specialty !== "undefined" && (
                      <>
                        <img
                          src={pin}
                          alt=""
                          className="expand-training-card-icon"
                        />
                        <div className="training-specialty">
                          {props.info.specialty}
                        </div>
                      </>
                    )}
                </div>
                <div>
                  {props.info.event !== null &&
                    props.info.event !== "" &&
                    typeof props.info.event !== "undefined" && (
                      <>
                        <img
                          src={ticket}
                          alt=""
                          className="expand-training-card-icon"
                        />
                        <div className="training-event">{props.info.event}</div>
                      </>
                    )}
                </div>
              </div>
              <div
                className="training-description"
                dangerouslySetInnerHTML={{ __html: props.info.description }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingCard;
