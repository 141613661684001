import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Image from "./popup.webp";
import PhoneImage from "./popup-mobile.webp";
import "./Poppage.css";
import Header2 from "../Header2/Header2";
import Footer from "../Footer/Footer";


function Poppage(){
    const [lanString, setLanString] = useState([] as any);
    const[popImage, setPopImage]=useState("");
    const language = useSelector((state: RootState) => state.language.language);
    const navigate = useNavigate();
    const isPhone = window.matchMedia("(max-width: 768px)").matches;
    const role = useSelector((state: RootState) => state.role.role);


    function handlePopupClose(){
      if(role==""){
        navigate(lanString + "/login")
      }else if(role=="trainee"){
        navigate(lanString+"/home")
      }
    }

    useEffect(()=>{
        if (language === "zh_hant") {
            document.title = "請登錄 - ACTuwise"; // Update the document title on mount
            setLanString("/ch");
          } else {
            document.title = "Please Log In - ACTuwise"; // Update the document title on mount
            setLanString("/en");
          }

        if(isPhone){
            setPopImage(PhoneImage)
        }else{
            setPopImage(Image);
        };
    },[isPhone,language,lanString])



    return(
        <>
        <div className="poppage-container">
        <Header2 url=""/>
        <div className="poppage-content">
        </div>
        <div
          className="poppage"
        //   style={{ display: showPopup ? "block" : "none" }}
        ></div>
        <div
          id="poppage-popup"
        //   style={{
        //     display: showPopup ? "flex" : "none",
        //     top: popupPosition,
        //   }}
        >
          <img id="poppage-popup-img" src={popImage} alt=""></img>
          {language == "en" ? (
            role != "" ? 
            (<p id="poppage-popup-text">Your account don't have access right to this page</p>) 
            : 
            (<p id="poppage-popup-text">Please log in to access this page</p>)
          ) : (
            <p id="poppage-popup-text">請登入以訪問此頁面</p>
          )}
          <button id="poppage-popup-button" onClick={handlePopupClose}>
            OK
          </button>
        </div>
        <Footer/>
        </div>
      </>
    )
}

export default Poppage;