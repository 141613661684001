/**
 * Helper class to deal with the API using Singleton pattern.
 */
class ApiHelper {
  private static instance: ApiHelper;
  public static readonly url: string =
    process.env.REACT_APP_API_URL || "http://localhost:3000";
  public static readonly fileUrl: string =
    process.env.REACT_APP_UPLOAD_URL || "http://localhost:3000";
  public static readonly local: string = `${ApiHelper.url}/graphql`;
  public static readonly login: string = `${ApiHelper.url}/webLogin`;
  public static readonly forgetPw: string = `${ApiHelper.url}/forgetPassword`;
  public static readonly image: string = `${ApiHelper.url}/uploadImage`;
  public static readonly getImage: string = `${ApiHelper.fileUrl}/images/`;
  public static readonly getVideo: string = `${ApiHelper.fileUrl}/videos/`;
  public static readonly getAudio: string = `${ApiHelper.fileUrl}/audios/`;
  public static readonly getPDF: string = `${ApiHelper.fileUrl}/pdf/`;
  private constructor() {}

  public static getInstance(): ApiHelper {
    if (!ApiHelper.instance) {
      ApiHelper.instance = new ApiHelper();
    }
    return ApiHelper.instance;
  }
}

export default ApiHelper;
