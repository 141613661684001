import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import nicknameReducer from "./nicknameSlice";
import roleReducer from "./roleSlice";
import NewsReducer from "./NewsSlice";

const store = configureStore({
  reducer: {
    language: languageReducer,
    nickname: nicknameReducer,
    role: roleReducer,
    News: NewsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
