import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./FGACT.css";
import Footer from "../Footer/Footer";
import bg from "./Frame 2705.webp";
import bubble1 from "./module_0.png";
import bubble2 from "./module_1.png";
import bubble3 from "./module_2.png";
import bubble4 from "./module_3.png";
import bubble5 from "./module_4.png";
import bubble6 from "./module_5.png";
import bubble7 from "./module_6.png";

import lockedBubble2 from "./module1_locked.png";
import lockedBubble3 from "./module2_locked.png";
import lockedBubble4 from "./module3_locked.png";
import lockedBubble5 from "./module4_locked.png";
import lockedBubble6 from "./module5_locked.png";
import lockedBubble7 from "./module6_locked.png";
import { Link } from "react-router-dom";
import Header2 from "../Header2/Header2";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import { Console } from "console";
import { element } from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";
import LockIcon from "@mui/icons-material/Lock";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import lock from "./lock.png";

interface ButtonData {
  icon: string;
  IconData: string;
  url: string;
}

interface Data {
  background: string;
  buttons: ButtonData[];
}

interface Content {
  type: string;
  text: string;
}

function FGACT() {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const [backendData, setBackendData] = useState([] as any);
  const [titleData, settitleData] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  // const [loadingnumber,setLoadinnumber]=useState<number>();
  let loadingnumber = 0;
  function getData() {
    const request = `{
        menuBar(where :$where){
            url
            title
            priority
            id
            parentContent {
              title
              session
              id
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                type
                title
                priority
                position
                langLabel {
                  lang
                  index
                  id
                }
                isDeleted
                category
                content
              }
            }
            child(orderBy: $orderBy){
                title
                priority
                id
                url
                childContent(where: $childContentWhere2) {
                    title
                    contents (orderBy: $contentsOrderBy2,where: $contentsWhere2){
                      langLabel {
                        lang
                        index
                        id
                      }
                      content
                      position
                      category
                      id
                 
                    }
                  }
            }
        }
    }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [ChildMenuOrderByInput!]!,$childContentWhere2: PageContentWhereInput!, $contentsOrderBy2: [ContentDetailOrderByInput!], $contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "Facilitator-Guided ACT" },
        childContentWhere2: {
          title: { equals: "Main Page Information" },
        },

        orderBy: [
          {
            priority: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };
    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        console.log(res);
        // console.log(res.data.data.menuBar.child);
        // console.log(res.data.data.menuBar.parentContent);

        let totalCardArr = [] as any;
        let totalCard = [] as any;
        let totalCardArrResult = [] as any;

        res.data.data.menuBar.child.forEach((element: any, index: number) => {
          element.childContent.forEach((element: any, index: number) => {
            element.contents.forEach((element: any, index: number) => {
              totalCard.push(element);
            });
          });
        });

        totalCardArr = totalCard.reduce((acc: any, cur: any) => {
          if (!acc[cur.category]) {
            acc[cur.category] = 1;
          } else {
            acc[cur.category]++;
          }
          return acc;
        }, {});

        Object.keys(totalCardArr).forEach((e: any) => {
          totalCardArrResult.push({
            category: e,
            url: "",
            ModuleName: "",
            ModuleNameEng: "",
            IconImage: "",
            lockedIconImage: "",
            unlockTime: null,
          });
        });
        //console.dir(totalCardArrResult);

        totalCardArrResult.forEach((e: any, index: number) => {
          totalCard.forEach((element: any, idx: number) => {
            if (
              e.category === `Module${+element.category.split("Module")[1] - 1}`
            ) {
              if (element.position === "url") {
                totalCardArrResult[index].url = element.content;
              } else if (
                element.position === "title" &&
                element.langLabel[0].lang === "zh_hant"
              ) {
                totalCardArrResult[index].ModuleName = element.content;
              } else if (
                element.position === "title" &&
                element.langLabel[0].lang === "en"
              ) {
                totalCardArrResult[index].ModuleNameEng = element.content;
              }
            }
          });
        });
        //console.dir(totalCardArrResult);

        totalCardArrResult.forEach((e: any, index: number) => {
          totalCard.forEach((element: any, idx: number) => {
            if (e.category === element.category) {
              if (element.position === "timeLock") {
                totalCardArrResult[index].unlockTime = element.content;
              }
            }
          });
        });
        //console.dir(totalCardArrResult);

        //remove the last totalCard (as it is just previous module's child data)
        totalCardArrResult.splice(totalCardArrResult.length - 1, 1);

        const assignItems = {
          bubble: [bubble1, bubble2, bubble3, bubble4, bubble5, bubble6, bubble7],
          lockBubble: [lockedBubble2, lockedBubble3, lockedBubble4, lockedBubble5, lockedBubble6, lockedBubble7]
        }
        totalCardArrResult.map((el: any, index: number) => {
          el.IconImage = assignItems['bubble'][index]
          if (index != 0) {
            el.lockedIconImage = assignItems['lockBubble'][index - 1]
          }
          // if is actual can read modules
          if (index > 0 && (el.ModuleNameEng != 'Post Assessment' && el.ModuleNameEng != 'Follow-up Assessment')) {
            el.url = res.data.data.menuBar.child[index].url;
          }
        })
        // totalCardArrResult[0].IconImage = bubble1;
        // totalCardArrResult[1].IconImage = bubble2;
        // totalCardArrResult[2].IconImage = bubble3;
        // totalCardArrResult[3].IconImage = bubble4;
        // totalCardArrResult[4].IconImage = bubble5;
        // totalCardArrResult[5].IconImage = bubble6;
        // totalCardArrResult[6].IconImage = bubble7;

        // totalCardArrResult[1].lockedIconImage = lockedBubble2;
        // totalCardArrResult[2].lockedIconImage = lockedBubble3;
        // totalCardArrResult[3].lockedIconImage = lockedBubble4;
        // totalCardArrResult[4].lockedIconImage = lockedBubble5;
        // totalCardArrResult[5].lockedIconImage = lockedBubble6;
        // totalCardArrResult[6].lockedIconImage = lockedBubble7;

        // totalCardArrResult[1].url = res.data.data.menuBar.child[1].url;
        // totalCardArrResult[2].url = res.data.data.menuBar.child[2].url;
        // totalCardArrResult[3].url = res.data.data.menuBar.child[3].url;
        // totalCardArrResult[4].url = res.data.data.menuBar.child[4].url;

        //console.dir(totalCard);
        //console.dir(totalCardArr);
        //console.dir(totalCardArrResult);


        setBackendData(totalCardArrResult);
        settitleData(res.data.data.menuBar.parentContent[0].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  // console.log(backendData);
  // console.log(titleData);

  const language = useSelector((state: RootState) => state.language.language);

  useEffect(() => {
    getData();
    // Update the document title on mount
  }, []);

  function onMouseOverButton(e: any) {
    e.target.classList.add("active");
  }

  function onMouseOutButton(e: any) {
    e.target.classList.remove("active");
  }
  const ref = useRef<HTMLImageElement>(null);

  const [height, setHeight] = useState(0);
  const [lanString, setLanString] = useState([] as any);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    function handleWindowResize() {
      if (ref?.current?.offsetHeight) setHeight(ref.current.offsetHeight);
    }
    const getRoadMapSize = setInterval(() => {
      if (!ref?.current?.offsetHeight) return;
      setHeight(ref.current.offsetHeight);
      clearInterval(getRoadMapSize);
    }, 100);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      setLanString("/ch");
      document.title = "為家長而設: 網上ACT訓練課程 - ACTuwise";
    } else {
      setLoadingText("Loading");
      setLanString("/en");
      document.title = "Guided ACT Modules For Parents - ACTuwise";
    }
  }, [language]);

  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // window.onload = () => {
    //   // Code to execute when the page finishes loading
    //   console.log("Page finished loading");
    // };
    // document.addEventListener("DOMContentLoaded", () => {
    //   // Code to execute when the DOM is ready
    //   console.log("DOM is ready");
    // });

    setIsLoading(true);
    if (titleData.length && backendData.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [titleData, backendData]);
  // avoid change language error
  /* if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */

  function getUnlockTime(date: Date) {
    const opt: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    };
    return date.toLocaleString("HK", opt);
  }

  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  // console.log(loadingnumber)
  //[page] [section] 為家長而設 page (participant)
  return (
    <div className="fgact-wrapper">
      <div className="general-header">
        <Header2 url="/guided-act-modules-for-parents" />
      </div>
      <div className="ACT-container">
        <div id="middleSection" className="ACT-content">
          {titleData.map((item: any, index: number) => {
            return (
              <div>
                {item.position === "title" &&
                  item.langLabel[0].lang === "zh_hant" && (
                    <h1
                      key={index}
                      className="ACT-title"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></h1>
                  )}
                {/* {item.position==='title'&&language==='en'&&item.langLabel[0].lang===language&&(
                <h1 key={index} className="ACT-title" dangerouslySetInnerHTML={{ __html: item.content }}></h1>
              )} */}
                {item.position === "content" &&
                  item.langLabel[0].lang === "zh_hant" && (
                    <p
                      key={index}
                      className="ACT-paragraph"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></p>
                  )}
                {/* {item.position==='content'&&language==='en'&&item.langLabel[0].lang===language&&(
               <p key={index} className="ACT-paragraph"dangerouslySetInnerHTML={{ __html: item.content }}></p>
              )} */}
              </div>
            );
          })}
        </div>
        <div className="roadmap-container">
          <img className="ACT-image" src={bg} alt="" ref={ref} />

          {backendData.map((button: any, index: number) => {
            loadingnumber = index;
            //console.log(index + "zIndex: " + (6-index));
            //console.log(backendData);
            return (
              <div className="button-row" key={index}>
                <div
                  className={`button-container-outer btn${index}`}
                  style={{
                    top: `${index * 0.135 * height + height * 0.07}px`,
                    zIndex: backendData.length - index,
                  }}
                >
                  {/* {button.url.toLowerCase().startsWith("http") ? (
                <a
                  className="button-container"
                  href={button.url}
                  onMouseOver={onMouseOverButton}
                  onMouseOut={onMouseOutButton}
                  onTouchStart={onMouseOverButton}
                  onTouchEnd={onMouseOutButton}
                >
                  <img className="button-bg" src={button.IconImage}></img>
                </a>
              )  */}
                  {button.unlockTime > new Date(Date.now()).toISOString() ? (
                    <div className="button-container">
                      <div className="image-overlay"></div>
                      <img src={lock} className="lock"></img>
                      <img
                        className="button-hidden-bg"
                        src={button.lockedIconImage || button.iconImage}
                      ></img>
                      <p
                        className={
                          "button-desc " + (index % 2 == 0 ? "left" : "right")
                        }
                      >
                        {/* {language==='en'&&(<span>{button.ModuleNameEng}</span>)} */}
                        <span>
                          於 {getUnlockTime(new Date(button.unlockTime))}
                          {/* {`${
                            new Date(
                              new Date(button.unlockTime).setHours(
                                new Date(button.unlockTime).getHours() + 8
                              )
                            )
                              .toISOString()
                              .split("T")[0]
                          }:${
                            new Date(
                              new Date(button.unlockTime).setHours(
                                new Date(button.unlockTime).getHours() + 8
                              )
                            )
                              .toISOString()
                              .split("T")[1]
                              .split(".")[0]
                              .split(":")[0]
                          }-${
                            new Date(
                              new Date(button.unlockTime).setHours(
                                new Date(button.unlockTime).getHours() + 8
                              )
                            )
                              .toISOString()
                              .split("T")[1]
                              .split(".")[0]
                              .split(":")[1]
                              .split(":")[0]
                          }`} */}
                          開放
                        </span>
                      </p>
                    </div>
                  ) : (
                    <>
                      <Link
                        className="button-container"
                        target="_blank"
                        rel="noopener noreferrer"
                        to={
                          index === 0 || index === 5 || index === 6
                            ? button.url
                            : lanString + button.url
                        }
                        onMouseOver={onMouseOverButton}
                        onMouseOut={onMouseOutButton}
                        onTouchStart={onMouseOverButton}
                        onTouchEnd={onMouseOutButton}
                      >
                        <img className="button-bg" src={button.IconImage}></img>
                      </Link>
                      <p
                        className={
                          "button-desc " + (index % 2 == 0 ? "left" : "right")
                        }
                      >
                        {/* {language==='en'&&(<span>{button.ModuleNameEng}</span>)} */}
                        <span>{button.ModuleName}</span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FGACT;

// const [data, setData] = useState<Data>({
//   background: bg,
//   buttons: [
//     {
//       icon: bubble1,
//       IconData: "基線評估",
//       url: "http://google.com",
//     },
//     {
//       icon: bubble2,
//       IconData: "作為父母及小朋友的價值觀",
//       url: "/act/Module1",
//     },
//     {
//       icon: bubble3,
//       IconData: "接受你的反應活在當下",
//       url: "/Module1",
//     },
//     {
//       icon: bubble4,
//       IconData: "覺察、接納和共享心理存在感",
//       url: "/Module1",
//     },
//     {
//       icon: bubble5,
//       IconData: "父母的自我慈悲選擇價值觀方向並做出承諾行動",
//       url: "/Module1",
//     },
//     {
//       icon: bubble6,
//       IconData: "後續評估",
//       url: "http://google.com",
//     },
//     {
//       icon: bubble7,
//       IconData: "跟進評估",
//       url: "http://google.com",
//     },
//   ],
// });

// const content: Content[] = [
//   { type: "title", text: "ACT 輔導員指導" },
//   {
//     type: "paragraph",
//     text: "歡迎參加「接納與承諾閱讀療法 (ACT) - 活出ACT價值」工作坊, 為期12星期, 包括網上訓練和每三星期一次的視像會議。工作坊旨在教導家長如何使用 ACT 療法來應對負面情緒，並促進積極育兒。家長將會學習各種技巧和策略，例如接納負面情緒、培養自身價值觀和目標，以及透過積極行動改善親子關係。完成閱讀學習後，家長將以小組形式參與受訓導師帶領的家長小組，分享學習心得、探討育兒問題，並練習應用 ACT 技巧。",
//   },
// ];

// for(var i=0; i= res.data.data.menuBar.child.length; i++){
//   totalCard=res.data.data.menuBar.child[i].childContent.contents.reduce(
//     (acc: any, cur: any) => {
//       if (!acc[cur.category]) {
//         acc[cur.category] = 1;
//       } else {
//         acc[cur.category]++;
//       }
//       return acc;
//     },
//     {}
//   )
// }

// let totalCard=res.data.data.menuBar.child.forEach().childContent.forEach().contents.reduce(
//   (acc: any, cur: any) => {
//     if (!acc[cur.category]) {
//       acc[cur.category] = 1;
//     } else {
//       acc[cur.category]++;
//     }
//     return acc;
//   },
//   {}
// );
// console.log(totalCard);

// Object.keys(7).forEach((e:any)=>
// totalCardArr.push({
//   category: e,
//   url:'',
//   ModuleTitle:'',
//   ModuleTitleEng:'',
// })
// );

// totalCardArr.forEach((e:any,index:number)=>{
//   res.data.data.menuBar.child.forEach(
//     (element:any, idx:number)=>{
//       if(e.category===element.childContent.contents[idx].category){
//         if(element.childContent.contents[idx].position==='url'){
//           totalCardArr[index].url=element.childContent.contents[idx].content;
//         }else if (element.childContent.contents[idx].position==='title'&&
//         element.childContent.contents[idx].langLabel.lang==='zh_hant'){
//           totalCardArr[index].ModuleTitle===element.childContent.contents[idx].content;
//         }else if (element.childContent.contents[idx].position==='title'&&
//         element.childContent.contents[idx].langLabel.lang==='en'){
//           totalCardArr[index].ModuleTitle===element.childContent.contents[idx].content;
//         }
//       }

//     }
//   );
// });

// console.log(totalCardArr);
