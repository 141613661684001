import { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { useSelector } from "react-redux";
import "react-h5-audio-player/lib/styles.css";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import playButton from "./Frame_264.webp";
import scrollToTopBtn from "./Group_193.webp";
import scrollToTopBtnInversed from "./Group_2357.webp";
import "./Publicity_sub.css";
import audioPlayBtn from "./audio_play.webp";
import { useLocation } from "react-router-dom";
import { PublicityCardsProps } from "../Publicity/PublicityCard";
import React from "react";
import audioPauseBtn from "./audio_pause_button.webp";
import ApiHelper from "../helper/ApiHelper";
import prevBtn from "../Publicity/prevBtn.webp";
import nextBtn from "../Publicity/nextBtn.webp";
import ArrowPrevHover from "../Publicity/Group 2351.webp";
import ArrowNextHover from "../Publicity/Group 2352.webp";
import { RootState } from "../store";
import GreyLeft from "./grey_left.webp";
import GreyRight from "./grey_right.webp";
import playIcon from "./Frame_264.webp";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import LoadingPage from "../Loading/Loading";

export interface subPublicityCardsProps extends PublicityCardsProps {
  subPageTitle: string[];
  videoCaption: string[];
  pictureCaption: string[];
  audioCaption: string[];
  subContent: string[];
  subTitle: string[];
}

function Publicity_sub({
  baseRoute,
  cards,
  innerURL,
}: {
  baseRoute: string;
  cards: PublicityCardsProps;
  innerURL: string;
}) {
  const location = useLocation();
  let [Loading, setLoading] = useState(true);
  const currentUrl = location.pathname;
  const subCard: subPublicityCardsProps = {
    ...cards,
    subPageTitle: [],
    videoCaption: [],
    pictureCaption: [],
    audioCaption: [],
    subContent: [],
    subTitle: [],
  };

  const [card, setCard] = useState(subCard);

  const [isIOS, setIsIOS] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(1);
  const [isPrevHovered, setIsPrevHovered] = useState(false);
  const [isNextHovered, setIsNextHovered] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const handlePrevTouchStart = () => {
    setIsPrevHovered(true);
  };

  const handlePrevTouchEnd = () => {
    setIsPrevHovered(false);
  };

  const handleNextTouchStart = () => {
    setIsNextHovered(true);
  };

  const handleNextTouchEnd = () => {
    setIsNextHovered(false);
  };

  const handleTouchMove = () => {
    setIsPrevHovered(false);
    setIsNextHovered(false);
  };

  const handlePrevBtnClick = () => {
    if (currentImageIndex === 1) {
      setCurrentImageIndex(card.images.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextBtnClick = () => {
    if (currentImageIndex === card.images.length - 1) {
      setCurrentImageIndex(1);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOS = Boolean(
      userAgent.match(/iPhone|iPad|iPod/i) ||
        (userAgent.match(/Mac/) && navigator.maxTouchPoints > 2)
    );
    setIsIOS(isIOS);
  }, []);

  card.contents.forEach((content, index) => {
    switch (content.position) {
      case "title":
        card.subPageTitle.push(content.content);
        break;
      case "caption":
        if (index === 5 || index === 6) card.videoCaption.push(content.content);
        // else if (index === 15 || index === 16)
        else if (index === 13 || index === 14)
          card.audioCaption.push(content.content);
        break;
      case "caption1":
      case "caption2":
      case "caption3":
        card.pictureCaption.push(content.content);
        break;
      case "subTitle1":
      case "subTitle2":
      case "subTitle3":
      case "subTitle4":
      case "subTitle5":
        card.subTitle.push(content.content);
        break;

      case "content1":
      case "content2":
      case "content3":
      case "content4":
      case "content5":
        card.subContent.push(content.content);
        break;
      default:
        // console.warn(`Unexpected position value: ${content.position}`);
        break;
    }
  });

  function handleOnClick(url: string) {
    if (currentUrl === url) {
      return;
    }
    if (url == "#") setBtnSrc(scrollToTopBtn);
    window.scrollTo(0, 0);
  }

  const language = useSelector((state: RootState) => state.language.language);
  const [languageLabel, setLanguageLabel] = useState(1 as number);
  // const [currentWebUrl, setCurrentWebUrl] = useState(
  //   window.location.href.toLowerCase().startsWith("http://localhost:3000")
  //     ? window.location.href.split("3000").at(-1)
  //     : window.location.href.split("com").at(-1)
  // );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(currentUrl?.split("/")[1]);
    if (currentUrl?.split("/")[1] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
  }, [language]);
  useEffect(() => {
    if (language === "zh_hant") {
      setLanguageLabel(0);
    } else if (language === "en") {
      setLanguageLabel(1);
    }
  }, [language]);
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = card.subPageTitle[languageLabel] + " - ACTuwise"; // Update the document title on mount
    } else if (language === "en") {
      setLoadingText("Loading");
      document.title = card.subPageTitle[languageLabel] + " - ACTuwise"; // Update the document title on mount
    }
  }, [languageLabel]);

  // useEffect(() => {
  //     if (language === "zh_hant") {
  //       setLoadingText("加載中");
  //       document.title = "專業培訓 - ACTuwise"; // Update the document title on mount
  //     } else {
  //       setLoadingText("Loading");
  //       document.title = "Professional Training - ACTuwise"; // Update the document title on mount
  //     }
  //   }, [language]);
  const [btnSrc, setBtnSrc] = useState(scrollToTopBtn);
  function onMouseOverButton() {
    setBtnSrc(scrollToTopBtnInversed);
  }
  function onMouseOutButton() {
    setBtnSrc(scrollToTopBtn);
  }

  // console.log(card.videoCaption);
  // console.log(card.pictureCaption);
  // console.log(card.audioCaption);
  // console.log(card.contents);
  const audioFlag: boolean =
    card.audios.length > 0 && card.audios.some((audio) => audio.url !== "");
  const videoFlag: boolean =
    card.videos.length > 0 && card.videos.some((video) => video.url !== "");
  // const imagesFlag: boolean =
  //   card.images.length > 0 && card.images.some((image) => image.url !== "");
  const imagesFlag: boolean =
    card.images.length > 1 &&
    card.images.slice(1).some((image) => image.url !== "");

  const [audioPlayers, setAudioPlayers] = useState<(HTMLAudioElement | null)[]>(
    []
  );
  const handlePlayButtonClick = (audioIndex: number) => {
    const audioPlayer = audioPlayers[audioIndex];
    if (audioPlayer) {
      audioPlayer.play();
    }
  };

  const handlePauseButtonClick = (audioIndex: number) => {
    const audioPlayer = audioPlayers[audioIndex];
    if (audioPlayer) {
      audioPlayer.pause();
    }
  };
  //Loading UI Control
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  if (Loading) {
    return <LoadingPage loadingText={loadingText} />;
  }
  return (
    <div className="publicity_sub_wrapper">
      <div className="landing-header">
        <Header2 url={"/news-and-events" + innerURL}></Header2>
      </div>
      <div className="ps-container">
        <div id="middleSection" className="ps-content">
          <h1 className="ps-title">{card.subPageTitle[languageLabel]}</h1>
          {(imagesFlag || videoFlag) && (
            <div className={"ps-multimedia-container"}>
              <p className={"ps-multimedia-title"}>
                {card.subPageTitle[2 + languageLabel]}
                {/* <button onClick={()=>(console.log("card url",card.images[0].url))}></button> */}
              </p>
              {videoFlag && (
                <div className={"ps-videos-container"}>
                  {card.videos.map((video, id) => (
                    <div className="videosWrapper" key={id}>
                      <iframe
                        className="video-player"
                        id="videoContainer"
                        key={id}
                        width="100%"
                        src={video.url || ""}
                      >
                        {/* <source
                        src={"https://www.youtube.com/embed/"+ video.url?.split("https://www.youtube.com/watch?v=")?.at(-1)}
                          // src={`${ApiHelper.getVideo}${video.url}`}
                          // type="video/mp4"
                        ></source> */}

                        {/* {playIcon && (
                          <img className={"playBtn"} src={playButton} />
                        )} */}
                      </iframe>
                    </div>
                  ))}
                  <p className={"ps-videos-caption"}>
                    {card.videoCaption[languageLabel]}
                  </p>
                </div>
              )}

              {
                card.images.length > 1 && (
                  <>
                    <div className={"ps-image-slider-container-wrapper"}>
                      <img
                        key={card.images[currentImageIndex].id}
                        src={`${ApiHelper.getImage}${card.images[currentImageIndex].url}`}
                        alt={card.images[currentImageIndex].title}
                      />
                    </div>
                    <div className={"ps-image-slider-container"}>
                      <div className={"ps-image-slider"}>
                        <div className={"ps-images-caption"}>
                          {
                            card.pictureCaption[
                              (currentImageIndex - 1) * 2 + languageLabel
                            ]
                          }
                        </div>

                        <div className={"ps-image-slider-controls"}>
                          {
                            <button
                              onClick={handlePrevBtnClick}
                              {...(!isIOS && {
                                onTouchMove: () => handleTouchMove(),
                                onTouchStart: () => handlePrevTouchStart(),
                                onTouchEnd: () => handlePrevTouchEnd(),
                                onMouseEnter: () => {
                                  setIsPrevHovered(true);
                                },
                                onMouseLeave: () => {
                                  setIsPrevHovered(false);
                                },
                              })}
                            >
                              {card.images.length !== 2 ? (
                                <img
                                  id="prevBtnImg"
                                  // src={isPrevHovered ? ArrowPrevHover : prevBtn}
                                  src={prevBtn}
                                  alt="Previous"
                                />
                              ) : null}
                            </button>
                          }

                          <div className={"ps-image-slider-page-number"}>
                            {currentImageIndex} / {card.images.length - 1}
                          </div>
                          {
                            <button
                              onClick={handleNextBtnClick}
                              {...(!isIOS && {
                                onTouchMove: () => handleTouchMove(),
                                onTouchStart: () => handleNextTouchStart(),
                                onTouchEnd: () => handleNextTouchEnd(),
                                onMouseEnter: () => setIsNextHovered(true),
                                onMouseLeave: () => setIsNextHovered(false),
                              })}
                            >
                              {card.images.length !== 2 ? (
                                <img
                                  id="nextBtnImg"
                                  // src={isNextHovered ? ArrowNextHover : nextBtn}
                                  src={nextBtn}
                                  alt="Next"
                                />
                              ) : null}
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )
                // ImageSlider(card, isIOS, languageLabel)
              }
            </div>
          )}
          {(card.subTitle.some((item) => item.trim() !== "") ||
            card.subContent.some(
              (item) => item.replace(/<[^>]+>/g, "").trim() !== ""
            )) && (
            <div className={"ps-contents-container"}>
              {[0, 1, 2, 3, 4].map((index) =>
                index != 4 &&
                card.subTitle[2 * index + languageLabel].trim() !== "" &&
                card.subContent[2 * index + languageLabel]
                  .replace(/<[^>]+>/g, "")
                  .trim() !== "" ? (
                  <div key={index} className={"ps-callout-container"}>
                    {card.subTitle[2 * index + languageLabel].trim() !== "" ? (
                      <h5 className={"ps-callout-title"}>
                        {card.subTitle[2 * index + languageLabel]}
                      </h5>
                    ) : null}

                    {card.subContent[2 * index + languageLabel]
                      .replace(/<[^>]+>/g, "")
                      .trim() !== "" ? (
                      <div
                        className={"ps-callout-description"}
                        dangerouslySetInnerHTML={{
                          __html: card.subContent[2 * index + languageLabel],
                        }}
                      ></div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    {card.subTitle[2 * index + languageLabel].trim() !== "" && (
                      <h5>{card.subTitle[2 * index + languageLabel]}</h5>
                    )}
                    {card.subContent[2 * index + languageLabel]
                      .replace(/<[^>]+>/g, "")
                      .trim() !== "" && (
                      <div
                        key={index}
                        className={"ps-paragraph"}
                        dangerouslySetInnerHTML={{
                          __html: card.subContent[2 * index + languageLabel],
                        }}
                      ></div>
                    )}
                  </div>
                )
              )}

              {audioFlag ? (
                <div className="ps-audios-container">
                  {card.audios.map((audio, idx) => (
                    <div key={audio.id} className="ps-audio-player">
                      <AudioPlayer
                        src={`${ApiHelper.getAudio}${audio.url}`}
                        layout="horizontal-reverse"
                        customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                        customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
                        showJumpControls={false}
                        customIcons={{
                          play: <img src={audioPlayBtn} alt="Play" />,
                          pause: <img src={audioPauseBtn} alt="Pause" />,
                        }}
                        onPlay={() => handlePlayButtonClick(idx)}
                        onPause={() => handlePauseButtonClick(idx)}
                      />

                      <p className="ps-audios-caption">
                        {card.audioCaption?.[languageLabel + idx * 2]}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="scroll-to-top">
          <img
            src={btnSrc}
            onClick={() => handleOnClick("#")}
            onMouseOver={onMouseOverButton}
            onMouseOut={onMouseOutButton}
            onTouchStart={onMouseOverButton}
            onTouchEnd={onMouseOutButton}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Publicity_sub;
