import React, { useEffect, useState } from "react";
import "./Card.css";
import defaultIcon from "./icon.webp";
// import test from "./test.jpg";
// import { useSelector, useDispatch } from "react-redux";
// import { setAreaone } from "../textareaheightSlice";
// import { RootState, AppDispatch } from "../store";

const Card = (props: {
  cardDetail: {
    icon: string;
    fullName: string;
    fullNameEng: string;
    position: string;
    positionEng: string;
    education: string;
    educationEng: string;
    link: string;
    category: string;
  };
  language: string;
}) => {
  const {
    icon,
    fullName,
    fullNameEng,
    position,
    positionEng,
    education,
    educationEng,
    link,
    category,
  } = props.cardDetail;

  useEffect(() => {
    let textareaHeight = 0;
    let textareaHeightposition = 0;
    let textareaHeightname = 0;

    const elements = document.getElementsByClassName(
      "card-text-education"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elements.length; i++) {
      if (elements[i]?.clientHeight > textareaHeight) {
        textareaHeight = elements[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.height = `${textareaHeight}px`;
    }

    const elementstwo = document.getElementsByClassName(
      "card-text-position"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementstwo.length; i++) {
      if (elementstwo[i]?.clientHeight > textareaHeightposition) {
        textareaHeightposition = elementstwo[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementstwo.length; i++) {
      elementstwo[i].style.height = `${textareaHeightposition}px`;
    }

    const elementsthree = document.getElementsByClassName(
      "card-title"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elementsthree.length; i++) {
      if (elementsthree[i]?.clientHeight > textareaHeightname) {
        textareaHeightname = elementsthree[i]?.clientHeight;
      }
    }
    for (let i = 0; i < elementsthree.length; i++) {
      elementsthree[i].style.height = `${textareaHeightname}px`;
    }
  });
  // const areaoneheight= useSelector((state:RootState)=>state.textareaheight.areaone)

  // const height = 200; // set the desired height value here

  // for (let i = 0; i < elements.length; i++) {
  // elements[i].style.height = `${height}px`;
  // }

  return (
    <div className="person-info-card">
      {props.language === "zh_hant" && (
        <>
          <div className="info-card-content">
            <img
              className="card-image"
              src={icon ? icon : defaultIcon}
              alt="Card"
            />
            <h2 className="card-title">{fullName}</h2>
            <h5 className="card-text-position">{position}</h5>
            <h5 className="card-text-education">{education}</h5>
            {link && link !== "" && (
              <a className="card-more-info" href={link} target="_blank">
                更多資料
              </a>
            )}
          </div>
        </>
      )}
      {props.language === "en" && (
        <>
          <div className="info-card-content">
            <img
              className="card-image"
              src={icon ? icon : defaultIcon}
              alt="Card"
            />
            <h2 className="card-title">{fullNameEng}</h2>
            <h5 className="card-text-position">{positionEng}</h5>
            <h5 className="card-text-education">{educationEng}</h5>
            {link && link !== "" && (
              <a className="card-more-info" href={link} target="_blank">
                Faculty Profile
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Card;
