import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import TrainingBG from "../Landing/Group 2477.webp";
import { RootState } from "../store";
import { useEffect, useState } from "react";

interface LoadingDisplayData {
    loadingText: string
}

interface PoolObject {
    [key: string]: string[]
}

const Loading = (props: LoadingDisplayData) => {
    const language: string = useSelector((state: RootState) => state.language.language);    // Get language code from Store, value = en OR zh_hant
    const origLang: string = language
    const msgPool: PoolObject = {
        "en": [
            "Your ACT journey begins soon...",
            "ACTuwise: Journey to Mindful Acceptance.",
            "Accept, Commit, Transform: Discover the Wisdom of ACTuwise.",
        ],
        "zh_hant": [
            "你的ACT之旅即將展開...",
            "ACTuwise：走向接納之旅。",
            "接納，承諾，轉變：發現ACTuwise的智慧。"
        ]
    }

    const [message, setMessage] = useState("")

    useEffect(() => {
        // console.log(`lang: ${language} -> ${props.loadingText}`)
        if (language !== origLang || message === "") {
            setMessage(getLoadingText(language))
        }
    }, [language])

    
    function getLoadingText(lang: string) {
        const pool: string[] = msgPool[`${lang}`] as string[]
        return pool[Math.floor(Math.random() * pool.length)]
    }

    return (
        <div className="loadingPage" style={{ backgroundColor: "#fffaeb", zIndex: "100", padding: "1rem" }}>
            <img src={TrainingBG} alt="" />
            <h3 className="fadeIn">{message}</h3>
            <BeatLoader color="#1A237E " />
        </div>
    )
}

export default Loading