import React, { useEffect, useState } from "react";
import "./AboutUs.css";
import Footer from "../Footer/Footer";
import test from "./test.webp";
import SectionBar from "../SectionBar/SectionBar";
import Header2 from "../Header2/Header2";
import axios from "axios";
import config from "../config";
import API from "../helper/ApiHelper";
import { RootState, AppDispatch } from "../store";
import { setEnglish, setChinese } from "../languageSlice";
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import Loading from "../Loading/Loading";

interface IconData {
  icon: string;
  IconData: string[];
}

interface Data {
  IconImg: IconData;
}

interface Content {
  type: string;
  text: string;
}

function AboutUs() {
  // request headers using cookies to get jwt
  // should have a token value = Cookies.get("jwt_authorization")
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  // this id is not official
  const id = "MeetUs";
  // session = data grep from backend
  const [session, setSession] = useState([] as any); // change the any type later
  const [contentList, setContentList] = useState([] as any);
  const [meetUsImage, setMeetUsImage] = useState("");
  const [loadingText, setLoadingText] = useState("Loading");
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  // axios get backend data
  async function getData() {
    const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent(orderBy: $orderBy) {
              title
              session
              template
              images (orderBy: $imagesOrderBy2,where:$imagesWhere2) {
                url
                priority
                category
                id
                isDeleted
              }
              id
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                priority
                id
                content
                category
                position
                langLabel{
                  id
                  lang
                  index
                }
                isDeleted
              }
              sessionImage
            }
          }
        }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!) ${request}`,
      variables: {
        where: { title: id },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },

        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res.data.data.menuBar.parentContent[0].contents);
        setSession(res.data.data.menuBar);
        setContentList(res.data.data.menuBar.parentContent[0].contents);
        setMeetUsImage(res.data.data.menuBar.parentContent[0].images[0].url);
      })
      .catch((err) => console.error("err", err));
  }

  // console.log(session);

  // frontend code start here
  const [data, setData] = useState<Data>({
    IconImg: {
      icon: test,
      IconData: [],
    },
  });
  // control the language zh_hant or en

  const language = useSelector((state: RootState) => state.language.language);

  // const content: Content[] = [
  //   { type: "title", text: "關於我們" },
  //   {
  //     type: "paragraph",
  //     text: "莊博士帶領的研究團隊近年致力與不同醫院和社會服務機構緊密合作，積極推廣接納與承諾治療至不同群組，我們的服務對象包括市民大眾、病患者及前線專職醫療人員。透過接納與承諾治療（ACT）令參加者了解其人生中真正重視的事情，定下以價值觀為基礎目標的態度付諸實行。",
  //   },
  //   // {
  //   //   type: "paragraph",
  //   //   text: "莊博士帶領的研究團隊近年致力與不同醫院和社會服務機構緊密合作，積極推廣接納與承諾治療至不同群組，我們的服務對象包括市民大案。",
  //   // },
  // ];

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mobile scroll header control
  // const isPhone = window.matchMedia("(max-width: 768px)").matches;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const phone = window.matchMedia("(max-width: 768px)").matches;
  //     let contenty: number = 159.19;
  //     let content = document.querySelector(
  //       ".about-us-content-section"
  //     ) as HTMLElement;
  //     const currentScrollPos = window.pageYOffset;
  //     const aboutUsContainers =
  //       document.getElementsByClassName("about-us-container");

  //     if (phone) {
  //       if (content)
  //         contenty = window.scrollY + content.getBoundingClientRect().top; // Y
  //       for (let i = 0; i < aboutUsContainers.length; i++) {
  //         const aboutUsContainer = aboutUsContainers[i] as HTMLElement;
  //         if (currentScrollPos >= contenty && aboutUsContainer) {
  //           aboutUsContainer.style.marginTop = "17rem";
  //         } else if (aboutUsContainer) {
  //           aboutUsContainer.style.marginTop = "2rem";
  //         }
  //       }
  //     } else {
  //       for (let i = 0; i < aboutUsContainers.length; i++) {
  //         const aboutUsContainer = aboutUsContainers[i] as HTMLElement;
  //         aboutUsContainer.style.marginTop = "-10%";
  //       }
  //     }
  //   };

  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Trigger the scroll event on page load
  //   handleScroll();

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isPhone, []]);
  useEffect(() => {
    getData();
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "關於我們 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "About Us - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (contentList.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [contentList]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="wrapper">
      <div className="general-header">
        <Header2 url="/about-us" />
      </div>
      <div className="about-us-content-section">
        <SectionBar
        // sections={[
        //   { name: "Meet Us", url: "/meetus" },
        //   { name: "Team In Charge", url: "/meetus/teamhead" },
        //   { name: "About Collaborator", url: "/meetus/collaborators" },
        //   {
        //     name: "Our Counsellor & Team Member",
        //     url: "/meetus/counsellors",
        //   },
        // ]}
        />

        <div className="about-us-container">
          <div id="middleSection" className="about-us-content">
            {contentList.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {e.position === "title" &&
                    e.langLabel[0].lang === language && (
                      <div
                        className="about-us-title"
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
            {contentList.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {e.position === "content1" &&
                    e.langLabel[0].lang === language && (
                      <div
                        className="about-us-paragraph"
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
            <img
              className="about-us"
              src={`${API.getImage}${meetUsImage}`}
              alt=""
            />
            {/* {data.IconImg.IconData.map((name, index) => (
              <p key={index} className="about-us-paragraph">
                {name}
              </p>
            ))} */}
            {contentList.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {e.position === "content2" && (
                    // e.langLabel[0].lang === language &&
                    <div
                      className="about-us-paragraph"
                      dangerouslySetInnerHTML={{ __html: e.content }}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
      <div id="AboutUs"></div>
    </div>
  );
}

export default AboutUs;
