import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const rootElement:HTMLElement|null = document.querySelector(':root')
    let removedSmoothScrollChecking:any = null
    let maxTry = 0
      const promise = new Promise((resolve, reject) => {
          rootElement?.style?.setProperty("scroll-behavior", 'auto', 'important');
        resolve('');
      })
      .then(()=>{
        return new Promise((resolve, reject)=>{
          removedSmoothScrollChecking = setInterval(()=>{
            if (!rootElement?.getAttribute('style')?.includes('scroll-behavior: auto') && maxTry < 100) return  ++maxTry
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'auto'
            });
            clearInterval(removedSmoothScrollChecking)
            resolve("")
          },10)
        })
      })
      .then(()=>{
        // rootElement?.style?.setProperty("scroll-behavior", 'smooth');  // Stop the scrolling animation when clicking on the card
      })
    return clearInterval(removedSmoothScrollChecking)

  }, [pathname]);

  return null;
}