import { isAndroid, isIOS, isIOS13, isIPad13, isIPhone13, isIPod13 } from 'react-device-detect'
import { useNavigate } from "react-router-dom";

const GooglePlay: string = 'https://play.google.com/store/apps/details?id=hk.edu.cuhk.nur.ACT'
const AppStore: string = 'https://apps.apple.com/hk/app/pai-act/id6462592721'

const App = () => {
    const nav = useNavigate()

    const redirectToStore = () => {
        const isAppleMobileDeviceResult: boolean = isAppleMobileDevice()
        // console.log(`[DetectDevice] isAndroid: ${isAndroid}, isAppleMobileDevice: ${isAppleMobileDeviceResult}`)
        if (isAppleMobileDeviceResult) {
            window.location.href = AppStore
            // console.log(`Redirect to: ${AppStore}`)
        } else {
            window.location.href = GooglePlay
            // console.log(`Redirect to: ${GooglePlay}`)
        }
        setTimeout(() => {
            nav(-1)
        }, 1000)
    }

    const isAppleMobileDevice = () => {
        return isIOS || isIOS13 || isIPad13 || isIPhone13 || isIPod13
    }

    redirectToStore()

    return (
        <div>
            <h1>Redirecting...</h1>
        </div>
    )
}

export { App, AppStore, GooglePlay }