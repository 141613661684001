import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

interface RoleState {
  role: string;
}

const initialState = () => {
  let role : string = "";
  let jwt : string = Cookies.get("jwt") as string;
  if (jwt != undefined) {
    let token : any = jwtDecode(jwt);
    role = token.role;
  } 
  return {role: role}
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRole(state, action){
      state.role=action.payload;
    },
    
  },
});

export const { setRole, } = roleSlice.actions;
export default roleSlice.reducer;
