import { InfoRounded } from "@mui/icons-material";
import { info } from "console";
import React, { useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import ResearchesSub from "../ResearchesSub/ResearchesSub";
import "./ResearchesCard.css";
import tempImage from "./tempImage.webp";

import API from "../helper/ApiHelper";

export type ResearchesInfo = {
  info: {
    budget: string;
    cardTopic: string;
    durationCover: string;
    sponsor: string;

    duration: string;
    funding: string;
    introduction: string;
    partnerOrganization: string;
    projectName: string;
    target: string;

    mainTitleTree2: string;
    content1: string;
    content2: string;
    content3: string;
    content4: string;
    content5: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    title5: string;

    contentTree3: string;
    mainTitleTree3: string;
    subTitleTree3: string;

    contentTree4: string;
    mainTitleTree4: string;
    subTitleTree4: string;

    image: string;
    url: string;
    pdfUrl: string;

    researchItemTitle: string;
    projectNameTitle: string;
    durationTitle: string;
    targetTitle: string;
    introductionTitle: string;
    partnerOrganizationTitle: string;
    fundingTitle: string;
    position: number;
  };
};

const ResearchesCard = ({
  props,
  baseRoute,
}: {
  props: ResearchesInfo;
  baseRoute: string;
}) => {
  return (
    <div className="researches-info-container">
      <div className="researches-info">
        {/* <button onClick={()=>{console.log("API.getImage: ", API.getImage)}}>Hello</button>
        <button onClick={()=>{console.log("props.info.image: ", props.info.image)}}>Hello2</button> */}
        <Link style={{ width: "100%" }} to={`${baseRoute}/${props.info.url}`}>
          <div className="researches-image-container-container">
            <div className="researches-image-container">
              <img
                className="researches-image"
                style={{ width: "100%" }}
                src={
                  API.getImage === props.info.image
                    ? tempImage
                    : props.info.image
                }
                alt={props.info.cardTopic}
              />
            </div>
          </div>
        </Link>
        <div className="researches-info-contents">
          <p className="researches-duration">{props.info.duration ? props.info.duration : <>&nbsp;</>}</p>
          <p className="researches-title">{props.info.cardTopic}</p>
          <p className="researches-sponsor">{props.info.sponsor}</p>
        </div>
      </div>
    </div>
  );
};

export default ResearchesCard;
