import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import "./Recruitment.css";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

function Recruitment() {
  const [data, setData] = useState([] as any);
  const [description, setDescription] = useState([] as any);
  const [jobs, setJobs] = useState([] as any);
  const [information, setInformation] = useState([] as any);
  const [title, setTitle] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  async function getData() {
    const request = `{childMenu(where: $where) {
      id,
      title,
      childContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
        }
        videos(orderBy: $videosOrderBy2) {
          url
          priority
          category
          title
          id
          type
        }
        audios (orderBy: $audiosOrderBy2) {
          url
          priority
          category
          title
          id
          type
        }
        pdf(orderBy: $pdfOrderBy2){
          originalFileName
          position
          priority
          title
          url
          category
          id
        }
        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!) ${request}`,
      variables: {
        where: { title: "Recruitment" },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.childMenu.childContent: ",
        //   res.data.data.childMenu.childContent
        // );
        // temp filter out the new data first, suppose to add this into each card using category to match which card display position
        // let positionArr = [] as any;
        // res.data.data.childMenu.childContent.forEach((e: any) => {
        //   for (let i = e.contents.length - 1; i >= 0; i--) {
        //     if (e.contents[i].position === "cardPosition") {
        //       positionArr.push(e.contents[i]);
        //       e.contents.splice(i, 1);
        //     }
        //   }
        // });
        // console.log("stored new data in here:", positionArr);
        setData(res.data.data.childMenu.childContent);
        setDescription(res.data.data.childMenu.childContent[0].contents);
        // console.log(res.data.data.childMenu.childContent[1].contents);
        let totalCardArr = [] as any;
        let totalCard = res.data.data.childMenu.childContent[1].contents.reduce(
          (acc: any, cur: any) => {
            if (!acc[cur.category]) {
              acc[cur.category] = 1;
            } else {
              acc[cur.category]++;
            }
            return acc;
          },
          {}
        );
        // console.log(totalCard);
        Object.keys(totalCard).forEach((e: any) =>
          totalCardArr.push({
            category: e,
            publishDate: "",
            jobTitle: "",
            jobTitleEng: "",
            position: null,
          })
        );
        // console.log(res.data.data.childMenu);
        totalCardArr.forEach((e: any, index: number) => {
          res.data.data.childMenu.childContent[1].contents.forEach(
            (element: any, idx: number) => {
              if (e.category === element.category) {
                if (element.position === "date") {
                  totalCardArr[index].publishDate = element.content;
                } else if (
                  element.position === "jobTitle" &&
                  element.langLabel[0].lang === "zh_hant"
                ) {
                  totalCardArr[index].jobTitle = element.content;
                } else if (
                  element.position === "jobTitle" &&
                  element.langLabel[0].lang === "en"
                ) {
                  totalCardArr[index].jobTitleEng = element.content;
                } else if (element.position === "cardPosition") {
                  totalCardArr[index].position = +element.content;
                }
              }
            }
          );
        });
        // console.log(totalCardArr);
        totalCardArr.sort((a: any, b: any) => a.position - b.position);
        setJobs(totalCardArr);
        setInformation(res.data.data.childMenu.childContent[2].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  async function getTitleData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        id
        contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }
      
      
        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, ) ${request}`,
      variables: {
        where: { title: "Get Involved" },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.menuBar.parentContent: ",
        //   res.data.data.menuBar.parentContent
        // );
        setTitle(res.data.data.menuBar.parentContent[1].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  const language = useSelector((state: RootState) => state.language.language);

  useEffect(() => {
    getData();
    getTitleData();
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "人才招聘 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Position Available - ACT"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (
      title.length &&
      description.length &&
      jobs.length &&
      information.length
    ) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [title, description, jobs, information]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="get-involved-wrapper">
      <div className="general-header">
        <Header2 url="/get-involved/position-available" />
      </div>
      <div className="recruitment-container">
        <div className="recruitment-title">
          {title.map((e: any, idx: number) => {
            return (
              <div key={idx}>
                {language === "en" &&
                  e.langLabel[0].lang === language &&
                  e.position === "subTitle1" && (
                    <div className="recruitment-title-text" dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                {language === "zh_hant" &&
                  e.langLabel[0].lang === language &&
                  e.position === "subTitle1" && (
                    <div className="recruitment-title-text" dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
              </div>
            );
          })}
        </div>
        <div className="recruitment-title-paragragh">
          {description.map((e: any, idx: number) => {
            return (
              <div key={idx}>
                {language === "en" && e.langLabel[0].lang === language && (
                  <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                )}
                {language === "zh_hant" && e.langLabel[0].lang === language && (
                  <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                )}
              </div>
            );
          })}
        </div>
        <div className="recruitment-list">
          <div className="recruitment-list-title">
            <div className="publish-date">
              {language === "en" ? "Published Date" : "刊登日期"}
            </div>
            <div className="job-title">
              {language === "en" ? "Open Positions" : "職位名稱"}
            </div>
          </div>
          {jobs.map((e: any, idx: number) => {
            // var i;
            // var j = jobs.length / 3 - 1;
            // console.log(j);
            // console.log(e);

            return (
              <div className="recruitment-list-card" key={idx}>
                <div className="publish-date">{e.publishDate}</div>
                {language === "zh_hant" && (
                  <div className="job-title">{e.jobTitle}</div>
                )}
                {language === "en" && (
                  <div className="job-title">{e.jobTitleEng}</div>
                )}
              </div>
            );
          })}
          {/* <div className="publish-date">20/04/2023</div>
                        <div className="job-title">青年工作幹事（項目經理）（註冊學位社工）</div> */}
        </div>
        <div className="recruitment-more-information-title">
          {information.map((e: any, idx: number) => {
            return (
              <div key={idx}>
                {information[5]?.content ? (
                  <a href={information[5]?.content} target="_blank">
                    {language === "en" && e.priority === 2 && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                    {language === "zh_hant" && e.priority === 1 && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  </a>
                ) : (
                  <div>
                    {language === "en" && e.priority === 2 && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                    {language === "zh_hant" && e.priority === 1 && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="recruitment-title-paragragh">
          {information.map((e: any, idx: number) => {
            return (
              <div key={idx}>
                {language === "en" && e.priority === 4 && (
                  <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                )}
                {language === "zh_hant" && e.priority === 3 && (
                  <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                )}
              </div>
            );
          })}
        </div>
        <div className="recruitment-e-mail">
          {information.map((e: any, idx: number) => {
            return (
              <div className="recruitment-e-mail-content" key={idx}>
                {e.priority === 5 && (
                  <a
                    href="mailto:hello@abc.com"
                    dangerouslySetInnerHTML={{ __html: e.content }}
                  ></a>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Recruitment;
