import React, { useEffect, useState } from "react";
import "./SectionBar.css";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import config from "../config";
import buttonHandleEnglish from "../Header2/Header2";
import buttonHandleChinese from "../Header2/Header2";
function SectionBar() {
  const [data, setData] = useState([] as any);
  const [childdata, setChilddata] = useState([] as any);
  const [childdataone, setChilddataone] = useState([] as any);
  const [childdatatwo, setChilddatatwo] = useState([] as any);
  const [childdatathree, setChilddatathree] = useState([] as any);

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  async function menuTitle() {
    const AllMenuItems = `{menuBars(orderBy: $orderBy) {
        id,
        title,
        url,
        priority,
        child(orderBy: $childOrderBy) {
          id,
          title,
          url,
          priority,
          displayName,
          displayNameEng,
        },
        displayName,
        displayNameEng,
      }
    }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($orderBy: [MenuBarOrderByInput!]!, $childOrderBy:[ChildMenuOrderByInput!]!) ${AllMenuItems}`,
      variables: {
        orderBy: [
          {
            priority: "asc",
          },
        ],
        childOrderBy: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.menuBars: ",
        //     res.data.data.menuBars,
        // )
        setData(res.data.data.menuBars[6]);
        setChilddata(res.data.data.menuBars[6].child);
        setChilddataone(res.data.data.menuBars[6].child[0]);
        setChilddatatwo(res.data.data.menuBars[6].child[1]);
        setChilddatathree(res.data.data.menuBars[6].child[2]);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  const language = useSelector((state: RootState) => state.language.language);
  // console.log("language", language);
  const [isEng, setIsEng] = useState(true);

  useEffect(() => {
    menuTitle();

    if (language === "en") {
      setIsEng(true);
    } else {
      setIsEng(false);
    }
  }, [language]);

  return (
    <>
      {isEng ? (
        <SectionBarElement
          sections={[
            { name: data.displayNameEng, url: "/en/about-us" },
            {
              name: childdataone.displayNameEng,
              url: "/en/about-us/team-lead",
            },
            {
              name: childdatatwo.displayNameEng,
              // url: "/en/about-us/our-people",
              url: "/en/about-us/our-collaborators",
            },
            {
              name: childdatathree.displayNameEng,
              // url: "/en/about-us/our-collaborators",
              url: "/en/about-us/our-people",
            },
          ]}
        />
      ) : (
        <SectionBarElement
          sections={[
            { name: data.displayName, url: "/ch/about-us" },
            { name: childdataone.displayName, 
              url: "/ch/about-us/team-lead",
            },
            { name: childdatatwo.displayName, 
              // url: "/ch/about-us/our-people", 
              url: "/ch/about-us/our-collaborators",
            },
            {
              name: childdatathree.displayName,
              url: "/ch/about-us/our-people", 
              // url: "/ch/about-us/our-collaborators",
            },
          ]}
        />
      )}
    </>
  );

  function SectionBarElement(props: {
    sections: { name: string; url: string }[];
  }) {
    const [currentUrl, setCurrentUrl] = useState(
      window.location.href.toLowerCase().startsWith("http://localhost:3000")
        ? window.location.href.split("3000").at(-1)
        : window.location.href.split("com").at(-1)
    );
    // console.log(window.location.href.toLowerCase())
    // console.log("currentUrl",window.location.href.toLowerCase().startsWith("http://localhost:3000")? window.location.href.split("3000").at(-1):window.location.href.split("com").at(-1))
    // console.log("languagecode",currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1])

    useEffect(() => {
      const handleScroll = () => {
        const sectionOffsets = props.sections.map((section) => ({
          offsetTop: section.url.startsWith("#")
            ? (document.querySelector(section.url) as HTMLElement)?.offsetTop
            : null,
          url: section.url,
        }));
        const current = sectionOffsets
          .reverse()
          .find(
            ({ offsetTop }) => offsetTop && window.pageYOffset + 1 >= offsetTop
          )?.url;

        setCurrentUrl(
          current ||
            window.location.href
              .toLowerCase()
              .startsWith("http://localhost:3000")
            ? window.location.href.split("3000").at(-1)
            : window.location.href.split("com").at(-1)
        );
      };

      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [props.sections]);

    function handleOnClick(url: string) {
      if (currentUrl === url) {
        return;
      }

      if (url.startsWith("#")) {
        const element = document.querySelector(url);
        element && element.scrollIntoView({ behavior: "smooth" });
      } else {
        window.location.href = url;
      }
    }

    const isPhone = window.matchMedia("(max-width: 768px)").matches;

    useEffect(() => {
      if (isPhone) {
        const sectionBar = document.querySelector(
          ".section-bar"
        ) as HTMLElement;
        let prevScrollPos = window.pageYOffset;

        window.addEventListener("scroll", () => {
          const currentScrollPos = window.pageYOffset;

          if (currentScrollPos > window.innerHeight * 0.4) {
            if (prevScrollPos > currentScrollPos) {
              sectionBar?.classList.add("scroll-up");
            } else {
              sectionBar?.classList.remove("scroll-up");
            }
          } else {
            sectionBar?.classList.remove("scroll-up");
          }

          prevScrollPos = currentScrollPos;
        });
      }
    }, [isPhone]);

    return (
      <div className="meet-us-section-bar">
        <div className="section-bar-container">
          {props.sections.map((section, index) => (
            <React.Fragment key={index}>
              <div
                className={
                  currentUrl === section.url ? "section current" : "section"
                }
                key={section.name}
                onClick={() => handleOnClick(section.url)}
              >
                {section.name}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default SectionBar;
