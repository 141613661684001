import { useEffect, useState } from "react";
import "./Footer.css";
import fbIcon from "./fb2.png";
import igIcon from "./Group 2354.webp";
import twitterIcon from "./x_darkblue.png";
import cuIcon from "./cu_logo_4c_centre.webp";
import medicineIcon from "./40-Logo_FINAL_CMYK-vertical.webp";
import nursingIcon from "./Nursing_Logo_White_Ribbon_WORDS1.webp";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Link } from "react-router-dom";
import { fetchFooterData } from "./fetchFooterData";
import config from "../config";
import { useNavigate } from 'react-router-dom';


export interface FooterLink {
  title: string[];
  path: string | "";
}

interface WebLink {
  [key: string]: {
    [key: string]: string;
  };
}

const webLinkSet: WebLink = {
  CUHK: {
    en: "https://www.cuhk.edu.hk/english/",
    zh_hant: "https://www.cuhk.edu.hk/chinese/",
  },
  MED_CUHK: {
    en: "https://www.med.cuhk.edu.hk/",
    zh_hant: "https://www.med.cuhk.edu.hk/",
  },
  NUR_CUHK: {
    en: "https://www.nur.cuhk.edu.hk/",
    zh_hant: "https://www.nur.cuhk.edu.hk/front-page-hk/",
  },
};

const Footer = () => {
  const language = useSelector((state: RootState) => state.language.language);
  const role = useSelector((state: RootState) => state.role.role);
  const [lanString, setLanString] = useState([] as any);
  const [languageLabel, setLanguageLabel] = useState(1 as number);
  const navigate = useNavigate();

  function redirectToOtherPage(page: string) {
    let state = { page }
    navigate({ pathname: `${lanString}/home` }, { state });
  }


  useEffect(() => {
    if (language === "zh_hant") {
      setLanguageLabel(0);
      setLanString("/ch");
    } else if (language === "en") {
      setLanguageLabel(1);
      setLanString("/en");
    }
  }, [language]);

  const home: string[] = ["主頁", "Home"];
  const aboutUs: string[] = ["關於我們", "About Us"];
  const contactUs: string[] = ["聯絡我們", "Contact Us"];
  const [links, setLinks] = useState<FooterLink[]>();

  useEffect(() => {
    fetchFooterData((footer) => {
      // console.log(footer);f
      setLinks(footer);
    });
  }, []);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const homeLink = <a href={lanString + "/home"}>{home[languageLabel]}</a>;
  const aboutUsLink = (
    <a href={lanString + "/about-us"}>{aboutUs[languageLabel]}</a>
  );
  const contactUsLink = (
    <a
      className="text-pointer-style"
      onClick={() => { redirectToOtherPage('contactUs') }}
    // href={lanString + "/home#contactUsContainer"} [back] resume this

    >
      {contactUs[languageLabel]}
    </a>
  );

  return (
    <div className="footer">
      {/* Sitemap */}
      <div className="site-map-container">
        <div className="site-map">
          {homeLink}
          {aboutUsLink}
          {contactUsLink}
          {links && links[4] && (
            <Link to={lanString + links[4].path}>
              {links[4].title[languageLabel]}
            </Link>
          )}
          {links && links[5] && (
            <Link to={lanString + links[5].path}>
              {links[5].title[languageLabel]}
            </Link>
          )}
        </div>
        <div className="site-map">
          {links && links[6] && (
            <Link to={lanString + links[6].path}>
              {links[6].title[languageLabel]}
            </Link>
          )}
          {links && links[7] && (
            <Link to={lanString + links[7].path}>
              {links[7].title[languageLabel]}
            </Link>
          )}
          {role === "participant" && links && links[8] && (
            <Link to={lanString + links[8].path}>
              {links[8].title[languageLabel]}
            </Link>
          )}
        </div>
      </div>
      <div className="bottom-block">
        <div className="left-block">
          {/* Owner logo */}
          <div className="institution-icons-container">
            <a href={webLinkSet.CUHK[language]} target="_blank">
              <img
                className="institution-icon"
                src={cuIcon}
                alt={
                  language === "zh_hant"
                    ? "香港中文大學"
                    : "The Chinese University of Hong Kong"
                }
              />
            </a>
            <a href={webLinkSet.MED_CUHK[language]} target="_blank">
              <img
                className="institution-icon"
                src={medicineIcon}
                alt="Faculty of Medicine, The Chinese University of Hong Kong"
              />
            </a>
            <a href={webLinkSet.NUR_CUHK[language]} target="_blank">
              <img
                className="institution-icon"
                src={nursingIcon}
                alt={language === "zh_hant" ? "" : ""}
              />
            </a>
          </div>
          {/* Copyright */}
          <div className="copyright">
            {languageLabel === 1 ? (
              <div>
                <span>
                  Copyright © {`${new Date(Date.now()).getFullYear()}`} All
                  Rights Reserved.{" "}
                </span>
                <span>The Nethersole School of Nursing, </span>
                <span>Faculty of Medicine, </span>
                <span>The Chinese University of Hong Kong.</span>
              </div>
            ) : (
              <div>
                <span>
                  © {`${new Date(Date.now()).getFullYear()}`} 版權所有{" "}
                </span>
                <span>香港中文大學</span>
                <span>醫學院</span>
                <span>那打素護理學院</span>
              </div>
            )}
          </div>
        </div>
        {/* Follow us */}
        <div className="social-media right-block">
          <div className="title">
            {links && links[3] && links[3].title[languageLabel]}
          </div>
          <div className="icons">
            {links && links[0] && links[0].path !== "" && (
              <a href={links[0].path} target="_blank">
                <img className="social-icon" src={fbIcon} alt="" />
              </a>
            )}
            {links && links[1] && links[1].path !== "" && (
              <a href={links[1].path} target="_blank">
                <img className="social-icon" src={igIcon} alt="" />
              </a>
            )}
            {links && links[2] && links[2].path !== "" && (
              <a href={links[2].path} target="_blank">
                <img className="social-icon" src={twitterIcon} alt="" />
              </a>
            )}
          </div>
        </div>
      </div>
      {/* Print version */}
      <div className="version">
        <span>V{config.versionNum}</span>
      </div>
    </div>
  );
};

export default Footer;
