import React, { useEffect, useState } from "react";
import "./Counselor.css";
import CounselorCard from "../CounselorCard/CounselorCard";
import Footer from "../Footer/Footer";
import SectionBar from "../SectionBar/SectionBar";
import Header2 from "../Header2/Header2";
import axios from "axios";
import config from "../config";
import API from "../helper/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import { RootState, AppDispatch } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import Loading from "../Loading/Loading";

type cardDetails = {
  icon: string;
  fullName: string;
  fullNameEng: string;
  position: string;
  positionEng: string;
  education: string;
  educationEng: string;
  email: string;
  phone: any;
  moreInfo: string;
  moreInfoEng: string;
  category: string;
  cardPosition: number;
};

const Counselor = () => {
  let id = "Counsellors";

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const [counsellorList, setCounsellorList] = useState([] as any);
  const [teamList, setTeamList] = useState([] as any);
  const [studentHelperList, setStudentHelperList] = useState([] as any);

  const language = useSelector((state: RootState) => state.language.language);
  const [loadingText, setLoadingText] = useState("Loading");

  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  async function getData() {
    const request = `{childMenu(where: $where){
      id,
      title
      childContent {
          title,
          session,
          template,
          images {
              url,
              priority,
              id,
              category
          }
          id
          contents(where: $contentsWhere2) {
              category,
              content,
              id,
              priority,
              position,
              isDeleted,
              langLabel{
                id
                lang
                index
              }
          }
          sessionImage
      }
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: id },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data.data);
        // setPageInfo(res.data.data.childMenu);
        let receivedData = res.data.data.childMenu;
        let counsellorArr: [cardDetails] = [] as any;
        let teamArr: [cardDetails] = [] as any;
        let studentHelperArr: [cardDetails] = [] as any;
        if (res.data.data.childMenu.childContent[0]) {
          if (res.data.data.childMenu.childContent[0].contents.length > 0) {
            let idList = [] as any;
            receivedData.childContent[0].contents.forEach((e: any) => {
              idList.push({ id: e.category });
            });
            // console.log(idList);
            let totalList = idList.reduce((acc: any, curr: any) => {
              // console.log(acc);
              let key = `${curr.id}`;
              if (key in acc) {
                acc[key]++;
              } else {
                acc[key] = 1;
              }
              return acc;
            }, {});
            for (const [key, value] of Object.entries(totalList)) {
              if (key.split("_")[0] === "counsellor") {
                counsellorArr.push({
                  icon: "",
                  fullName: "",
                  fullNameEng: "",
                  category: key,
                  position: "",
                  positionEng: "",
                  education: "",
                  educationEng: "",
                  email: "",
                  phone: null,
                  moreInfo: "",
                  moreInfoEng: "",
                  cardPosition: 0,
                });
              } else if (key.split("_")[0] === "team") {
                teamArr.push({
                  icon: "",
                  fullName: "",
                  fullNameEng: "",
                  category: key,
                  position: "",
                  positionEng: "",
                  education: "",
                  educationEng: "",
                  email: "",
                  phone: null,
                  moreInfo: "",
                  moreInfoEng: "",
                  cardPosition: 0,
                });
              } else if (key.split("_")[0] === "studentHelper") {
                studentHelperArr.push({
                  icon: "",
                  fullName: "",
                  fullNameEng: "",
                  category: key,
                  position: "",
                  positionEng: "",
                  education: "",
                  educationEng: "",
                  email: "",
                  phone: null,
                  moreInfo: "",
                  moreInfoEng: "",
                  cardPosition: 0,
                });
              }
            }
            // console.log(receivedData.childContent[0].contents);
            receivedData.childContent[0].contents.forEach((e: any) => {
              counsellorArr.forEach((counsellor: any, idx: number) => {
                if (e.category === counsellor.category) {
                  if (
                    e.position === "fullName" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    counsellorArr[idx]["fullName"] = e.content;
                  } else if (
                    e.position === "position" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    counsellorArr[idx]["position"] = e.content;
                  } else if (
                    e.position === "education" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    counsellorArr[idx]["education"] = e.content;
                  } else if (
                    e.position === "moreInfo" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    counsellorArr[idx]["moreInfo"] = e.content;
                  }
                  // English version
                  else if (
                    e.position === "fullNameEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    counsellorArr[idx]["fullNameEng"] = e.content;
                  } else if (
                    e.position === "positionEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    counsellorArr[idx]["positionEng"] = e.content;
                  } else if (
                    e.position === "educationEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    // console.log("education", e);
                    counsellorArr[idx]["educationEng"] = e.content;
                  } else if (
                    e.position === "moreInfoEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    counsellorArr[idx]["moreInfoEng"] = e.content;
                  } else if (e.position === "email") {
                    counsellorArr[idx]["email"] = e.content;
                  } else if (e.position === "phone") {
                    counsellorArr[idx]["phone"] = e.content;
                  } else if (e.position === "cardPosition") {
                    counsellorArr[idx]["cardPosition"] = +e.content;
                  }
                }
              });
              teamArr.forEach((team: any, idx: number) => {
                if (e.category === team.category) {
                  if (
                    e.position === "fullName" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    teamArr[idx]["fullName"] = e.content;
                  } else if (
                    e.position === "position" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    teamArr[idx]["position"] = e.content;
                  } else if (
                    e.position === "education" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    teamArr[idx]["education"] = e.content;
                  } else if (
                    e.position === "moreInfo" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    teamArr[idx]["moreInfo"] = e.content;
                  }
                  // English Version
                  else if (
                    e.position === "fullNameEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    teamArr[idx]["fullNameEng"] = e.content;
                  } else if (
                    e.position === "positionEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    // console.log("team position", e);
                    teamArr[idx]["positionEng"] = e.content;
                  } else if (
                    e.position === "educationEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    teamArr[idx]["educationEng"] = e.content;
                  } else if (
                    e.position === "moreInfoEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    teamArr[idx]["moreInfoEng"] = e.content;
                  } else if (e.position === "email") {
                    teamArr[idx]["email"] = e.content;
                  } else if (e.position === "phone") {
                    teamArr[idx]["phone"] = e.content;
                  } else if (e.position === "cardPosition") {
                    teamArr[idx]["cardPosition"] = +e.content;
                  }
                }
              });
              studentHelperArr.forEach((studentHelper: any, idx: number) => {
                if (e.category === studentHelper.category) {
                  if (
                    e.position === "fullName" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    studentHelperArr[idx]["fullName"] = e.content;
                  } else if (
                    e.position === "position" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    studentHelperArr[idx]["position"] = e.content;
                  } else if (
                    e.position === "education" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    studentHelperArr[idx]["education"] = e.content;
                  } else if (
                    e.position === "moreInfo" &&
                    e.langLabel[0].lang === "zh_hant"
                  ) {
                    studentHelperArr[idx]["moreInfo"] = e.content;
                  }
                  // English Version
                  else if (
                    e.position === "fullNameEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    studentHelperArr[idx]["fullNameEng"] = e.content;
                  } else if (
                    e.position === "positionEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    // console.log("team position", e);
                    studentHelperArr[idx]["positionEng"] = e.content;
                  } else if (
                    e.position === "educationEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    studentHelperArr[idx]["educationEng"] = e.content;
                  } else if (
                    e.position === "moreInfoEng" &&
                    e.langLabel[0].lang === "en"
                  ) {
                    studentHelperArr[idx]["moreInfoEng"] = e.content;
                  } else if (e.position === "email") {
                    studentHelperArr[idx]["email"] = e.content;
                  } else if (e.position === "phone") {
                    studentHelperArr[idx]["phone"] = e.content;
                  } else if (e.position === "cardPosition") {
                    studentHelperArr[idx]["cardPosition"] = +e.content;
                  }
                }
              });
            });
            receivedData.childContent[0].images.forEach((e: any) => {
              counsellorArr.forEach((counsellor: any, idx: number) => {
                if (e.category === counsellor.category) {
                  counsellorArr[idx]["icon"] = `${API.getImage}${e.url}`;
                }
              });
              teamArr.forEach((team: any, idx: number) => {
                if (e.category === team.category) {
                  teamArr[idx]["icon"] = `${API.getImage}${e.url}`;
                }
              });
              studentHelperArr.forEach((studentHelper: any, idx: number) => {
                if (e.category === studentHelper.category) {
                  studentHelperArr[idx]["icon"] = `${API.getImage}${e.url}`;
                }
              });
            });
          }
        }
        // console.log(counsellorArr);
        // console.log(teamArr);
        counsellorArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
        teamArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
        studentHelperArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
        setCounsellorList(counsellorArr);
        setTeamList(teamArr);
        setStudentHelperList(studentHelperArr);
      })
      .catch((err) => console.error("err", err));
  }

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // // Mobile scroll header control
  // const isPhone = window.matchMedia("(max-width: 768px)").matches;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const phone = window.matchMedia("(max-width: 768px)").matches;
  //     let contenty: number = 159.19;
  //     let content = document.querySelector(
  //       "#counselor-content-section"
  //     ) as HTMLElement;
  //     const currentScrollPos = window.pageYOffset;
  //     const counselorContentContainer = document.getElementById(
  //       "counselor-content-container"
  //     );

  //     if (phone) {
  //       if (content)
  //         contenty = window.scrollY + content.getBoundingClientRect().top; // Y
  //       if (currentScrollPos >= contenty && counselorContentContainer) {
  //         counselorContentContainer.style.marginTop = "17rem";
  //       } else if (counselorContentContainer) {
  //         counselorContentContainer.style.marginTop = "2rem";
  //       }
  //     } else {
  //       if (counselorContentContainer) {
  //         counselorContentContainer.style.marginTop = "-19rem";
  //       }
  //     }
  //   };

  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Trigger the scroll event on page load
  //   handleScroll();

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isPhone, []]);
  useEffect(() => {
    getData();
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "我們的輔導員 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Our People - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (counsellorList.length && teamList.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [counsellorList, teamList]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="wrapper">
      <div className="general-header">
        <Header2 url="/about-us/our-people" />
      </div>
      <div id="counselor-content-section">
        <SectionBar
        // sections={[
        //   { name: "Meet Us", url: "/meetus" },
        //   { name: "Team In Charge", url: "/meetus/teamhead" },
        //   { name: "About Collaborator", url: "/meetus/collaborators" },
        //   {
        //     name: "Our Counsellor & Team Member",
        //     url: "/meetus/counsellors",
        //   },
        // ]}
        />

        <div id="counselor-content-container">
          <h1>{language === "en" ? "Our Research Team" : "我們的研究團隊"}</h1>
          <div id="team-cards-container">
            {teamList.map((counsellor: any, index: number) => (
              <CounselorCard
                key={index}
                counsellor={counsellor}
                language={language}
              />
            ))}
          </div>
          <h1>{language === "en" ? "Our Counsellors" : "我們的輔導員"}</h1>
          <div id="counselor-cards-container">
            {counsellorList.map((counsellor: any, index: number) => (
              <CounselorCard
                key={index}
                counsellor={counsellor}
                language={language}
              />
            ))}
          </div>
          <h1>
            {language === "en" ? "Our Student Helpers" : "我們的學生助理"}
          </h1>
          <div id="studentHelper-cards-container">
            {studentHelperList.map((studentHelper: any, index: number) => (
              <CounselorCard
                key={index}
                counsellor={studentHelper}
                language={language}
              />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Counselor;
