import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import BeatLoader from "react-spinners/BeatLoader";
import slugify from "slugify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { HashNavigation, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import API from "../helper/ApiHelper";
import { setChinese, setEnglish } from "../languageSlice";
import { RootState } from "../store";
import demoVideo from "./Banner Video_20231129-2.mp4";
import contactUs from "./Contact Us Graphic.webp";
import trainingBg from "./Group 2477.webp";
import researchPhone from "./pai-act-qrcode-frame.png";
import "./Landing.css";
import leftArrowB from "./Larrow_black.webp";
import leftArrowY from "./Larrow_yelo.webp";
import rightArrowB from "./Rarrow_black.webp";
import rightArrowY from "./Rarrow_yelo.webp";
import mail from "./ion_mail.webp";
import address from "./mdi_address-marker.webp";
import calendar from "./mdi_calendar.webp";
import leftQuote from "./mingcute_quote-left-fill.webp";
import rightQuote from "./mingcute_quote-right-fill.webp";
import phone from "./phone.webp";
import timelineCircle from "./timelineCircle.webp";
import Loading from "../Loading/Loading";
import AppStoreIcon from "./app store.png";
import GooglePlayIcon from "./google play.png";
import { GooglePlay, AppStore } from "../Store/App";
import { useLocation } from 'react-router-dom';


interface CustomSwiperProps extends SwiperProps {
  autoHeight: boolean;
  spaceBetween: number;
  loop: boolean;
  hashNavigation: { watchState: boolean };
  pagination: { clickable: boolean; el: string };
  navigation: { nextEl: string; prevEl: string };
  modules: any[];
  className: string;
}

export default function Landing() {
  const customSwiperProps: CustomSwiperProps = {
    autoHeight: true,
    spaceBetween: 0,
    loop: true,
    hashNavigation: {
      watchState: true,
    },
    pagination: {
      clickable: true,
      el: ".landing-publicity-scroll",
    },
    navigation: {
      nextEl: ".landing-arrow-next",
      prevEl: ".landing-arrow-prev",
    },
    modules: [Pagination, Navigation, HashNavigation],
    className: "mySwiper",
  };
  const [logging, setLogging] = useState(false as boolean);
  const [trainingData, setTrainingData] = useState([0, 1, 2, 3, 4] as any[]);
  const [prevBtnSrc, setPrevBtnSrc] = useState([
    leftArrowB,
    leftArrowB,
    leftArrowB,
  ] as string[]);
  const [backBtnSrc, setBackBtnSrc] = useState([
    rightArrowB,
    rightArrowB,
    rightArrowB,
  ] as string[]);
  const [scrollPos, setScrollPos] = useState([
    0 as number,
    [] as number[],
    [] as number[],
  ] as any[]);

  const [top, setTop] = useState([] as any);
  const [topVideo, setTopVideo] = useState("" as string);
  const [publicity, setPublicity] = useState([] as any);
  const [publicityCard, setPublicityCard] = useState([] as any);
  const [training, setTraining] = useState([] as any);
  const [trainingCard, setTrainingCard] = useState([] as any[]);
  const [research, setResearch] = useState([] as any);
  const [researchCard, setResearchCard] = useState([[], []] as any);
  const [order, setOrder] = useState([] as any);
  const [app, setApp] = useState([] as any);
  const [contact, setContact] = useState([] as any);
  const [langLabel, setLangLabel] = useState(0 as number);
  const language = useSelector((state: RootState) => state.language.language);
  const displayTwoCard = window.matchMedia("(max-width: 992px)").matches;
  const displayOneCard = window.matchMedia("(max-width: 768px)").matches;
  const [researchCardNumber, setResearchCardNumber] = useState(3 as number);
  const [orderCardNumber, setOrderCardNumber] = useState(3 as number);
  const [isResearchCardUpdated, setIsResearchCardUpdated] =
    useState<boolean>(false);

  const [index, setIndex] = useState("arrow");

  const [isIOS, setIsIOS] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOS = Boolean(
      userAgent.match(/iPhone|iPad|iPod/i) ||
      (userAgent.match(/Mac/) && navigator.maxTouchPoints > 2)
    );
    setIsIOS(isIOS);
  }, []);
  useEffect(() => {
    document.title = "ACTuwise"; // Update the document title on mount
  }, []);
  const [orderCard, setOrderCard] = useState([] as any[]);

  const location = useLocation();


  useEffect(() => {
    setIsResearchCardUpdated(true);
    const tid = setTimeout(() => {
      setIsResearchCardUpdated(false);
    }, 350);
    return () => {
      clearTimeout(tid);
    };
  }, [scrollPos["1"]]);

  function dateFormat(date: string) {
    let formatDate: any[] = [];
    const [day, month, year] = date.split("/");

    const formattedDate = new Date(+year, parseInt(month) - 1, +day);

    const monthName = new Intl.DateTimeFormat("en", { month: "short" }).format(
      formattedDate
    );

    const formattedDateEn = `${day} ${monthName} ${year}`;
    const formattedDateZh = `${year}年${month}月${day}日`;
    formatDate.push(formattedDateEn);
    formatDate.push(formattedDateZh);

    return formatDate;
  }

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  function getData() {
    const request = `{
      landing: menuBar(where: $where) {
        id,
        title,
        parentContent(orderBy: $orderBy) {
          title
          session
          template
          images (orderBy: $imagesOrderBy2, where: $imagesWhere2){
            url
            priority
            category
            id
            title
            type
            isDeleted
          }
          id
          contents(orderBy: $contentsOrderBy2, where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel{
              lang
              id
              index
            }
            isDeleted
          }
          videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          audios (orderBy: $audiosOrderBy2, where: $audiosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
            originalFileName
            position
            priority
            title
            url
            category
            id
            isDeleted
          }
          sessionImage
        }
      }
      publicity: menuBar(where: $where2) {
        id,
        title,
        parentContent(orderBy: $orderBy) {
          title
          session
          template
          images (orderBy: $imagesOrderBy2, where: $imagesWhere2){
            url
            priority
            category
            id
            title
            type
            isDeleted
          }
          id
          contents(orderBy: $contentsOrderBy2, where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel{
              lang
              id
              index
            }
            isDeleted
          }
          videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          audios (orderBy: $audiosOrderBy2, where: $audiosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
            originalFileName
            position
            priority
            title
            url
            category
            id
            isDeleted
          }
          sessionImage
        }
      }
      training: menuBar(where: $where3) {
        id,
        title,
        parentContent(orderBy: $orderBy) {
          title
          session
          template
          images (orderBy: $imagesOrderBy2, where: $imagesWhere2){
            url
            priority
            category
            id
            title
            type
            isDeleted
          }
          id
          contents(orderBy: $contentsOrderBy2, where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel{
              lang
              id
              index
            }
            isDeleted
          }
          videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          audios (orderBy: $audiosOrderBy2, where: $audiosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
            originalFileName
            position
            priority
            title
            url
            category
            id
            isDeleted
          }
          sessionImage
        }
      }
      research: menuBar(where: $where4) {
        id,
        title,
        parentContent(orderBy: $orderBy) {
          title
          session
          template
          images (orderBy: $imagesOrderBy2, where: $imagesWhere2){
            url
            priority
            category
            id
            title
            type
            isDeleted
          }
          id
          contents(orderBy: $contentsOrderBy2, where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel{
              lang
              id
              index
            }
            isDeleted
          }
          videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          audios (orderBy: $audiosOrderBy2, where: $audiosWhere2) {
            url
            priority
            category
            title
            id
            type
            isDeleted
          }
          pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
            originalFileName
            position
            priority
            title
            url
            category
            id
            type
            isDeleted
          }
          sessionImage
        }
      }
    }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!, $where2: MenuBarWhereUniqueInput!, $where3: MenuBarWhereUniqueInput!, $where4: MenuBarWhereUniqueInput!, $imagesWhere2: ImageDetailWhereInput, $contentsWhere2: ContentDetailWhereInput, $videosWhere2: VideoDetailWhereInput, $audiosWhere2: AudioDetailWhereInput, $pdfWhere2: PdfDetailWhereInput) ${request}`,
      variables: {
        where: { title: "Landing" },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        videosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        audiosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        pdfWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
        where2: { title: "Publicity" },
        where3: { title: "Training" },
        where4: { title: "Research" },
      },
    };

    // Get backend data using Axios
    axios({
      url: API.local, // Reference in ApiHelper
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log("res.data.data:", res.data.data);
        if (res.data.data) {
          let receivedData = res.data.data;
          let tempImg: any[] = [[], [], [], [], [], [], []];
          let pinnedArr: any = []

          for (const sessionName in receivedData) {
            let tempArray: any[] = [[] as any[], [] as any[]];
            let tempArrayData: any[] = [];
            let tempCard: any[] = [[], []];

            let tempCardData: any[] = [];
            let trainingPastData: any[] = [];
            let tempVideo: string = "";
            let tempPubCard: any[] = [[], []];
            //console.log("This is", sessionName, receivedData[sessionName]);
            // get data if session has content
            if (receivedData[sessionName].parentContent.length) {
              // handle session contents
              receivedData[sessionName].parentContent.forEach(
                (part: any, index: number) => {
                  switch (sessionName) {
                    case "landing":
                      let tempLanding: any = {};
                      tempLanding.id = part.session;
                      part.contents.map((e: any) => {
                        switch (part.session) {
                          case 1:
                            // set description data
                            switch (e.langLabel.length) {
                              case 0:
                                break;
                              default:
                                switch (e.langLabel[0].lang) {
                                  case "en":
                                    tempLanding.titleEn = e.content;
                                    break;
                                  case "zh_hant":
                                    tempLanding.titleZh = e.content;
                                    break;
                                  default:
                                }
                            }
                            break;
                          case 2:
                            // set order data
                            tempCardData = [];
                            const tempCardText = part.contents.filter(
                              (e: any) => {
                                return (
                                  (e.position == "name" && e.content != "") ||
                                  (e.position == "title" && e.content != "") ||
                                  (e.position == "content" &&
                                    e.content != "") ||
                                  (e.position == "preface" &&
                                    e.content != "") ||
                                  e.position == "cardPosition"
                                );
                              }
                            );
                            const tempCardImage = part.images.filter(
                              (e: any) => {
                                return (
                                  (e.type == "image" &&
                                    typeof e.url != undefined &&
                                    e.url != "") ||
                                  (e.type == "icon" &&
                                    typeof e.url != undefined &&
                                    e.url != "")
                                );
                              }
                            );
                            tempCardData = tempCardData.concat(
                              tempCardText,
                              tempCardImage
                            );
                            break;
                          case 3:
                            // set contact us data
                            switch (e.position) {
                              case "phone":
                              case "email":
                                tempLanding[e.position] = e.content;
                                break;
                              case "address":
                                switch (e.langLabel[0].lang) {
                                  case "en":
                                    tempLanding.addressEn = e.content;
                                    break;
                                  case "zh_hant":
                                    tempLanding.addressZh = e.content;
                                    break;
                                  default:
                                }
                                break;
                              default:
                            }
                            break;
                        }
                      });

                      tempArrayData.push(tempLanding);
                      break;
                    case "publicity":
                      switch (part.title) {
                        case "Publicity Main Title":
                          tempArrayData = part.contents;
                          break;
                        default:
                          const tempCardText = part.contents.filter(
                            (e: any) => {
                              if (e.position === 'pinned' && e.content === 'true') { //get all pinned items id
                                pinnedArr.push(e)
                              }
                              return (
                                (e.position == "title" &&
                                  !e.category.includes("@@")) ||
                                e.position == "content1" ||
                                (e.position == "date" &&
                                  typeof e.content != undefined &&
                                  e.content != "" &&
                                  e.content.includes("/"))
                              );
                            }
                          );
                          const tempCardImage = part.images.filter((e: any) => {
                            return (
                              e.title == "Image" &&
                              typeof e.url != undefined &&
                              e.url != ""
                            );
                          });
                          tempCardData = tempCardData.concat(
                            tempCardText,
                            tempCardImage
                          );
                      }
                      break;
                    case "training":
                      switch (part.title) {
                        case "Page Title":
                          tempArrayData = part.contents;
                          break;
                        case "Training Materials (Login Required)":
                          break;
                        case "Details of Upcoming Events":
                          //console.log("getting data", part);
                          tempCardData = part.contents.filter((e: any) => {
                            return (
                              (e.position == "title" &&
                                !e.category.includes("@@")) ||
                              (e.position == "date" &&
                                typeof e.content != undefined &&
                                e.content != "" &&
                                e.content.includes("/"))
                            );
                          });
                          //console.log('tempCardData', tempCardData)
                          break;

                        case "Details of Past Events":
                          trainingPastData = part.contents.filter((e: any) => {
                            return (
                              (e.position == "title" &&
                                !e.category.includes("@@")) ||
                              (e.position == "date" &&
                                typeof e.content != undefined &&
                                e.content != "" &&
                                e.content.includes("/"))
                            );
                          });
                          break;
                        default:
                      }
                      break;
                    case "research":
                      switch (part.title) {
                        case "Research Introduction":
                          tempArrayData = part.contents.filter((e: any) => {
                            return e.position == "title";
                          });
                          break;
                        default:
                          const tempCardText = part.contents.filter(
                            (e: any) => {
                              return (
                                e.position == "cardTopic" ||
                                (e.position == "duration" && e.content != "")
                              );
                            }
                          );
                          const tempCardImage = part.images.filter((e: any) => {
                            return (
                              e.title == "Card Image" &&
                              typeof e.url != undefined &&
                              e.url != ""
                            );
                          });
                          tempCardData = tempCardData.concat(
                            tempCardText,
                            tempCardImage
                          );
                      }
                      break;
                    default:
                  }
                }
              );

              // handler tempArray for non card content
              let tempLangEn: any = {};
              let tempLangZh: any = {};
              switch (sessionName) {
                case "landing":
                  //console.log(tempArray);
                  tempArrayData.map((s: any) => {
                    tempLangEn = {};
                    tempLangZh = {};
                    Object.keys(s).map((e: any) => {
                      switch (e.includes("En")) {
                        case true:
                          tempLangEn[e.split("En")[0]] = s[e];
                          break;
                        case false:
                          switch (e.includes("Zh")) {
                            case true:
                              tempLangZh[e.split("Zh")[0]] = s[e];
                              break;
                            case false:
                              tempLangEn[e] = s[e];
                              tempLangZh[e] = s[e];
                              break;
                            default:
                          }
                          break;
                        default:
                      }
                    });
                    tempArray[0].push(tempLangEn);
                    tempArray[1].push(tempLangZh);
                  });
                  break;
                case "publicity":
                case "training":
                case "research":
                  tempArrayData.map((e: any) => {
                    tempLangEn = [];
                    tempLangZh = [];
                    let newTempLangEn = { title: "" };
                    let newTempLangZh = { title: "" };

                    switch (e.langLabel[0].lang) {
                      case "en":
                        newTempLangEn.title = e.content;
                        tempArray[0] = newTempLangEn;
                        break;
                      case "zh_hant":
                        newTempLangZh.title = e.content;
                        tempArray[1] = newTempLangZh;
                        break;
                      default:
                    }
                  });
                  break;
                default:
              }

              let mergeArr: any[] = [[], []];
              let tempLangCardEn: any[] = [];
              let tempLangCardZh: any[] = [];
              switch (sessionName) {
                case "landing":
                  //console.log(tempCardData);
                  // group the card by category
                  if (tempCardData.length) {
                    mergeArr = tempCardData.reduce((acc: any, cur: any) => {
                      const { position, content, url, category, type } = cur;

                      const cardKey = category.split("@@")[0];
                      if (!acc[cardKey]) {
                        acc[cardKey] = [];
                      }
                      switch (cur.type) {
                        case "icon":
                          acc[cardKey].push({
                            title: type,
                            url: url,
                            lang: "both",
                          });
                          break;
                        case "image":
                          acc[cardKey].push({
                            title: type,
                            url: url,
                            lang: "both",
                          });
                          break;
                        default:
                          switch (cur.langLabel.length as number) {
                            case 0:
                              acc[cardKey].push({
                                title: position,
                                content: content,
                                lang: "both",
                              });
                              break;
                            default:
                              acc[cardKey].push({
                                title: position,
                                content: content,
                                lang: cur.langLabel[0].lang,
                              });
                          }
                      }
                      return acc;
                    }, []);
                  }
                  // group by language
                  // console.log(mergeArr);
                  for (const cardName in mergeArr) {
                    tempLangCardEn = [];
                    tempLangCardZh = [];
                    let newTempLangCardEn: any = {};
                    let newTempLangCardZh: any = {};
                    if (mergeArr[cardName].length == 9) {
                      mergeArr[cardName].map((e: any) => {
                        // console.log(e);
                        switch (e.title) {
                          case "icon":
                            newTempLangCardEn[
                              "icon"
                            ] = `${API.getImage}${e.url}`;
                            newTempLangCardZh[
                              "icon"
                            ] = `${API.getImage}${e.url}`;
                            break;
                          case "image":
                            newTempLangCardEn[
                              "image"
                            ] = `${API.getImage}${e.url}`;
                            newTempLangCardZh[
                              "image"
                            ] = `${API.getImage}${e.url}`;
                            break;
                          case "cardPosition":
                            newTempLangCardEn["cardPosition"] = +e.content;
                            newTempLangCardZh["cardPosition"] = +e.content;
                            break;
                          default:
                            let key = "";
                            switch (e.title) {
                              case "title":
                                key = "title";
                                break;
                              case "name":
                                key = "name";
                                break;
                              case "preface":
                                key = "preface";
                                break;
                              case "cardPosition":
                                key = "cardPosition";
                                break;
                              default:
                            }
                            switch (e.lang) {
                              case "en":
                                newTempLangCardEn[key] = e.content;
                                break;
                              case "zh_hant":
                                newTempLangCardZh[key] = e.content;
                                break;
                              default:
                            }
                        }
                      });

                      // console.log(newTempLangCardEn);
                      // console.log(newTempLangCardZh);
                      // drop unable car

                      if (Object.keys(newTempLangCardEn).length == 6) {
                        tempCard[0].push(newTempLangCardEn);
                      }
                      if (Object.keys(newTempLangCardZh).length == 6) {
                        tempCard[1].push(newTempLangCardZh);
                      }
                    }
                  }
                  break;
                case "publicity":
                  // group the card by category
                  // console.log('publicity_tempCardData',tempCardData);
                  if (tempCardData.length) {
                    mergeArr = tempCardData.reduce((acc: any, cur: any) => {
                      const { position, content, url, category, type, id } =
                        cur;
                      const cardKey = category.split("@@")[0];
                      if (!acc[cardKey]) {
                        acc[cardKey] = [];
                      }
                      switch (cur.type) {
                        case "image":
                          acc[cardKey].push({
                            title: type,
                            url: url,
                            lang: "both",
                          });
                          break;
                        default:
                          switch (cur.langLabel.length as number) {
                            case 0:
                              acc[cardKey].push({
                                title: position,
                                link: id,
                                content: content,
                                lang: "both",
                              });
                              break;
                            default:
                              acc[cardKey].push({
                                title: position,
                                link: id,
                                content: content,
                                category: category,
                                lang: cur.langLabel[0].lang,
                              });
                          }
                      }
                      // console.log(acc)
                      return acc;
                    }, []);
                  }
                  let filteredPinnedTempCard: any = [[], []]; //first arr: ENG version, sec arr: ZH version

                  // group by language
                  for (const cardName in mergeArr) {
                    // check if is pinnedobj
                    const isPinnedObject = pinnedArr?.find((item: any) => item.category === cardName && item.position === "pinned"); //see if current card is pinned position
                    tempLangCardEn = [];
                    tempLangCardZh = [];
                    let newTempLangCardEn: any = {};
                    let newTempLangCardZh: any = {};
                    if (mergeArr[cardName].length == 6 || isPinnedObject) {
                      mergeArr[cardName].map((e: any) => {
                        newTempLangCardEn['category'] = cardName;
                        newTempLangCardZh['category'] = cardName;

                        switch (e.title) {
                          case "date":
                            const formatDate = dateFormat(e.content);
                            newTempLangCardEn["date"] = formatDate[0];
                            newTempLangCardZh["date"] = formatDate[1];
                            break;
                          case "image":
                            newTempLangCardEn[
                              "image"
                            ] = `${API.getImage}${e.url}`;
                            newTempLangCardZh[
                              "image"
                            ] = `${API.getImage}${e.url}`;
                            break;
                          default:
                            let key = "";
                            switch (e.title) {
                              case "title":
                                key = "title";
                                break;
                              case "content1":
                                key = "content";
                                break;
                              default:
                            }
                            switch (e.lang) {
                              case "en":
                                switch (key) {
                                  case "title":
                                    newTempLangCardEn[key] = e.content;
                                    newTempLangCardEn["link"] = e.link;
                                    newTempLangCardZh["link"] = e.link;
                                    break;
                                  default:
                                    newTempLangCardEn[key] = e.content;
                                }
                                break;
                              case "zh_hant":
                                newTempLangCardZh[key] = e.content;
                                break;
                              default:
                            }
                        }
                      });

                      // drop unable car
                      if (Object.keys(newTempLangCardEn).length == 6 && !isPinnedObject) {
                        tempCard[0].push(newTempLangCardEn);
                      }
                      if (Object.keys(newTempLangCardZh).length == 6 && !isPinnedObject) {
                        tempCard[1].push(newTempLangCardZh);
                      }
                      if (isPinnedObject) {
                        filteredPinnedTempCard[0].push({ ...newTempLangCardEn, priority: isPinnedObject?.priority }); // Add filtered items to the separate tempCarday
                        filteredPinnedTempCard[1].push({ ...newTempLangCardZh, priority: isPinnedObject?.priority }); // Add filtered items to the separate tempCarday
                      }
                    }
                  }

                  // sort pinned position accordingly
                  filteredPinnedTempCard[0].sort((a: any, b: any) => a.priority - b.priority);
                  filteredPinnedTempCard[1].sort((a: any, b: any) => a.priority - b.priority);


                  // sort other card by date
                  tempCard.forEach((e: any, i: number) => {
                    e.sort((a: any, b: any) => {
                      let dateA, dateB;
                      switch (i) {
                        case 0:
                          dateA = new Date(a.date);
                          dateB = new Date(b.date);
                          break;
                        case 1:
                          dateA = new Date(
                            a.date?.replace(/年|月/g, "-")?.replace(/日/g, "")
                          );
                          dateB = new Date(
                            b.date?.replace(/年|月/g, "-")?.replace(/日/g, "")
                          );
                          break;
                        default:
                      }
                      if (typeof dateA == "undefined") return 1;
                      if (typeof dateB == "undefined") return -1;
                      if (dateA == dateB) return 0;
                      return dateA > dateB ? -1 : 1;
                    });
                  });
                  // add pinned cards back to tempCard arr
                  tempCard[0] = filteredPinnedTempCard[0].concat(tempCard[0]);
                  tempCard[1] = filteredPinnedTempCard[1].concat(tempCard[1]);
                  // make it max 12 cards
                  tempCard[0] = tempCard[0].slice(0, 12);
                  tempCard[1] = tempCard[1].slice(0, 12);
                  break;
                case "training":
                  // console.log('pre-tempCardData', tempCardData)
                  // group the card by category
                  if (tempCardData.length) {
                    mergeArr = tempCardData.reduce((acc: any, cur: any) => {
                      const { title, content, category, position, id } = cur;
                      const cardKey = category.split("@@")[0];
                      if (!acc[cardKey]) {
                        acc[cardKey] = [];
                      }
                      switch (cur.type) {
                        default:
                          switch (cur.langLabel.length as number) {
                            case 0:
                              acc[cardKey].push({
                                title: title,
                                link: id,
                                content: content,
                                category: category,
                                langLabel: 2,
                              });
                              break;
                            default:
                              acc[cardKey].push({
                                title: title,
                                link: id,
                                content: content,
                                category: category,
                                lang: cur.langLabel[0].lang,
                              });
                          }
                      }
                      return acc;
                    }, []);
                  }
                  // console.log('training_mergeArray', mergeArr)
                  // group by language
                  for (const cardName in mergeArr) {
                    tempLangCardEn = [];
                    tempLangCardZh = [];
                    let newTempLangCardEn: any = {};
                    let newTempLangCardZh: any = {};
                    if (mergeArr[cardName].length == 3) {
                      mergeArr[cardName].map((e: any) => {
                        switch (e.title) {
                          case "Date":
                            const formatDate = dateFormat(e.content);
                            newTempLangCardEn.date = formatDate[0];
                            newTempLangCardZh.date = formatDate[1];
                            break;
                          default:
                            switch (e.lang) {
                              case "en":
                                // remove the style
                                newTempLangCardEn.title = e.content;
                                //e.content.split("style=")[0]
                                //e.content.split("style=")[1];
                                newTempLangCardEn.category = e.category;
                                newTempLangCardEn.link = e.link;
                                break;
                              case "zh_hant":
                                // newTempLangCardZh.title =
                                //   e.content.split("style=")[0] +
                                //   e.content.split("style=")[1];
                                newTempLangCardZh.title = e.content;
                                newTempLangCardZh.category = e.category;
                                break;
                              default:
                            }
                        }
                      });

                      //console.log('trainingnewTempLangCardEn',newTempLangCardEn);
                      //console.log('trainingnewTempLangCardZh',newTempLangCardZh);
                      tempCard[0].push(newTempLangCardEn);
                      tempCard[1].push(newTempLangCardZh);
                    }
                  }

                  // add previous training if upcoming training < 3
                  // tempCard.map((i: any) => {
                  //   if (i.length < 3) {
                  // group the card
                  if (trainingPastData.length) {
                    mergeArr = trainingPastData.reduce((acc: any, cur: any) => {
                      const { title, content, category, id } = cur;
                      const cardKey = category.split("@@")[0];
                      if (!acc[cardKey]) {
                        acc[cardKey] = [];
                      }
                      switch (cur.type) {
                        default:
                          switch (cur.langLabel.length as number) {
                            case 0:
                              acc[cardKey].push({
                                title: title,
                                link: id,
                                content: content,
                                category: category,
                                langLabel: 2,
                              });
                              break;
                            default:
                              acc[cardKey].push({
                                title: title,
                                link: id,
                                content: content,
                                category: category,
                                lang: cur.langLabel[0].lang,
                              });
                          }
                      }
                      return acc;
                    }, []);
                    // console.log('training_mergeArray', mergeArr)
                  }

                  // group by language
                  for (const cardName in mergeArr) {
                    tempLangCardEn = [];
                    tempLangCardZh = [];
                    let newTempLangCardEn: any = {};
                    let newTempLangCardZh: any = {};
                    if (mergeArr[cardName].length == 3) {
                      mergeArr[cardName].map((e: any) => {
                        switch (e.title) {
                          case "Date":
                            const formatDate = dateFormat(e.content);
                            newTempLangCardEn.date = formatDate[0];
                            newTempLangCardZh.date = formatDate[1];
                            break;
                          default:
                            switch (e.lang) {
                              case "en":
                                // add finish for past event
                                e.content = e.content;
                                e.url = e.content;
                                /* e.content.slice(0, 3) +
                                      "[Finish] " +
                                      e.content.slice(3); */
                                // remove style
                                newTempLangCardEn.title = e.content;
                                /*                                       e.content.split("style=")[0] +
                                      e.content.split("style=")[1]; */
                                newTempLangCardEn.category = e.category;
                                newTempLangCardEn.link = e.link;
                                break;
                              case "zh_hant":
                                e.content = e.content;
                                // e.content.slice(0, 3) +
                                // "[已完成] " +
                                // e.content.slice(3);
                                // newTempLangCardZh.title =
                                //   e.content.split("style=")[0] +
                                //   e.content.split("style=")[1];
                                newTempLangCardZh.title = e.content;
                                newTempLangCardZh.category = e.category;
                                break;
                              default:
                            }
                        }
                      });
                      tempCard[0].push(newTempLangCardEn);
                      tempCard[1].push(newTempLangCardZh);
                    }
                  }
                  // console.log(tempCard);
                  // console.log(new Date(tempCard[0][1]?.date.split("-").at(-1)))
                  // console.log(new Date(tempCard[0][2]?.date.split("-").at(-1)))
                  // console.log(new Date(tempCard[1][1]?.date.replace(/年|月/g, "-")?.replace(/日/g, "")))
                  // console.log(new Date(tempCard[1][2]?.date.replace(/年|月/g, "-")?.replace(/日/g, "")))
                  // console.log(tempCard[1][1]?.date.replace(/年|月/g, "-")?.replace(/日/g, "").slice(0,10))
                  // display 3 cards if has past event
                  // tempCard.slice(0, 3);
                  //   }
                  // // });
                  //              sort card by date

                  tempCard.forEach((e: any, i: number) => {
                    e.sort((a: any, b: any) => {
                      let dateA, dateB;
                      switch (i) {
                        case 0:
                          dateA = new Date(a.date?.split("-")?.at(-1));
                          dateB = new Date(b.date?.split("-")?.at(-1));
                          break;
                        case 1:
                          dateA = new Date(
                            a.date
                              ?.replace(/年|月/g, "-")
                              ?.replace(/日/g, "")
                              ?.slice(0, 10)
                          );
                          dateB = new Date(
                            b.date
                              ?.replace(/年|月/g, "-")
                              ?.replace(/日/g, "")
                              ?.slice(0, 10)
                          );
                          break;
                        default:
                      }
                      if (typeof dateA == "undefined") return 1;
                      if (typeof dateB == "undefined") return -1;
                      if (dateA == dateB) return 0;
                      return dateA > dateB ? -1 : 1;
                    });
                  });
                  break;

                case "research":
                  // group by category
                  if (tempCardData.length) {
                    mergeArr = tempCardData.reduce((acc: any, cur: any) => {
                      const { position, content, url, category, type, id } =
                        cur;
                      const cardKey = category.split("@@")[0];
                      if (!acc[cardKey]) {
                        acc[cardKey] = [];
                      }
                      switch (cur.type) {
                        case "image":
                          acc[cardKey].push({
                            title: type,
                            url: url,
                            lang: "both",
                          });
                          break;
                        default:
                          switch (cur.langLabel.length as number) {
                            case 0:
                              acc[cardKey].push({
                                title: position,
                                link: id,
                                content: content,
                                lang: "both",
                              });
                              break;
                            default:
                              acc[cardKey].push({
                                title: position,
                                link: id,
                                content: content,
                                category: category,
                                lang: cur.langLabel[0].lang,
                              });
                          }
                      }
                      return acc;
                    }, []);
                  }

                  // group by language
                  for (const cardName in mergeArr) {
                    tempLangCardEn = [];
                    tempLangCardZh = [];
                    let newTempLangCardEn: any = {};
                    let newTempLangCardZh: any = {};

                    mergeArr[cardName].map((e: any) => {
                      switch (e.title) {
                        case "duration":
                          newTempLangCardEn["date"] = e.content;
                          newTempLangCardZh["date"] = e.content;
                          break;
                        case "image":
                          newTempLangCardEn[
                            "image"
                          ] = `${API.getImage}${e.url}`;
                          newTempLangCardZh[
                            "image"
                          ] = `${API.getImage}${e.url}`;
                          break;
                        case "cardTopic":
                          switch (e.lang) {
                            case "en":
                              newTempLangCardEn["title"] = e.content;
                              newTempLangCardEn["link"] = e.link;
                              newTempLangCardZh["link"] =
                                e.content + e.category;
                              break;
                            case "zh_hant":
                              newTempLangCardZh["title"] = e.content;
                              break;
                            default:
                          }
                          break;
                        default:
                      }
                    });

                    // drop unable car
                    if (Object.keys(newTempLangCardEn).length == 4) {
                      tempCard[0].push(newTempLangCardEn);
                    }
                    if (Object.keys(newTempLangCardZh).length == 4) {
                      tempCard[1].push(newTempLangCardZh);
                    }
                  }
                  break;
                default:
              }

              // store data
              switch (sessionName) {
                case "landing":
                  //console.log(tempArray);
                  // console.log(tempCard);
                  let tempDescription: any[] = [] as any[];
                  let tempOrder: any[] = [] as any[];
                  let tempContact: any[] = [] as any[];
                  tempArray.map((l: any, langIndex: number) => {
                    l.map((e: any) => {
                      switch (e.id) {
                        case 1:
                          tempDescription.push(e);
                          break;
                        case 2:
                          break;
                        case 3:
                          tempContact.push(e);
                          break;
                        default:
                      }
                    });
                  });
                  tempCard[0].sort(
                    (a: any, b: any) => a.cardPosition - b.cardPosition
                  );
                  tempCard[1].sort(
                    (a: any, b: any) => a.cardPosition - b.cardPosition
                  );
                  setTop(tempDescription);
                  setOrderCard(tempCard);

                  setContact(tempContact);
                  break;
                case "publicity":
                  if (tempCard[0].length > 14) {
                    tempCard[0].slice(0, 14);
                    tempCard[1].slice(0, 14);
                  }
                  setPublicity(tempArray);
                  setPublicityCard(tempCard);
                  break;
                case "training":
                  setTraining(tempArray);
                  //console.log('Training_tempArray',tempArray)
                  setTrainingCard(tempCard);
                  // console.log('TrainingCard_tempCard',tempCard)
                  break;
                case "research":
                  // tempCard[0] = tempCard[0].concat(tempCard[0]);
                  setResearch(tempArray);
                  setResearchCard(tempCard);
                  break;
                default:
              }
            }
          }
        }
      })
      .catch((err) => console.error("err", err));
  }

  // console.log(orderCard);

  let images = [] as any;
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const [isAnimating, setIsAnimating] = useState(false);

  publicityCard[0]?.forEach((element: any, index: number) => {
    images.push(element.image);
  });

  const onRightArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newPos = [...scrollPos];
    const target = e.target as HTMLElement;
    switch (target.id) {
      case "0":
        switch (scrollPos[target.id]) {
          case 0:
            newPos[0] = publicityCard[langLabel].length - 1;
            break;
          default:
            newPos[0] = scrollPos[0] - 1;
        }
        break;
      case "1":
        if (researchCard[langLabel].length > researchCardNumber) {
          newPos[1] = newPos[1].map((i: number) => {
            i -= 1;
            if (i < 0) {
              i += researchCard[langLabel].length;
            }
            return i;
          });
        }
        break;
      case "2":
        if (orderCard[langLabel].length >= orderCardNumber) {
          newPos[2] = newPos[2].map((i: number) => {
            i -= 1;
            if (i < 0) {
              i += orderCard[langLabel].length;
            }
            return i;
          });
        }
        break;
      default:
    }
    setScrollPos(newPos);
  };

  const onLeftArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newPos = [...scrollPos];
    const target = e.target as HTMLElement;
    switch (target.id) {
      case "0":
        switch (scrollPos[target.id]) {
          case publicityCard[langLabel].length - 1:
            newPos[0] = 0;
            break;
          default:
            newPos[0] = scrollPos[0] + 1;
        }
        break;
      case "1":
        if (researchCard[langLabel].length > researchCardNumber) {
          newPos[1] = newPos[1].map((i: number) => {
            i += 1;
            if (i >= researchCard[langLabel].length) {
              i = 0;
            }
            return i;
          });
        }
        break;
      case "2":
        if (orderCard[langLabel].length >= orderCardNumber) {
          newPos[2] = newPos[2].map((i: number, index: number) => {
            i += 1;
            if (i >= orderCard[langLabel].length) {
              i = 0;
            }
            return i;
          });
        }
        break;
      default:
    }
    setScrollPos(newPos);
  };

  const onMouseOverPrevButton = (e: any) => {
    if (!isIOS) {
      const btnSrc = [...prevBtnSrc];
      // switch (e.target.id) {
      //   case "0":
      //     btnSrc[e.target.id] = leftArrowY;
      //     break;
      //   case "1":
      //     if (researchCard[langLabel].length > researchCardNumber) {
      //       btnSrc[e.target.id] = leftArrowY;
      //     }
      //     break;
      //   case "2":
      //     if (orderCard[langLabel].length > orderCardNumber) {
      //       btnSrc[e.target.id] = leftArrowY;
      //     }
      //     break;
      //   default:
      // }
      btnSrc[0] = leftArrowY;
      setPrevBtnSrc(btnSrc);
    }
  };

  const onMouseOutPrevButton = (e: any) => {
    const btnSrc = [...prevBtnSrc];
    // switch (e.target.id) {
    //   case "0":
    //     btnSrc[e.target.id] = leftArrowB;
    //     break;
    //   case "1":
    //     if (researchCard[langLabel].length > researchCardNumber) {
    //       btnSrc[e.target.id] = leftArrowB;
    //     }
    //     break;
    //   case "2":
    //     if (orderCard[langLabel].length > orderCardNumber) {
    //       btnSrc[e.target.id] = leftArrowB;
    //     }
    //     break;
    //   default:
    // }
    btnSrc[0] = leftArrowB;
    setPrevBtnSrc(btnSrc);
  };

  const onMouseOverBackButton = (e: any) => {
    if (!isIOS) {
      const btnSrc = [...backBtnSrc];
      // switch (e.target.id) {
      //   case "0":
      //     btnSrc[e.target.id] = rightArrowY;
      //     break;
      //   case "1":
      //     if (researchCard[langLabel].length > researchCardNumber) {
      //       btnSrc[e.target.id] = rightArrowY;
      //     }
      //     break;
      //   case "2":
      //     if (orderCard[langLabel].length > orderCardNumber) {
      //       btnSrc[e.target.id] = rightArrowY;
      //     }
      //     break;
      //   default:
      // }
      btnSrc[0] = rightArrowY;
      setBackBtnSrc(btnSrc);
    }
  };

  const onMouseOutBackButton = (e: any) => {
    const btnSrc = [...backBtnSrc];
    // switch (e.target.id) {
    //   case "0":
    //     btnSrc[e.target.id] = rightArrowB;
    //     break;
    //   case "1":
    //     if (researchCard[langLabel].length > researchCardNumber) {
    //       btnSrc[e.target.id] = rightArrowB;
    //     }
    //     break;
    //   case "2":
    //     if (orderCard[langLabel].length > orderCardNumber) {
    //       btnSrc[e.target.id] = rightArrowB;
    //     }
    //     break;
    //   default:
    // }
    btnSrc[0] = rightArrowB;
    setBackBtnSrc(btnSrc);
  };

  const modifyindex = () => {
    setIndex("element");
    setTimeout(() => {
      setIndex("arrow");
    }, 500);
  };

  const handlePublicityClick = (e: any) => {
    const newPos = [...scrollPos];
    newPos[0] = Number(e.target.id);
    setScrollPos(newPos);
  };

  // console.log(publicityCard)
  // console.log(images)

  // set scroll card limit by screen size
  useEffect(() => {
    switch (displayTwoCard) {
      case true:
        setOrderCardNumber(1);
        switch (displayOneCard) {
          case true:
            setResearchCardNumber(1);
            break;
          default:
            setResearchCardNumber(2);
        }
        break;
      default:
        setResearchCardNumber(3);
        setOrderCardNumber(3);
    }
  }, [displayOneCard, displayTwoCard]);

  // screen resize listener
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [lanString, setLanString] = useState([] as any);
  // change language label
  useEffect(() => {
    // console.log(currentUrl?.split("/")[3]);
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLangLabel(1);
      setLanString("/ch");
    } else {
      setLangLabel(0);
      setLanString("/en");
    }
  }, [language]);
  useEffect(() => {
    if (language === "zh_hant") {
      setLangLabel(1);
      setLanString("/ch");
    } else {
      setLangLabel(0);
      setLanString("/en");
    }
  }, [language]);

  // set scroll position
  useEffect(() => {
    let tempScrollPos = [...scrollPos];
    tempScrollPos[0] = 0;
    if (researchCard.length) {
      switch (researchCard[langLabel].length) {
        case 0:
          tempScrollPos[1] = [];
          break;
        case 1:
          tempScrollPos[1] = [0];
          break;
        case 2:
          tempScrollPos[1] = [0, 1];
          break;
        default:
          tempScrollPos[1] = [0, 1, 2];
      }
    }
    if (orderCard.length) {
      switch (orderCard[langLabel].length) {
        case 0:
          tempScrollPos[2] = [];
          break;
        case 1:
          tempScrollPos[2] = [0];
          break;
        case 2:
          tempScrollPos[2] = [0, 1];
          break;
        default:
          tempScrollPos[2] = [0, 1, 2];
      }
    }
    setScrollPos(tempScrollPos);
  }, [researchCard, orderCard, language, langLabel]);

  useEffect(() => {
    if (localStorage.getItem("logging")) {
      setLogging(true);
    } else {
      setLogging(false);
    }
    getData();


  }, []);

  // console.log("1:",scrollPos);
  // console.log(top);
  // console.log(publicity);
  // console.log(publicityCard);
  // console.log(training);
  // console.log('training:', trainingCard);
  // console.log(research);
  // console.log(researchCard);
  // console.log("2:",order);
  // console.log("3:",orderCard);
  // console.log(contact);

  // loading page
  const [loadingText, setLoadingText] = useState("Loading");
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
    } else {
      setLoadingText("Loading");
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  // let loading = true;
  /*   console.log(
    publicity.length,
    training.length,
    research.length,
    order.length,
    contact.length
  ); */
  const containerRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    setIsLoading(true);
    if (
      publicity.length &&
      training.length &&
      research.length &&
      // && order.length
      contact.length
    ) {
      setIsLoading(false);

      // const hash = window.location.hash;
      // const sectionId: string = "#contactUsContainer";
      // if (hash === sectionId && containerRef.current) {
      //   tryGotoContactUs(50);
      // }

      if (location.state?.page === 'contactUs') {
        // if is redirected from "contact us", scroll to bottom
        // document
        //   .querySelector("div.contactUsContainer")
        //   ?.scrollIntoView();
        tryGotoContactUs(50);
        window.history.replaceState({}, document.title);
      }
    }
  }, [publicity, training, research, order, contact, langLabel]);


  function tryGotoContactUs(interval: number) {
    const result: boolean = gotoContactUs();
    if (!result) {
      setTimeout(() => {
        tryGotoContactUs(interval);
      }, interval);
    }
  }

  function gotoContactUs() {
    containerRef.current?.scrollIntoView({ behavior: "auto" });
    return containerRef.current !== null;
  }

  function getAppIntroTitle(lang: string) {
    switch (lang) {
      case "zh_hant":
        return (
          <h1 className="landing-title landing-box">Pai.ACT 手機應用程式</h1>
        );
      default:
        return (
          <h1 className="landing-title landing-box">
            Pai.ACT Mobile Application
          </h1>
        );
    }
  }

  function getAppIntroDescription(lang: string) {
    switch (lang) {
      case "zh_hant":
        return (
          <>
            <p className="landing-para">
              Pai.ACT是一個基於接納與承諾治療(Acceptance and Commitment
              therapy)理念的手機應用程式，專為以粵語為母語的特殊需要兒童照顧者而設計。
            </p>
            <p className="landing-para">
              程式中由深度學習驅動的聊天機器人Pai運用手機語音識別及人工智能技術評估家長所面臨的心理挑戰。它更在與家長對話時可以模仿接納與承諾治療（ACT）輔導員的對話模式。
            </p>
            <p className="landing-para">
              對話後，Pai亦能夠針對不同家長的需要度身訂造一個持續五至六星期與ACT有關的自學課程，內容包括閱讀材料、意象練習、覺察練習和比喻體驗練習等等，讓心理健康支援變得更加個人化及更方便。
            </p>
            <p className="landing-para">
              Pai.ACT應用程式已於2023年10月申請美國臨時專利（No.
              63/587,850），並已於11月正式推出。
            </p>
            <p className="landing-para">
              下載方法︰
              <br />
              以智能電話於Google Play / App Store
              <br />
              搜尋︰Pai.ACT
            </p>
            <p className="landing-para">或按以下圖片下載程式︰</p>
            <div className="landing-para app-download-store">
              <a href={GooglePlay} aria-label="到Google Play" target="_blank">
                <img src={GooglePlayIcon} alt="在Google Play下載" />
              </a>
              <a href={AppStore} aria-label="到App Store" target="_blank">
                <img src={AppStoreIcon} alt="在App Store下載" />
              </a>
            </div>
          </>
        );
      default:
        return (
          <>
            <p className="landing-para">
              Pai.ACT is a mobile application based on the framework of
              Acceptance and Commitment Therapy (ACT), specifically designed for
              Cantonese-speaking parents or caregivers of children with special
              needs.
            </p>
            <p className="landing-para">
              The app features a deep learning-powered chatbot, Pai, that
              utilizes smartphone voice recognition and artificial intelligence
              technologies to assess the psychological challenges faced by
              parents. Pai is capable of providing responses similar to those
              provided by an ACT counsellor when engaged in conversation with
              parents.
            </p>
            <p className="landing-para">
              After speaking with Pai, a tailored and convenient five or
              six-week ACT-based program will be provided for psychological
              support in the challenges parents face. The program includes
              self-help reading materials, imagery exercises, mindfulness
              practices, and metaphorical experiential exercises etc.
            </p>
            <p className="landing-para">
              The Pai.ACT app was filed for a provisional U.S. patent (No.
              63/587,850) in October 2023 and officially launched in November.
            </p>
            <p className="landing-para">
              Download Instructions:
              <br />
              Search for “Pai.ACT” on Google Play / App Store.
            </p>
            <p className="landing-para">Or click the image below:</p>
            <div className="landing-para app-download-store">
              <a
                href={GooglePlay}
                aria-label="Link to Google Play"
                target="_blank"
              >
                <img src={GooglePlayIcon} alt="Get it on Google Play" />
              </a>
              <a href={AppStore} aria-label="Link to App Store" target="_blank">
                <img src={AppStoreIcon} alt="Download on the App Store" />
              </a>
            </div>
          </>
        );
    }
  }

  // avoid change language error
  // if (langLabel) {
  //   if (language == "zh_hant") {
  //     loading = false;
  //   }
  // } else {
  //   if (language == "en") {
  //     loading = false;
  //   }
  // }
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  //[page] [section] 主頁page
  return (
    <div className="landing-wrapper">
      <div className="landing-header">
        <Header2 url="/home" />
      </div>

      <div className="landing-container landing-video-container">
        {/* video part for demo, iframe for actual use */}
        <div>
          <video autoPlay muted loop width={"inherit"} height={"inherit"}>
            <source src={demoVideo} type="video/mp4"></source>
          </video>
          {/* <iframe
            className="landing-video"
            width="1080"
            height="720"
            frameBorder={"0"}
            allowFullScreen={true}
            src={
              "https://www.youtube.com/embed/50IY83t039o?rel=0&mute=1&controls=0&autoplay=1&playlist=50IY83t039o&loop=1&enablejsapi=1&origin=http://localhost:3000/home&width=1080&height=720"
            }
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          /> */}
        </div>

        <p
          className="landing-para"
          dangerouslySetInnerHTML={{
            __html: top?.[langLabel]?.title,
          }}
        />
      </div>

      {publicity.length ? (
        <div className="landing-container landing-publicity-container">
          <h1 className="landing-title">
            {langLabel ? "最新動態" : "News and Events"}
          </h1>
          <div className="landing-publicity-content">
            <div className="landing-publicity-box-left">
              <>
                <Swiper {...customSwiperProps}>
                  {images.map((image: any, index: number) => {
                    return (
                      <SwiperSlide data-hash={`slide${index + 1}`} key={index}>
                        <img
                          key={index}
                          className="landing-publicity-box-left-img"
                          src={image}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
              {/* <img
                className="landing-publicity-box-left-img"
                src={publicityCard?.[langLabel]?.[scrollPos[0]]?.image}
              /> */}
              <div className="landing-publicity-box-left-bottom">
                {/* <button
                  className="landing-arrow-prev"
                  id="0"
                  style={{
                    border: "none",
                    background: "transparent",
                  }}
                  onClick={(e) => onLeftArrowClick(e)}
                  onMouseOver={(e) => onMouseOverPrevButton(e)}
                  onMouseOut={(e) => onMouseOutPrevButton(e)}
                  onTouchStart={(e) => onMouseOverPrevButton(e)}
                  onTouchEnd={(e) => onMouseOutPrevButton(e)}
                > */}
                <img
                  className={`landing-${index}-prev`}
                  src={prevBtnSrc[0]}
                  alt="Previous"
                  onClick={modifyindex}
                  onMouseOver={(e) => onMouseOverPrevButton(e)}
                  onMouseOut={(e) => onMouseOutPrevButton(e)}
                />
                {/* </button> */}
                <div className="landing-publicity-scroll">
                  {/* {publicityCard?.[langLabel].map((item: any, index: any) => (
                    <img
                      src={
                        index == scrollPos[0]
                          ? publicityCircle2
                          : publicityCircle
                      }
                      id={index}
                      onClick={handlePublicityClick}
                    />
                  ))} */}
                </div>
                {/* <div
                  className="landing-arrow-next"
                  id="0"
                  style={{
                    border: "none",
                    background: "transparent",
                  }}
                  onClick={(e) => onRightArrowClick(e)}
                  onMouseOver={(e) => onMouseOverBackButton(e)}
                  onMouseOut={(e) => onMouseOutBackButton(e)}
                  onTouchStart={(e) => onMouseOverBackButton(e)}
                  onTouchEnd={(e) => onMouseOutBackButton(e)}
                > */}
                <img
                  className={`landing-${index}-next`}
                  src={backBtnSrc[0]}
                  alt="Next"
                  onClick={modifyindex}
                  onMouseOver={(e) => onMouseOverBackButton(e)}
                  onMouseOut={(e) => onMouseOutBackButton(e)}
                />
              </div>
            </div>
            <div className="landing-publicity-box-right">
              <>
                <Swiper {...customSwiperProps}>
                  {publicityCard?.[langLabel]?.map(
                    (element: any, index: number) => {
                      return (
                        <SwiperSlide
                          key={index}
                          data-hash={`slide${index + 1}`}
                        >
                          <h2 className="landing-title-little">
                            {element.title}
                          </h2>
                          <div
                            className="landing-para-little landing-publicity-box-right-para"
                            dangerouslySetInnerHTML={{
                              __html: element?.content,
                            }}
                          />
                          {/* to={`/training/${slugify(trainingCard[0][index].title, { lower: true }) + "-" + trainingCard[0][index].link}`} */}
                          <a
                            href={
                              lanString +
                              "/news-and-events/" +
                              slugify(publicityCard[0][index].title, {
                                lower: true,
                              }) +
                              "-" +
                              element.link
                            }
                            target="_blank"
                          >
                            <p className="landing-para-little">
                              {langLabel ? "查看更多" : "Read More"}
                            </p>
                          </a>
                          {element.date && <div className="landing-smart-box">
                            <img src={calendar} />
                            <p className="landing-para-little">
                              {element.date}
                            </p>
                          </div>}
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </>
              {/* <h1 className="landing-title-little">
                {publicityCard?.[langLabel]?.[scrollPos[0]]?.title}
              </h1>
              <div
                className="landing-para-little landing-publicity-box-right-para"
                dangerouslySetInnerHTML={{
                  __html: publicityCard?.[langLabel]?.[scrollPos[0]]?.content.replace(/<[^>]*>/g, ""),
                }}
              />
                to={`/training/${slugify(trainingCard[0][index].title, { lower: true }) + "-" + trainingCard[0][index].link}`}
              <a
                href={ lanString + 
                  "/publicity/" + slugify(publicityCard[0][scrollPos[0]]?.title, { lower: true })
                  + "-" + publicityCard[0][scrollPos[0]]?.link
                }
                target="_blank"
              >
                <p className="landing-para-little">
                  {langLabel ? "查看更多" : "Read more"}
                </p>
              </a>
              <div>
                <img src={calendar} />
                <p className="landing-para-little">
                  {publicityCard?.[langLabel]?.[scrollPos[0]]?.date}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {training.length ? (
        <div className="landing-container landing-training-container">
          <h1 className="landing-title">{training?.[langLabel]?.title}</h1>
          {/* {training?.[langLabel]?.title} */}
          <div className="landing-training-content">
            <img className="landing-training-img" src={trainingBg} />
            <div className="landing-training-box-container">
              <div className="landing-training-box">
                {trainingCard[langLabel].map((e: any, index: number) => (
                  <div className="landing-training-box-content" key={index}>
                    <div className="landing-training-box-content-left">
                      <img
                        className="landing-training-box-img"
                        src={timelineCircle}
                      />
                    </div>
                    <div className="landing-training-box-content-right">
                      <p>{e.date}</p>

                      <a
                        href={
                          lanString +
                          `/professional-training/${slugify(trainingCard[0][index].title, {
                            lower: true,
                          }) +
                          "-" +
                          trainingCard[0][index].link
                          }`
                        }
                        target="_blank"
                      >
                        <div>{e?.title}</div>
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: e?.title?.replace(/<[^>]*>/g, ""),
                          }}
                        ></div> */}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {research.length ? (
        <div className="landing-container landing-research-container">
          <h1
            className="landing-title"
          // dangerouslySetInnerHTML={{
          //   __html: research?.[langLabel]?.title,
          // }}
          >
            {langLabel ? "我們的研究" : "Our Research"}
          </h1>
          {researchCard?.[langLabel].length ? (
            <div className="landing-research-containcontent">
              <button
                className="landing-arrow landing"
                id="1"
                onClick={(e) => onRightArrowClick(e)}
              // onMouseOver={(e) => onMouseOverPrevButton(e)}
              // onMouseOut={(e) => onMouseOutPrevButton(e)}
              // onTouchStart={(e) => onMouseOverPrevButton(e)}
              // onTouchEnd={(e) => onMouseOutPrevButton(e)}
              >
                <img
                  className="landing-arrow-image"
                  src={prevBtnSrc[0]}
                  alt="Previous"
                />
              </button>
              <div className="landing-research-content">
                {scrollPos[1].map((i: number, index: number) => {
                  if (researchCard?.[langLabel]?.[i] != undefined) {
                    return (
                      <div
                        className={`landing-research-card ${isResearchCardUpdated ? "fadeIn" : ""
                          }`}
                        key={index}
                      >
                        <img src={researchCard?.[langLabel]?.[i]?.image} />
                        <h1 className="landing-para-little">
                          {researchCard?.[langLabel]?.[i]?.title}
                        </h1>
                        <p>{researchCard?.[langLabel]?.[i]?.date}</p>
                        <a
                          href={
                            lanString +
                            "/research/" +
                            slugify(researchCard[0][i]?.title, {
                              lower: true,
                            }) +
                            "-" +
                            researchCard[0][i]?.link
                          }
                          target="_blank"
                        >
                          <button className="landing-research-button">
                            {langLabel ? "閱讀更多" : "Read More"}
                          </button>
                        </a>
                      </div>
                    );
                  }
                  return <></>;
                })}
              </div>
              <button
                className="landing-arrow"
                id="1"
                onClick={(e) => onLeftArrowClick(e)}
              // onMouseOver={(e) => onMouseOverBackButton(e)}
              // onMouseOut={(e) => onMouseOutBackButton(e)}
              // onTouchStart={(e) => onMouseOverBackButton(e)}
              // onTouchEnd={(e) => onMouseOutBackButton(e)}
              >
                <img
                  className="landing-arrow-image"
                  src={backBtnSrc[0]}
                  alt="Previous"
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {orderCard.length ? (
        <div className="landing-container landing-order-container">
          <h1 className="landing-title">{langLabel ? "前言" : "Foreword"}</h1>
          {orderCard?.[langLabel].length ? (
            <div className="landing-order-content">
              {scrollPos[2]?.length > 2 ? (
                <>
                  <button
                    className="landing-arrow"
                    id="2"
                    onClick={(e) => onRightArrowClick(e)}
                    onMouseOver={(e) => onMouseOverPrevButton(e)}
                    onMouseOut={(e) => onMouseOutPrevButton(e)}
                  // onTouchStart={(e) => onMouseOverPrevButton(e)}
                  // onTouchEnd={(e) => onMouseOutPrevButton(e)}
                  >
                    <img src={prevBtnSrc[0]} alt="Previous" />
                  </button>

                  {scrollPos[2].map((e: number, i: number) => (
                    <div
                      className={
                        "landing-order-card " +
                        (i == 1
                          ? "landing-order-card-large "
                          : "landing-order-card-small ") +
                        i
                      }
                      key={i}
                    >
                      <img
                        className="landing-order-img landing-order-img-small"
                        src={orderCard[langLabel][scrollPos[2][i]]?.icon}
                      />
                      <h1 className="landing-title-little">
                        {orderCard[langLabel][scrollPos[2][i]]?.title}
                      </h1>
                      <p
                        className={
                          i == 1 ? "landing-para " : "landing-little-para "
                        }
                      >
                        {orderCard[langLabel][scrollPos[2][i]]?.name}
                      </p>
                      <img src={leftQuote} />
                      <div
                        className={
                          i == 1 ? "landing-para" : "landing-para-little"
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            orderCard[langLabel][scrollPos[2][i]]?.preface,
                        }}
                      ></div>
                      <img src={rightQuote} />
                      <img src={orderCard[langLabel][scrollPos[2][i]]?.image} />
                    </div>
                  ))}
                  <button
                    className="landing-arrow"
                    id="2"
                    onClick={(e) => onLeftArrowClick(e)}
                    onMouseOver={(e) => onMouseOverBackButton(e)}
                    onMouseOut={(e) => onMouseOutBackButton(e)}
                  // onTouchStart={(e) => onMouseOverBackButton(e)}
                  // onTouchEnd={(e) => onMouseOutBackButton(e)}
                  >
                    <img src={backBtnSrc[0]} alt="Previous" />
                  </button>
                </>
              ) : (
                <>
                  <div className="landing-arrow"></div>
                  <div className="landing-order-card-small"></div>
                  <div
                    className={"landing-order-card landing-order-card-large"}
                  >
                    <img
                      className="landing-order-img landing-order-img-small"
                      src={orderCard?.[langLabel]?.[scrollPos[2]?.[0]]?.icon}
                    />
                    <h1 className="landing-title-little">
                      {orderCard?.[langLabel]?.[scrollPos[2]?.[0]]?.title}
                    </h1>
                    <p className="landing-para">
                      {orderCard?.[langLabel]?.[scrollPos[2]?.[0]]?.name}
                    </p>
                    <img src={leftQuote} />
                    <div
                      className={"landing-para"}
                      dangerouslySetInnerHTML={{
                        __html:
                          orderCard?.[langLabel]?.[scrollPos[2]?.[0]]?.preface,
                      }}
                    ></div>
                    <img src={rightQuote} />
                    <img
                      src={orderCard?.[langLabel]?.[scrollPos[2]?.[0]]?.image}
                    />
                  </div>
                  <div className="landing-order-card-small"></div>
                  <div className="landing-arrow"></div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="landing-container landing-app-container">
        <div className="landing-app-content">
          <img src={researchPhone} />
          <div className="landing-app-box">
            {/* <h1 className="landing-title landing-box">Pai.ACT</h1> */}
            {getAppIntroTitle(language)}
            {getAppIntroDescription(language)}
            {/* <p className="landing-para">
              Pai.ACT is the first artificial intelligence-driven,
              chatbot-assisted mental health mobile application possessed the
              domain knowledge in Acceptance and Commitment Therapy to provide
              personalised mental health services specially for Chinese-speaking
              parents of children with special needs in Hong Kong.
            </p>
            <p className="landing-para" style={{ fontSize: "14px" }}>
              <em>Note.</em>
              &nbsp; Pai.ACT has been officially trademarked, and an application
              for patent has been submitted for this invention. (Patent no: No. 63/587, 850)
            </p> */}
          </div>
        </div>
      </div>
      <div className="contactUsContainer-position" id="contactUsContainer-position" ></div>
      <div
        className="landing-container landing-bottom-container contactUsContainer"
        id="contactUsContainer"
        ref={containerRef}
      >
        {/* <a id="contactUsContainer" className="contactUsContainer"></a> */}
        <img className="landing-bottom-img" src={contactUs} />
        <div className="landing-bottom-box">
          <h1 className="landing-title-little landing-bottom-tittle">
            {langLabel ? "聯繫我們" : "Contact Us"}
          </h1>
          <img src={phone} />
          <a
            href={`tel:${contact?.[langLabel]?.phone}`}
            className="landing-para-little-box"
          >
            {contact?.[langLabel]?.phone}
          </a>
          <img src={mail} />
          <a
            href={`mailto:${contact?.[langLabel]?.email}`}
            className="landing-para-little-box"
          >
            {contact?.[langLabel]?.email}
          </a>
          <img src={address} />
          <p className="landing-para-little-box">
            {contact?.[langLabel]?.address}
          </p>
        </div>
      </div>

      <div className="landing-footer">
        <Footer />
      </div>
    </div>
  );
}
