import { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import "./GeneralPage.css";
import { BeatLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";


export interface GeneralPageDetail {
  content1: string[];
  content2: string[];
  title: string[];
  url: string;
}

function GeneralPage({
  generalPageDetail,
}: {
  generalPageDetail: GeneralPageDetail;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const language = useSelector((state: RootState) => state.language.language);
  const [loadingText, setLoadingText] = useState("Loading");
  const [languageLabel, setLanguageLabel] = useState(1 as number);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }

    if (language === "zh_hant") {
      setLoadingText("加載中");
      setLanguageLabel(0);
      document.title = generalPageDetail.title[0];
    } else if (language === "en") {
      setLanguageLabel(1);
      setLoadingText("Loading");
      document.title = generalPageDetail.title[1];
    }
  }, [language]);
  //Loading UI Control
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500);
  // });
  // if (isLoading) {
  //   return (
  //     <Loading loadingText={loadingText} />
  //   );
  // }
  return (
    <>
      <div className="general-header">
        <Header2 url={generalPageDetail.url}></Header2>
      </div>

      <div className="generalPageContainer">
        <div className="generalPageTitleContainer">
          {generalPageDetail.title.length !== 0 && (
            <h1 className="generalPageTitleInsidegeneralPageTitleContainer">
              {generalPageDetail.title[languageLabel]}
            </h1>
          )}
        </div>

        <div className="generalPageContentContainer">
          {generalPageDetail.content1.length !== 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: generalPageDetail.content1[languageLabel],
              }}
            />
          )}
          {generalPageDetail.content2.length !== 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: generalPageDetail.content2[languageLabel],
              }}
            />
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default GeneralPage;
