import React, { useEffect, useState } from "react";
import "./Team.css";
import Footer from "../Footer/Footer";
import SectionBar from "../SectionBar/SectionBar";
import backArrow from "./arrow_forward_24px-1.webp";
import forwardArrow from "./arrow_forward_24px.webp";
import hoverBackArrow from "./Group 2351.webp";
import hoverForwardArrow from "./Group 2352.webp";
import email from "./email_24px.webp";
import phone from "./call_24px.webp";
import home from "./home_24px.webp";
import Header2 from "../Header2/Header2";
import axios from "axios";
import config from "../config";
import defaultIcon from "./icon.jpeg";
import API from "../helper/ApiHelper";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import rect2 from "./rect2.webp";
import GreyLeft from "./grey_left.webp";
import GreyRight from "./grey_right.webp";
import { setEnglish, setChinese } from "../languageSlice";
import twitter from "../Header2/x_black.png";
import linkedin from "./linkedin.png";
import Loading from "../Loading/Loading";

// for page bottom award
type AwardInfo = {
  id: string;
  engID: string;
  yearID: string;
  contextID: string;
  year: string;
  context: string;
  contextEng: string;
  yearEng: string;
  contextEngID: string;
  yearEngID: string;
  position: string;
};

const Team = () => {
  // this id should be a unique id TODO to fix
  const id = "Team Head";

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  // temp language button
  const language = useSelector((state: RootState) => state.language.language);
  const [session, setSession] = useState() as any;
  const [awardList, setAwardList] = useState([] as any);
  const [contentList, setContentList] = useState() as any;
  const [teamIcon, setTeamIcon] = useState("");
  const [personalInfo, setPersonalInfo] = useState() as any;
  const [awardTitle, setAwardTitle] = useState() as any;
  const [firstTitle, setFirstTitle] = useState() as any;
  const [secondTitle, setSecondTitle] = useState() as any;
  const [firstContext, setFirstContext] = useState() as any;
  const [secondContext, setSecondContext] = useState() as any;
  const [titleContextList, setTitleContextList] = useState() as any;
  const [loadingText, setLoadingText] = useState("Loading");
  // get Backend data with axios
  async function getData() {
    const request = `{childMenu(where: $where){
      id,
      title
      childContent {
          title,
          session,
          template,
          images {
              url,
              priority,
              id,
              category
          }
          id
          contents(where: $contentsWhere2) {
              category,
              content,
              id,
              priority,
              position,
              isDeleted,
              langLabel{
                id
                lang
                index
              }
          }
          sessionImage
      }
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: id },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log("res.data.data.childMenu:", res.data.data.childMenu);
        let firstGroupArr = [] as any;
        // let secondGroupArr = [] as any;
        let awardArr: [AwardInfo] = [] as any;
        let contentArr = [] as any;
        let personalInfoArr = [] as any;
        let awardTitleArr = [] as any;
        // console.log(res.data.data.childMenu.childContent[0].contents);
        if (res.data.data.childMenu.childContent[0]) {
          res.data.data.childMenu.childContent[0].contents.forEach(
            (e: any, idx: number) => {
              if (e.position.includes("ticAward")) {
                if (e.langLabel[0]?.lang === "zh_hant") {
                  awardArr.push({
                    id: e.id,
                    engID: "",
                    yearID: `${e.content.split("!@#$%^&*()_+")[1]}+${idx + 1}`,
                    contextID: `existItem+${idx + 1}`,
                    context: e.content.split("!@#$%^&*()_+")[0],
                    year: e.content.split("!@#$%^&*()_+")[1],
                    contextEng: "",
                    yearEng: "",
                    contextEngID: "",
                    yearEngID: "",
                    position: e.position,
                  });
                } else if (e.langLabel[0]?.lang === "en") {
                  awardArr.forEach((element: any, index: number) => {
                    if (element.position === e.position) {
                      awardArr[index]["engID"] = e.id;
                      awardArr[index]["yearEngID"] = `ENGyear${
                        e.content.split("!@#$%^&*()_+")[1]
                      }+${idx + 1}`;
                      awardArr[index]["contextEngID"] = `ENGcontent${
                        e.content.split("!@#$%^&*()_+")[0]
                      }+${idx + 1}`;
                      awardArr[index]["contextEng"] =
                        e.content.split("!@#$%^&*()_+")[0];
                      awardArr[index]["yearEng"] =
                        e.content.split("!@#$%^&*()_+")[1];
                    }
                  });
                }
              } else if (
                e.position === "TICEmail" ||
                e.position === "TICPhone" ||
                e.position === "TICAddress" ||
                e.position === "TICLink" ||
                e.position === "TICTwitter" ||
                e.position === "TICLinkedIn"
              ) {
                personalInfoArr.push(e);
              } else if (e.position === "AwardTitle") {
                awardTitleArr.push(e);
              } else if (e.position === "1stContext") {
                // setFirstContext(e.content);
                firstGroupArr.push(e);
              } else if (e.position === "1stTitle") {
                // setFirstTitle(e.content);
                firstGroupArr.push(e);
              } else if (e.position === "2ndContext") {
                // setSecondContext(e.content);
                firstGroupArr.push(e);
              } else if (e.position === "2ndTitle") {
                // setSecondTitle(e.content);
                firstGroupArr.push(e);
              } else {
                contentArr.push(e);
              }
              setPersonalInfo(personalInfoArr);
              setAwardList(awardArr);
              setTeamIcon(
                res.data.data.childMenu.childContent[0].images[0].url
              );
              contentArr.sort((a: any, b: any) => {
                const aNum = parseInt(a.position.match(/\d+/)[0]);
                const bNum = parseInt(b.position.match(/\d+/)[0]);

                if (aNum < bNum) return -1;
                if (aNum > bNum) return 1;

                if (
                  a.position.includes("Title") &&
                  b.position.includes("Context")
                )
                  return -1;
                if (
                  a.position.includes("Context") &&
                  b.position.includes("Title")
                )
                  return 1;

                return 0;
              });
              setContentList(contentArr);
              setAwardTitle(awardTitleArr);
              setTitleContextList(firstGroupArr);
            }
          );
        }
      })
      .catch((err: Error) => console.error("err", err));
  }

  //console.log("titleContextList:", titleContextList);

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [arrowSrc, setArrowSrc] = useState({
    back: backArrow,
    forward: forwardArrow,
  });

  const handlePrevClick = () => {
    setActiveCardIndex((prevIndex) =>
      prevIndex === 0 ? awardList.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setActiveCardIndex((prevIndex) =>
      prevIndex === awardList.length - 1 ? 0 : prevIndex + 1
    );
  };
  const activeCard = awardList[activeCardIndex];
  // console.log(activeCard)

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mobile scroll header control
  // const isPhone = window.matchMedia("(max-width: 768px)").matches;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const phone = window.matchMedia("(max-width: 768px)").matches;
  //     let contenty: number = 159.19;
  //     let content = document.querySelector(
  //       ".team-head-content-section"
  //     ) as HTMLElement;
  //     const currentScrollPos = window.pageYOffset;
  //     const teamHeadContentContainers = document.getElementsByClassName(
  //       "team-head-container"
  //     );

  //     if (phone) {
  //       if (content)
  //         contenty = window.scrollY + content.getBoundingClientRect().top; // Y
  //       for (let i = 0; i < teamHeadContentContainers.length; i++) {
  //         const teamHeadContainer = teamHeadContentContainers[i] as HTMLElement;
  //         if (currentScrollPos >= contenty && teamHeadContainer) {
  //           teamHeadContainer.style.marginTop = "17rem";
  //         } else if (teamHeadContainer) {
  //           teamHeadContainer.style.marginTop = "2rem";
  //         }
  //       }
  //     } else {
  //       for (let i = 0; i < teamHeadContentContainers.length; i++) {
  //         const teamHeadContainer = teamHeadContentContainers[i] as HTMLElement;
  //         teamHeadContainer.style.marginTop = "-22rem";
  //       }
  //     }
  //   };

  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Trigger the scroll event on page load
  //   handleScroll();

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isPhone, []]);

  useEffect(() => {
    if (titleContextList)
      titleContextList.forEach((e: any, key: number) => {
        // console.log(e);
        if (e.langLabel[0]?.lang === language) {
          switch (e.position) {
            case "1stContext":
              setFirstContext(e.content);
              break;
            case "1stTitle":
              setFirstTitle(e.content);
              break;
            case "2ndContext":
              setSecondContext(e.content);
              break;
            case "2ndTitle":
              setSecondTitle(e.content);
              break;
          }
        }
      });
  }, [language, []]);

  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    setIsTouchDevice(
      "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.maxTouchPoints > 0
    );
  }, []);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "團隊負責人 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Team lead - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (contentList?.length && personalInfo.length && awardTitle.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [contentList, awardTitle, personalInfo]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }

  return (
    <div className="wrapper">
      <div className="landing-header">
        <Header2 url="/about-us/team-lead" />
      </div>
      {/* <button onClick={() => console.log(contentList)}>contentList</button> */}
      <div className="team-head-content-section">
        <SectionBar
        // sections={[
        //   { name: "Meet Us", url: "/meetus" },
        //   { name: "Team In Charge", url: "/meetus/teamhead" },
        //   { name: "About Collaborator", url: "/meetus/collaborators" },
        //   {
        //     name: "Our Counsellor & Team Member",
        //     url: "/meetus/counsellors",
        //   },
        // ]}
        />

        <div className="team-head-container">
          <img
            className="team-head-icon"
            src={`${API.getImage}${teamIcon}`}
            alt="icon"
          />
          <div
            className="team-head-subtitle"
            dangerouslySetInnerHTML={{ __html: firstTitle }}
            style={{ height: "35px" }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: firstContext }}></div>
          {/* <div style={{ height: "15px" }}></div> */}
          <div
            dangerouslySetInnerHTML={{ __html: secondTitle }}
            style={{ height: "40px" }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: secondContext }}></div>
          {contentList &&
            contentList.map((e: any, idx: number) => {
              return (
                <div key={idx} className="teams_content_manage">
                  {language === "zh_hant" &&
                    e.langLabel[0]?.lang === language && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "en" && e.langLabel[0]?.lang === language && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                </div>
              );
            })}
          <div className="info-card">
            <div>
              <img
                className="info-icon"
                src={email}
                alt="icon"
                style={{ marginRight: "1rem" }}
              />
              {personalInfo &&
                personalInfo.map((e: any, idx: number) => {
                  return (
                    <div key={idx}>
                      {e.position === "TICEmail" && (
                        <a
                          className="team-head-more-info"
                          href={`mailto:${e.content}`}
                          target="_blank"
                        >
                          {e.content}
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
            <div>
              <img
                className="info-icon"
                src={phone}
                alt="icon"
                style={{ marginRight: "1rem" }}
              />
              {personalInfo &&
                personalInfo.map((e: any, idx: number) => {
                  return (
                    <div key={idx}>
                      {e.position === "TICPhone" && (
                        <a
                          className="team-head-more-info"
                          href={`tel:${e.content}`}
                        >
                          {e.content}
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
            <div>
              <img
                className="info-icon"
                src={home}
                alt="icon"
                style={{ marginRight: "1rem" }}
              />
              {personalInfo &&
                personalInfo.map((e: any, idx: number) => {
                  return (
                    <div key={idx} className="info-card-address">
                      {e.position === "TICAddress" &&
                        e.langLabel[0]?.lang === language && (
                          <span>{e.content}</span>
                        )}
                    </div>
                  );
                })}
              {}
            </div>
            <div>
              <img
                className="info-icon"
                src={twitter}
                alt="icon"
                style={{ marginRight: "1rem" }}
              />
              {personalInfo &&
                personalInfo.map((e: any, idx: number) => {
                  return (
                    <div key={idx}>
                      {e.position === "TICTwitter" && (
                        <a
                          className="team-head-more-info"
                          href={`${e.content}`}
                          target="_blank"
                        >
                          Twitter
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
            <div>
              <img
                className="info-icon"
                src={linkedin}
                alt="icon"
                style={{ marginRight: "1rem" }}
              />
              {personalInfo &&
                personalInfo.map((e: any, idx: number) => {
                  return (
                    <div key={idx}>
                      {e.position === "TICLinkedIn" && (
                        <a
                          className="team-head-more-info"
                          href={`${e.content}`}
                          target="_blank"
                        >
                          LinkedIn
                        </a>
                      )}
                    </div>
                  );
                })}
            </div>
            {personalInfo &&
              personalInfo.map((e: any, idx: number) => {
                return (
                  <div key={idx}>
                    {e.position === "TICLink" && (
                      <a
                        className="team-head-more-info"
                        href={e.content}
                        target="_blank"
                      >
                        {language === "en" ? "More Information" : "更多資料"}
                      </a>
                    )}
                  </div>
                );
              })}
          </div>
          {awardTitle &&
            awardTitle.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {e.langLabel[0]?.lang === language && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                </div>
              );
            })}
          <div className="active-card">
            <div className="active-card-content">
              {language === "zh_hant" && activeCard != undefined && (
                <>
                  <p style={{ whiteSpace: "pre-wrap", marginBottom: "10px" }}>
                    {activeCard.context}
                  </p>
                  <p style={{ textAlign: "right", margin: 0 }}>
                    {activeCard.year}
                  </p>
                </>
              )}
              {language === "en" && activeCard != undefined && (
                <>
                  <p style={{ whiteSpace: "pre-wrap", marginBottom: "10px" }}>
                    {activeCard.contextEng}
                  </p>
                  <p style={{ textAlign: "right", margin: 0 }}>
                    {activeCard.yearEng}
                  </p>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "1rem 0",
            }}
          >
            {isTouchDevice ? (
              <button
                className="arrow-button"
                onClick={handlePrevClick}
                disabled={activeCardIndex === 0}
              >
                {activeCardIndex === 0 ? (
                  <img className="arrow" src={GreyLeft} alt=""></img>
                ) : (
                  <img className="arrow" src={arrowSrc.back} alt=""></img>
                )}
              </button>
            ) : (
              <button
                className="arrow-button"
                onClick={handlePrevClick}
                onMouseOver={() =>
                  setArrowSrc({ back: hoverBackArrow, forward: forwardArrow })
                }
                onMouseLeave={() =>
                  setArrowSrc({ back: backArrow, forward: forwardArrow })
                }
                disabled={activeCardIndex === 0}
              >
                {activeCardIndex === 0 ? (
                  <img className="arrow" src={GreyLeft} alt=""></img>
                ) : (
                  <img className="arrow" src={arrowSrc.back} alt=""></img>
                )}
              </button>
            )}
            {/* <img
              className="arrow"
              src={arrowSrc.back}
              onClick={handlePrevClick}
              onMouseOver={() =>
                setArrowSrc({ back: hoverBackArrow, forward: forwardArrow })
              }
              onMouseLeave={() =>
                setArrowSrc({ back: backArrow, forward: forwardArrow })
              }
              alt=""
            /> */}
            <div className="slide-position-display-container">
              <div className="slide-position-display">
                {activeCardIndex + 1}/{awardList.length}
              </div>
              {/* <img src={rect2} alt="" /> */}
            </div>
            &nbsp;
            {isTouchDevice ? (
              <button
                className="arrow-button"
                onClick={handleNextClick}
                disabled={activeCardIndex === awardList.length - 1}
              >
                {activeCardIndex === awardList.length - 1 ? (
                  <img className="arrow" src={GreyRight} alt=""></img>
                ) : (
                  <img className="arrow" src={arrowSrc.forward} alt=""></img>
                )}
              </button>
            ) : (
              <button
                className="arrow-button"
                onClick={handleNextClick}
                disabled={activeCardIndex === awardList.length - 1}
                onMouseOver={() =>
                  setArrowSrc({ back: backArrow, forward: hoverForwardArrow })
                }
                onMouseLeave={() =>
                  setArrowSrc({ back: backArrow, forward: forwardArrow })
                }
              >
                {activeCardIndex === awardList.length - 1 ? (
                  <img className="arrow" src={GreyRight} alt=""></img>
                ) : (
                  <img className="arrow" src={arrowSrc.forward} alt=""></img>
                )}
              </button>
            )}
            {/* <img
              className="arrow"
              src={arrowSrc.forward}
              onClick={handleNextClick}
              onMouseOver={() =>
                setArrowSrc({ back: backArrow, forward: hoverForwardArrow })
              }
              onMouseLeave={() =>
                setArrowSrc({ back: backArrow, forward: forwardArrow })
              }
              alt=""
            /> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Team;
