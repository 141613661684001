import React, { useState, useEffect } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux/es/hooks/useSelector";
import axios from "axios";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import "./ForgetPassword.css";
import API from "../helper/ApiHelper";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";

function ForgetPassword() {
  const [email, setEmail] = useState("" as string);
  const [emailIsValid, setEmailIsValid] = useState(false as boolean);
  const [submitting, setSubmitting] = useState(false as boolean);
  const [langLabel, setLangLabel] = useState(0 as number);
  const language = useSelector((state: RootState) => state.language.language);
  const [lanString, setLanString] = useState([] as any);

  const [sessionDate, setSessionDate] = useState([
    {
      title: "Forget Password?",
      paragraph1:
        "Enter your email, an email will sent to you to reset your password.",
      fieldTitle1: "Email",
      errorAlert1: "Please enter a valid email.",
      button1: "Confirm",
      button2: "Back",
      paragraph2:
        "If the email address you provided matches our records, we've sent an email to that address with instructions to reset your password.", //"An email is sent to you to reset the password.",
      paragraph3:
        "Please check your email inbox, as well as your spam or junk folder if you don't see the email in your inbox.", //"Please check your email.",
      button3: "OK",
    },
    {
      title: "忘記密碼?",
      paragraph1:
        "輸入您的電子郵件，系統將向您發送一封電子郵件以重置您的密碼。",
      fieldTitle1: "電郵地址",
      errorAlert1: "請輸入有效的電郵地址",
      button1: "確認",
      button2: "返回",
      paragraph2:
        "如果您提供的電子郵件地址與我們的記錄相符，我們會向該地址發送電子郵件，其中包含重設您密碼的說明。", //"重置密碼電子郵件以發送。",
      paragraph3:
        "如果您在收件匣中沒有看到該電子郵件，請檢查您的電子郵件收件匣以及垃圾郵件資料夾。", //"請查看你的郵箱。",
      button3: "好",
    },
  ] as any[]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // valid email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailIsValid(emailRegex.test(event.target.value));
    setEmail(event.target.value);
  };
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  // open dialog
  const handleContinue = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const dialog = document.getElementById(
      "forget-dialog"
    ) as HTMLDialogElement | null;
    setSubmitting(true);
    if (emailIsValid) {
      try {
        const res = await axios.post(API.forgetPw, {
          email: email,
        });
        // console.log(res);
      } catch (err) { }

      if (dialog) {
        dialog.showModal();
        document.body.classList.add("dialog-open");
      }
      // empty input
      setEmail("");
    }
  };

  // close dialog
  const handleClose = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const dialog = document.getElementById(
      "forget-dialog"
    ) as HTMLDialogElement | null;
    if (dialog) {
      dialog.close();
      document.body.classList.remove("dialog-open");
    }
    setSubmitting(false);
  };

  // change language label
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLangLabel(1);
      setLanString("/ch");
      document.title = "忘記密碼? - ACTuwise";
    } else {
      setLangLabel(0);
      setLanString("/en");
      document.title = "Forget Password? - ACTuwise";
    }
  }, [language]);

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="forget-wrapper">
      <div className="general-header">
        <Header2 url="/forget-password" />
      </div>

      <div className="forget-container">
        <div className="forget-bg">
          <div className="forget-box">
            <h1 className="forget-box-title">
              {sessionDate?.[langLabel]?.title}
            </h1>
            <p className="forget-box-para">
              {sessionDate?.[langLabel]?.paragraph1}
            </p>
            <p className="forget-box-input-text">
              {sessionDate?.[langLabel]?.fieldTitle1}
            </p>
            <input
              className="forget-box-input"
              type="email"
              onChange={handleEmailChange}
              value={email}
              required
            />
            <p
              className="forget-box-validation-alert"
              style={{
                visibility: !emailIsValid && submitting ? "visible" : "hidden",
              }}
            >
              {sessionDate?.[langLabel]?.errorAlert1}
            </p>
            <div className="forget-box-button-container">
              <button
                className="forget-box-button forget-box-continue-button"
                onClick={handleContinue}
              >
                {sessionDate?.[langLabel]?.button1 + " "}
              </button>
              <a href={lanString + "/login"}>
                <button className="forget-box-button forget-box-back-button">
                  {sessionDate?.[langLabel]?.button2}
                </button>
              </a>
            </div>
          </div>
        </div>
        <dialog className="forget-dialog" id="forget-dialog">
          <div className="forget-dialog-bg">
            <div className="forget-dialog-box">
              <p className="forget-dialog-para">
                {sessionDate?.[langLabel]?.paragraph2}
              </p>
              <p className="forget-dialog-para">
                {sessionDate?.[langLabel]?.paragraph3}
              </p>
              <button className="forget-dialog-button" onClick={handleClose}>
                {sessionDate?.[langLabel]?.button3}
              </button>
            </div>
          </div>
        </dialog>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ForgetPassword;
