import axios from "axios";
import config from "../config";
import { GeneralPageDetail } from "../GeneralPage/GeneralPage";
import { FooterLink } from "./Footer";



export interface footerPageDetail {
  content1: string[];
  content2: string[];
  title: string[];
  url: string;
}


const headers = {
  "Content-Type": "application/json",
  // authorization: `Bearer ${token}`,
};
const linkDetailsSet = new Set<GeneralPageDetail>();
const footerSet = new Set<FooterLink>();
let isFetched = false;
let fetchDataPromise: Promise<[FooterLink[], GeneralPageDetail[]]> | null =
  null;

export function fetchFooterData(
  callback: (footer: FooterLink[], linkDetails: GeneralPageDetail[]) => void
) {
  if (isFetched) {
    const footer = Array.from(footerSet);
    const linkDetails = Array.from(linkDetailsSet);
    callback(footer, linkDetails);
    return;
  }

  if (fetchDataPromise) {
    fetchDataPromise.then(([footer, linkDetails]) => {
      callback(footer, linkDetails);
    });
    return;
  }

  fetchDataPromise = new Promise((resolve, reject) => {
    const graphqlQuery = {
      operationName: "MenuBar",
      query: `query MenuBar($where: MenuBarWhereUniqueInput!, $orderBy: [ChildMenuOrderByInput!]!, $parentContentOrderBy2: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $childContentOrderBy2: [PageContentOrderByInput!]!, $contentsOrderBy3: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!) {
          menuBar(where: $where) {
            url
            title
            displayNameEng
            displayName
            child(orderBy: $orderBy) {
              url
              title
              priority
              id
              displayNameEng
              displayName
              childContent(orderBy: $childContentOrderBy2) {
                contents(orderBy: $contentsOrderBy3,where: $contentsWhere2) {
                  type
                  title
                  priority
                  position
                  content
                  category
                  id
                  langLabel {
                    lang
                    index
                  }
                  isDeleted
                }
              }
            }
            parentContent(orderBy: $parentContentOrderBy2) {
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                title
                type
                position
                langLabel {
                  lang
                  index
                }
                isDeleted
                id
                content
                category
              }
            }
          }
        }`,
      variables: {
        where: { title: "Footer" },
        orderBy: [
          {
            priority: "asc",
          },
        ],
        parentContentOrderBy2: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        childContentOrderBy2: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy3: [
          {
            priority: "asc",
          },
        ],
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };
    axios({
      url: config.user_base_url,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log("footer res:", res);
        let counter = 0;
        let followUs: FooterLink = {
          title: [],
          path: "",
        };
        res.data.data.menuBar.parentContent.forEach((parentContent: any) => {
          parentContent?.contents.forEach((content: any) => {
            if (content?.position === "title") {
              followUs.title.push(content?.content);
            }
            if (content?.position === "facebook") {
              footerSet.add({
                title: content?.title,
                path: content?.content,
              });
            }
            if (content?.position === "instagram") {
              footerSet.add({
                title: content?.title,
                path: content?.content,
              });
            }
            if (content?.position === "twitter") {
              footerSet.add({
                title: content?.title,
                path: content?.content,
              });
            }
          });
        });
        if (Object.keys(followUs).length !== 0) footerSet.add(followUs);
        res.data.data.menuBar.child.forEach((child: any) => {
          const tmp: GeneralPageDetail = {
            content1: [],
            content2: [],
            title: [],
            url: "",
          };
          tmp.url = child?.url;
          child?.childContent.forEach((childContent: any) => {
            childContent?.contents.forEach((contents: any) => {
              switch (contents?.position) {
                case "title":
                  tmp.title.push(contents?.content);
                  break;
                case "content1":
                  tmp.content1.push(contents?.content);
                  break;
                case "content2":
                  tmp.content2.push(contents?.content);
                  break;
                default:
                  console.warn("undefined position:", contents);
                  break;
              }
            });
          });
          if (Object.keys(tmp).length !== 0) linkDetailsSet.add(tmp);
        });

        linkDetailsSet.forEach((link: GeneralPageDetail) => {
          let tmp: FooterLink = {
            title: [],
            path: "",
          };
          tmp.path = link.url;
          link.title.forEach((title: string) => {
            tmp.title.push(title);
          });
          if (Object.keys(tmp).length !== 0) footerSet.add(tmp);
        });

        // console.log(linkDetailsSet);
        // console.log(footerSet);

        // console.log(res.data.data.menuBar);
        counter++;
        if (counter === 1) {
          const footer = Array.from(footerSet);
          const linkDetails = Array.from(linkDetailsSet);
          callback(footer, linkDetails);
          isFetched = true;
          resolve([footer, linkDetails]);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}
