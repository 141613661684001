import { useEffect, useRef, useState } from "react";
import "./Researches.css";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import researcher from "./researcher.webp";
import Header2 from "../Header2/Header2";
import ResearchesCard, {
  ResearchesInfo,
} from "../ResearchesCard/ResearchesCard";
import ResearchesSub from "../ResearchesSub/ResearchesSub";
import API from "../helper/ApiHelper";
import axios from "axios";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import { any } from "prop-types";
import { validateLocaleAndSetLanguage } from "typescript";
import { BeatLoader } from "react-spinners";
import { info } from "console";
import slugify from "slugify";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import React from "react";
import Loading from "../Loading/Loading";

function Researches({ baseRoute }: { baseRoute: string }) {
  // Local variable for storing all data in Researches
  const [data, setData] = useState([] as any);
  const [cardPositionArr, setCardPositionArr] = useState([] as any);

  // Header used in axios
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  // function to retrieve data from backend and store in local
  function getData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2,where:$imagesWhere2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }

        pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
          originalFileName
          position
          priority
          title
          url
          category
          id
          isDeleted
        }
        sessionImage
      }
    }
  }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput!,$imagesWhere2:ImageDetailWhereInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "Research" },
        pdfWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    // Get backend data using Axios
    axios({
      url: API.local, // Reference in ApiHelper
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // Get image by ApiHelper
        res.data.data.menuBar.parentContent.forEach((ee: any) => {
          ee.images.forEach((e: any) => {
            e.url = `${API.getImage}${e.url}`;
          });
        });

        // Get pdf by ApiHelper
        res.data.data.menuBar.parentContent.forEach((ee: any) => {
          ee.pdf.forEach((e: any) => {
            e.url = `${API.getPDF}${e.url}`;
          });
        });

        // console.log(
        //   "res.data.data.menuBar.parentContent: ",
        //   res.data.data.menuBar.parentContent
        // );

        // // Identify Language and remove all data with diff language
        // const contents = res.data.data.menuBar.parentContent[1].contents;
        // for (let i = contents.length - 1; i >= 0; i--) {
        //   const e = contents[i];
        //   // console.log("e.priority: ", e.priority);
        //   // console.log("e.langLabel: ", e.langLabel);
        //   if (e.langLabel[0]) {
        //     // console.log("e.langLabel[0].lang: ", e.langLabel[0].lang);
        //   }
        //   if (e.langLabel[0] && e.langLabel[0].lang !== language) {
        //     // console.log("Remove: ", e.priority);
        //     // console.log("index: ", i);

        //     // console.log("Removed e: ", e);
        //     // console.log("---------------------------------");
        //     contents.splice(i, 1);
        //   }
        // }
        let positionArr = [] as any;
        res.data.data.menuBar.parentContent.forEach((e: any) => {
          for (let i = e.contents.length - 1; i >= 0; i--) {
            if (e.contents[i].position === "cardPosition") {
              positionArr.push(e.contents[i]);
              e.contents.splice(i, 1);
            }
          }
        });
        // Put backend data to local variable - data
        setCardPositionArr(positionArr);
        setData(res.data.data.menuBar.parentContent);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  // Language Control
  const language = useSelector((state: RootState) => state.language.language);
  // console.log("language: ", language);
  let languageLabel: number = 1;
  if (language === "zh_hant") {
    languageLabel = 0;
    // console.log("languageLabel: ", languageLabel);
  } else {
    languageLabel = 1;
    // console.log("languageLabel: ", languageLabel);
  }
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
      // console.log("dispatched english", currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] )
    } else if (currentUrl?.split("/")[3] === "ch") {
      dispatch(setChinese());
      // console.log("dispatched chinese", currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] )
    }
  }, [language]);
  useEffect(() => {
    getData();
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "研究 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Research - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  // Update the document title on mount and call getData to retrieve backend data

  // Header2 Collapse Control
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Array to stores all cards data, group by cards
  let infoArr: ResearchesInfo[] = [];

  const [researchItemTitle, setResearchItemTitleLang] =
    useState("Project Details");
  const [detailsTitle, setdetailsTitleLang] = useState("Details");
  const [projectNameTitle, setProjectNameTitleLang] = useState("Project Name");
  const [durationTitle, setDurationTitleLang] = useState("Duration");
  const [targetTitle, setTargetTitleLang] = useState("Target");
  const [introductionTitle, setIntroductionTitleLang] =
    useState("Introduction");
  const [partnerOrganizationTitle, setPartnerOrganizationTitleLang] = useState(
    "Partner Organization"
  );
  const [fundingTitle, setFundingTitleLang] = useState("Funding");

  const [loadingText, setLoadingText] = useState("Loading");

  useEffect(() => {
    if (language === "zh_hant") {
      setResearchItemTitleLang("研究項目");
      setdetailsTitleLang("詳情");
      setProjectNameTitleLang("計劃名稱");
      setDurationTitleLang("持續時間");
      setTargetTitleLang("對象");
      setIntroductionTitleLang("簡介");
      setPartnerOrganizationTitleLang("合作機構");
      setFundingTitleLang("基金資助");
    } else {
      setResearchItemTitleLang("Project Summary");
      setdetailsTitleLang("Details");
      setProjectNameTitleLang("Project Title");
      setDurationTitleLang("Project Period");
      setTargetTitleLang("Participants");
      setIntroductionTitleLang("Aims");
      setPartnerOrganizationTitleLang("Collaborators");
      setFundingTitleLang("Funder");
    }
  }, [language]);

  // Check if getData is succeed and all cards data are ready (not undefinded)
  if (data[1]) {
    // Local variable that stores card data group by category and position
    const contents = data[1].contents.reduce((acc: any, cur: any) => {
      const { category, position } = cur; // Point to current item by identifing unique category and position
      const cardKey = category.split("@@")[0]; // Extract the part before "@@" symbols
      let treeKey: string = category.split("_treeView_")[1]; // Extract the part after _treeView_
      if (!treeKey) treeKey = "cardCover"; // If the field is not treeView, default is cardCover
      // console.log("treeKey: ", treeKey);

      // Init the url field in contents
      if (
        cur.position == "cardTopic" &&
        cur.langLabel[0] &&
        cur.langLabel[0].lang === "en"
      ) {
        if (!acc[cardKey]) {
          acc[cardKey] = {};
        }
        acc[cardKey].url = cur.content + "-" + cur.id;
      }

      if (
        (cur.langLabel[0] && cur.langLabel[0].lang === language) ||
        !cur.langLabel[0]
      ) {
        // Initalize accumulator if null
        if (!acc[cardKey]) {
          acc[cardKey] = {};
        }
        if (!acc[cardKey][treeKey]) {
          acc[cardKey][treeKey] = {};
        }

        // Stores each current content according to their card and position, to the accumulator
        acc[cardKey][treeKey][position] = cur.content;
        // console.log("cur.content:", cur.content);
      }
      return acc;
    }, {});

    // Map image to contents
    data[1].images.reduce((acc: any, cur: any) => {
      const { category } = cur;
      const cardKey = category.split("@@")[0];

      // acc already initalized and just put the url of the reduced images to acc
      if (acc[cardKey]) {
        // console.log("cur.url:", cur.url);
        acc[cardKey].image = cur.url;
      }

      return acc;
    }, contents);

    // Map pdf to contents
    data[1].pdf.reduce((acc: any, cur: any) => {
      const { category } = cur;
      const cardKey = category.split("@@")[0];

      // acc already initalized and just put the url of the reduced images to acc
      if (acc[cardKey]) {
        // console.log("cur.url:", cur.url);
        acc[cardKey].pdf = cur.url;
      }

      return acc;
    }, contents);

    // console.log("contents:", contents)

    // Get the number of cards

    const cardKeys = Object.keys(contents);
    const numberOfCards = cardKeys.length; // Get the count of unique card keys
    // console.log("Number of cards:", numberOfCards);

    // Map the value in contents to infoArr with correct format that used to pass to other classes
    infoArr = Object.keys(contents).map((key) => {
      // to add cardPosition into each card
      let cardPosition = -1;
      if (cardPositionArr.length > 0) {
        cardPositionArr.forEach((e: any) => {
          if (e.category === key) {
            cardPosition = +e.content;
          }
        });
      }
      const value = contents[key];
      const cardCover = value.cardCover || {};

      let treeView: string[][] = [];
      let indexInner: number = 0;
      let indexOuter: number = 0;
      for (let i = 0; i < 4; i++) {
        treeView[i] = [];
      }

      // console.log("key: ", key);
      // console.log("value: ", value);

      // Iterate over each cards
      Object.entries(value).forEach(([id, item]) => {
        // console.log(`ID: ${id}`);
        //console.log("Data:", item);
        indexInner = 0;

        if (id != "image" && id != "cardCover" && id != "pdf" && id != "url") {
          // Iterate over the properties of the treeView
          Object.entries(item as Record<string, string>).forEach(
            ([key, data]) => {
              // console.log(`data: ${data}`);
              treeView[indexOuter][indexInner] = data || "";

              // Debug msg to determine index
              // console.log(`Property: ${key}`);
              // console.log(
              //   "treeView[indexOuter][indexInner]: ",
              //   treeView[indexOuter][indexInner]
              // );
              // console.log("indexOuter: ", indexOuter);
              // console.log("indexInner: ", indexInner);

              indexInner++;
            }
          );
          indexOuter++;
        }
      });

      // console.log("cardCover: ", cardCover);
      return {
        info: {
          budget: cardCover.budget || "",
          cardTopic: cardCover.cardTopic || "",
          durationCover: cardCover.duration || "",
          sponsor: cardCover.sponsor || "",
          // treeView[0] --> Background section
          projectName: treeView[0][0] || "",
          duration: treeView[0][1] || "",
          target: treeView[0][2] || "",
          introduction: treeView[0][3] || "",
          partnerOrganization: treeView[0][4] || "",
          funding: treeView[0][5] || "",
          // treeView[1] --> Detail section
          mainTitleTree2: treeView[1][0] || "",
          title1: treeView[1][1] || "",
          content1: treeView[1][2] || "",
          title2: treeView[1][3] || "",
          content2: treeView[1][4] || "",
          title3: treeView[1][5] || "",
          content3: treeView[1][6] || "",
          title4: treeView[1][7] || "",
          content4: treeView[1][8] || "",
          title5: treeView[1][9] || "",
          content5: treeView[1][10] || "",
          // treeView[2] --> Additional Session 1
          mainTitleTree3: treeView[2][0] || "",
          subTitleTree3: treeView[2][1] || "",
          contentTree3: treeView[2][2] || "",
          // treeView[3] --> Additional Session 2
          mainTitleTree4: treeView[3][0] || "",
          subTitleTree4: treeView[3][1] || "",
          contentTree4: treeView[3][2] || "",

          image: value.image,
          url: slugify(value.url, { lower: true }),
          pdfUrl: value.pdf,

          researchItemTitle: researchItemTitle,
          projectNameTitle: projectNameTitle,
          durationTitle: durationTitle,
          targetTitle: targetTitle,
          introductionTitle: introductionTitle,
          partnerOrganizationTitle: partnerOrganizationTitle,
          fundingTitle: fundingTitle,
          position: cardPosition,
        },
      };
    });

    // console.log("infoArr:", infoArr);
  }

  // infoArr.forEach((e: any) => {
  //   console.log(e);
  // });

  // Researches Home Page, two parameters include the cards data and home page url
  function ResearchesHome({
    propsArr,
    baseRoute,
  }: {
    propsArr: ResearchesInfo[];
    baseRoute: string;
  }) {
    propsArr.sort((a: any, b: any) => {
      return a.info.position - b.info.position;
    });
    return (
      <div className="research-wrapper">
        <div className="general-header">
          <Header2 url="/research" />
        </div>
        <div className="researchesHeader-container">
          <div className="bg-container">
            <img className="bg-img" src={researcher} />
          </div>
          <span className="researchHeaderParagraph-container">
            {data[0] && (
              <div
                className="Researches-title"
                dangerouslySetInnerHTML={{
                  __html: data[0].contents[0 * 2 + languageLabel].content,
                }}
              >
                {/* <button onClick={() => {console.log(data[0].contents[0])}}>hello</button> */}
              </div>
            )}

            {data[0] && (
              <div
                className="Researches-paragraph"
                dangerouslySetInnerHTML={{
                  __html: data[0].contents[1 * 2 + languageLabel].content,
                }}
              ></div>
            )}
          </span>
        </div>

        <div className="researches-container">
          {propsArr.map((infoArr, id) => (
            <ResearchesCard props={infoArr} baseRoute={baseRoute} key={id} />
          ))}
        </div>
        <Footer />
      </div>
    );
  }

  // Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    if (infoArr.length) {
      setIsLoading(false);
    }
  }, [infoArr]);
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  //[page][section] 研究page
  // Route between different research subpage and research homepage
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<ResearchesHome propsArr={infoArr} baseRoute={baseRoute} />}
        />
        {infoArr.map((researches, id) => (
          <React.Fragment key={id}>
            <Route
              path={`/${researches.info.url}`}
              element={
                <ResearchesSub
                  props={researches}
                  baseRoute={baseRoute}
                  key={id}
                />
              }
            />
            <Route
              path="*"
              element={
                <h3 style={{ textAlign: "center" }}>404 : Page Not Found</h3>
              }
            />
          </React.Fragment>
        ))}
      </Routes>
    </div>
  );
}

export default Researches;
