import React, { useEffect, useState } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux/es/hooks/useSelector";
import BeatLoader from "react-spinners/BeatLoader";
import "./WhatIsACT.css";
import Footer from "../Footer/Footer";
import SectionBar from "./SectionBar";
import Header2 from "../Header2/Header2";
import axios from "axios";
import config from "../config";
import API from "../helper/ApiHelper";
import test from "./Image.webp";
import frame from "./Frame 2458.webp";
import ArrowPrev from "./Larrow_black.webp";
import ArrowNext from "./Rarrow_black.webp";
import ArrowPrevHover from "./Larrow_yelo.webp";
import ArrowNextHover from "./Rarrow_yelo.webp";
import h1 from "./h1.webp";
import h2 from "./h2.webp";
import h3 from "./h3.webp";
import h4 from "./h4.webp";
import h5 from "./h5.webp";
import h6 from "./h6.webp";
import downloadPDF from "./PDFbutton.webp";
import downloadPDFEN from "./pdf_en.png";
import article1 from "./article (1).webp";
import article2 from "./article (2).webp";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

function WhatIsACT() {
  const [scrollPositions, setScrollPositions] = useState(0 as number);
  const [prevBtnSrc, setPrevBtnSrc] = useState(ArrowPrev);
  const [backBtnSrc, setBackBtnSrc] = useState(ArrowNext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [topData, setTopData] = useState([] as any[]);
  const [middleData, setMiddleData] = useState([] as any[]);
  const [cardData, setCardData] = useState([] as any[]);
  const [bottomData, setBottomData] = useState([] as any[]);
  const [bottomCardData, setBottomCardData] = useState([] as any[]);
  const [imageData, setImageData] = useState([] as any[]);
  // control the language zh_hant or en
  const [langLabel, setLangLabel] = useState(1 as number);
  const language = useSelector((state: RootState) => state.language.language);

  const [isIOS, setIsIOS] = useState(false);
  const [isGestureCardUpdated, setIsGestureCardUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOS = Boolean(
      userAgent.match(/iPhone|iPad|iPod/i) ||
      (userAgent.match(/Mac/) && navigator.maxTouchPoints > 2)
    );
    setIsIOS(isIOS);
  }, []);

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const [lanString, setLanString] = useState([] as any);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLanString("/ch");
    } else {
      setLanString("/en");
    }
  }, [language]);
  const getData = () => {
    const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent(orderBy: $orderBy) {
              title
              session
              template
              images (orderBy: $imagesOrderBy2,where:$imagesWhere2){
                url
                priority
                category
                id
                title
                type
                isDeleted
              }
              id
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                priority
                id
                content
                category
                position
                title
                type
                langLabel{
                  lang
                  id
                  index
                }
                isDeleted
              }

              pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
                originalFileName
                position
                priority
                title
                url
                category
                id
                isDeleted
              }
              sessionImage
            }
          }
        }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput!,$imagesWhere2:ImageDetailWhereInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "What Is ACT" },
        pdfWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        pdfOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    // Get backend data using Axios
    axios({
      url: API.local, // Reference in ApiHelper
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (res.data.data.menuBar.parentContent) {
          // temp filter out the new data first, suppose to add this into each card using category to match which card display position
          let cardPositionArr = [] as any;
          res.data.data.menuBar.parentContent.forEach((e: any) => {
            for (let i = e.contents.length - 1; i >= 0; i--) {
              if (e.contents[i].position === "cardPosition") {
                cardPositionArr.push(e.contents[i]);
                e.contents.splice(i, 1);
              }
            }
          });
          // console.log("stored new data in here:", cardPositionArr);
          let receivedData = res.data.data.menuBar.parentContent;
          let tempImg: any[] = [[], [], []];

          // console.log(receivedData);
          if (receivedData.length) {
            receivedData.forEach((part: any, index: number) => {
              // console.log(index);
              // console.log(part);
              let tempArray: any[] = [[] as any[], [] as any[]];
              let tempCard: any[] = [[] as any[], [] as any[]];
              let tempCardData: any[] = [[] as any[], [] as any[]];
              let tempPdf: any[] = [];
              //tempCard = tempCardData = [];

              // sort image to correct position
              if (part.images.length) {
                part.images.sort((a: any, b: any) => {
                  const aNum = parseInt(a.priority);
                  const bNum = parseInt(b.priority);

                  if (aNum > bNum) return 1;
                  if (aNum < bNum) return -1;

                  return 0;
                });
                tempImg[index] = part.images;
              }

              // sort pdf
              if (part.pdf.length) {
                part.pdf.sort((a: any, b: any) => {
                  const aNum = parseInt(a.priority);
                  const bNum = parseInt(b.priority);

                  if (aNum > bNum) return 1;
                  if (aNum < bNum) return -1;

                  return 0;
                });
                tempPdf = part.pdf;
              }

              // sort content to correct position
              if (part.contents.length) {
                part.contents.sort((a: any, b: any) => {
                  const aNum = parseInt(a.priority);
                  const bNum = parseInt(b.priority);

                  if (aNum > bNum) return 1;
                  if (aNum < bNum) return -1;

                  return 0;
                });
              }

              // group it by lang
              part.contents.forEach((e: any, idx: number) => {
                switch (index) {
                  case 0:
                    if (e.langLabel.length) {
                      switch (e.langLabel[0].lang) {
                        case "en":
                          tempArray[0].push(e);
                          break;
                        case "zh_hant":
                          tempArray[1].push(e);
                          break;
                        default:
                      }
                    } else {
                      tempArray[0].push(e);
                      tempArray[1].push(e);
                    }
                    break;
                  case 1:
                  case 2:
                    if (
                      e.position.includes("sessionTitle") ||
                      e.position.includes("content")
                    ) {
                      if (e.langLabel.length) {
                        switch (e.langLabel[0].lang) {
                          case "en":
                            tempArray[0].push(e);
                            break;
                          case "zh_hant":
                            tempArray[1].push(e);
                            break;
                          default:
                        }
                      } else {
                        tempArray[0].push(e);
                        tempArray[1].push(e);
                      }
                    } else {
                      if (e.langLabel.length) {
                        switch (e.langLabel[0].lang) {
                          case "en":
                            tempCardData[0].push(e);
                            break;
                          case "zh_hant":
                            tempCardData[1].push(e);
                            break;
                          default:
                        }
                      } else {
                        tempCardData[0].push(e);
                        tempCardData[1].push(e);
                      }
                    }

                    // group the card data and the image url
                    tempCardData.forEach((c: any, i: number) => {
                      let combinedArr = c.concat(tempImg[index]);
                      if (index == 2) {
                        combinedArr = combinedArr.concat(tempPdf);
                      }
                      const tempGroupCard = combinedArr.reduce(
                        (acc: any, obj: any) => {
                          const key = obj.category;
                          if (!acc[key]) {
                            acc[key] = [];
                          }
                          acc[key].push(obj);
                          return acc;
                        },
                        []
                      );
                      let temp: any[] = [];
                      for (let card in tempGroupCard) {
                        switch (index) {
                          case 1:
                            if (tempGroupCard[card].length == 4) {
                              if (
                                tempGroupCard[card][0].content != "" &&
                                tempGroupCard[card][1].content != "" &&
                                tempGroupCard[card][2].content != "" &&
                                tempGroupCard[card][3].url != ""
                              ) {
                                temp.push(tempGroupCard[card]);
                              }
                            }
                            break;
                          case 2:
                            if (tempGroupCard[card].length == 6) {
                              if (
                                tempGroupCard[card][0].content != "" &&
                                tempGroupCard[card][1].content != "" &&
                                tempGroupCard[card][4].url != ""
                              ) {
                                temp.push(tempGroupCard[card]);
                              }
                            } else {
                              let count = 0;
                              tempGroupCard[card].forEach((missData: any) => {
                                let title = missData.title.split(" ");
                                switch (title[0]) {
                                  case "Title":
                                  case "Description":
                                  case "Sponsor":
                                  case "Year":
                                  case "Image":
                                  case "Card":
                                    if (missData.content != "") {
                                      count += 1;
                                    }
                                    break;
                                  default:
                                }
                              });
                              if (count == 5) {
                                tempGroupCard[card].push({ url: "" });
                                temp.push(tempGroupCard[card]);
                              }
                            }
                            // console.log(tempGroupCard);
                            break;
                          default:
                        }
                      }
                      tempCard[i] = temp;
                    });
                    break;
                  default:
                }
              });
              if (cardPositionArr.length > 0) {
                // to match the card category and push the cardPosition to the card arr

                cardPositionArr.forEach((e: any) => {
                  tempCard.forEach((cardElement: any) => {
                    cardElement.forEach((card: any) => {
                      if (e.category === card[0].category) {
                        card.push(e);
                      }
                    });
                  });
                });

                // to sort by the cardPosition to re-order the card order
                tempCard.forEach((e) => {
                  if (e.length > 0) {
                    e.sort((a: any, b: any) => {
                      // console.log(a);
                      if (a && b) {
                        return (
                          +a[a.length - 1].content - +b[b.length - 1].content
                        );
                      }
                      return 0;
                    });
                  }
                });
              }

              switch (index) {
                case 0:
                  setTopData(tempArray);
                  break;
                case 1:
                  setMiddleData(tempArray);
                  setCardData(tempCard);
                  break;
                default:
                  setBottomData(tempArray);
                  setBottomCardData(tempCard);
              }

              // console.log(tempCard);
              // console.log(tempArray);
            });
            // store image
            setImageData(tempImg);
          }
        }
      })
      .catch((err) => console.error("err", err));
  };

  useEffect(() => {
    setIsGestureCardUpdated(true);
    const tid = setTimeout(() => {
      setIsGestureCardUpdated(false);
    }, 350);
    return () => {
      clearTimeout(tid);
    };
  }, [scrollPositions]);

  // console.log(bottomCardData)

  // let textareaHeight = 0
  // const elements = document.getElementsByClassName('what-is-card-body') as HTMLCollectionOf<HTMLElement>;
  // for (let i = 0; i < elements.length; i++){
  //   if (elements[i].clientHeight> textareaHeight){
  //     textareaHeight=elements[i].clientHeight;
  //   }
  // }
  // for (let i = 0; i < elements.length; i++) {
  //   elements[i].style.height = `${textareaHeight}px`;
  // }

  const handlePrevClick = () => {
    if (scrollPositions == 0) {
      setScrollPositions(cardData?.[langLabel]?.length - 1);
      // console.log(cardData);
    } else {
      setScrollPositions(scrollPositions - 1);
    }
  };

  const handleNextClick = () => {
    if (scrollPositions == cardData?.[langLabel]?.length - 1) {
      setScrollPositions(0);
    } else {
      setScrollPositions(scrollPositions + 1);
    }
  };

  function onMouseOverPrevButton(e: any) {
    if (!isIOS) {
      if (e.currentTarget?.disabled === true) return;
      setPrevBtnSrc(ArrowPrevHover);
    }
  }

  function onMouseOutPrevButton(e: any) {
    setPrevBtnSrc(ArrowPrev);
  }

  function onMouseOverBackButton(e: any) {
    if (!isIOS) {
      if (e.currentTarget?.disabled === true) return;
      setBackBtnSrc(ArrowNextHover);
    }
  }

  function onMouseOutBackButton(e: any) {
    setBackBtnSrc(ArrowNext);
  }

  // change language label
  useEffect(() => {
    if (language === "zh_hant") {
      setLangLabel(1);
    } else {
      setLangLabel(0);
    }
  }, [language]);

  useEffect(() => {
    getData();

    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(imageData);
  // console.log(topData);
  // console.log(middleData);
  // console.log("cardData", cardData);
  // console.log(bottomData);
  // console.log(bottomCardData);

  // loading page
  const [loadingText, setLoadingText] = useState("Loading");
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "接納與承諾治療 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "What Is ACT? - ACTuwise"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (topData.length && middleData.length && bottomData.length) {
      setIsLoading(false);
    }
  }, [topData, middleData, bottomData, langLabel]);

  // avoid change language error
  if (langLabel) {
    if (language == "zh_hant") {
      loading = false;
    }
  } else {
    if (language == "en") {
      loading = false;
    }
  }
  if (isLoading || loading) {
    return <Loading loadingText={loadingText} />;
  }
  //[page] [section] 接納與承諾 page
  return (
    <div className="what-is-wrapper">
      <div className="general-header">
        <Header2 url="/what-is-act" />
      </div>
      <div className="what-is-content-section">
        {topData.length && middleData.length && bottomData.length ? (
          bottomData?.[langLabel]?.[0]?.content == "" ? (
            <SectionBar
              sections={[
                {
                  name: topData?.[langLabel]?.[0]?.content,
                  url: "/what-is-act#what-is-top-section",
                },
                {
                  name: middleData?.[langLabel]?.[0]?.content,
                  url: "/what-is-act#what-is-middle-section",
                },
              ]}
            />
          ) : (
            <SectionBar
              sections={[
                {
                  name: topData?.[langLabel]?.[0]?.content,
                  url: "/what-is-act#what-is-top-section",
                },
                {
                  name: middleData?.[langLabel]?.[0]?.content,
                  url: "/what-is-act#what-is-middle-section",
                },
                {
                  name: bottomData?.[langLabel]?.[0]?.content,
                  url: "/what-is-act#what-is-bottom-section",
                },
              ]}
            />
          )
        ) : (
          <></>
        )}

        {topData.length && imageData[0].length ? (
          <div className="what-is-container">
            <div
              id="what-is-top-section"
              className="what-is-content what-is-top-section"
            >
              <h1>{topData?.[langLabel]?.[0]?.content}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: topData?.[langLabel]?.[1]?.content,
                }}
              />
              {imageData?.[0]?.[0]?.url.length ? (
                <>
                  <img
                    src={`${API.getImage}${imageData?.[0]?.[0]?.url}`}
                    alt={""}
                  />
                  <p className="img-title">
                    {topData?.[langLabel]?.[2]?.content}
                  </p>
                </>
              ) : (
                <></>
              )}
              {imageData?.[0]?.[1]?.url.length ? (
                <>
                  <img
                    src={`${API.getImage}${imageData?.[0]?.[1]?.url}`}
                    alt={""}
                  />
                  <p className="img-title">
                    {topData?.[langLabel]?.[3]?.content}
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        {middleData.length ? (
          <div className="what-is-container-middle">
            <div className="what-is-container">
              <div
                id="what-is-middle-section"
                className="what-is-content what-is-middle-section"
              >
                <h1>{middleData?.[langLabel]?.[0]?.content}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: middleData?.[langLabel]?.[1]?.content,
                  }}
                />
                <img
                  className="what-is-middle-section-img"
                  src={frame}
                  alt={""}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: middleData?.[langLabel]?.[2]?.content,
                  }}
                />
                <div className="what-is-card-container">
                  {cardData.length && (
                    <div className="what-is-card-inner-container">
                      <button
                        className="what-is-card-button-left"
                        onClick={(e) => handlePrevClick()}
                        onMouseOver={(e) => onMouseOverPrevButton(e)}
                        onMouseOut={(e) => onMouseOutPrevButton(e)}
                        onTouchStart={(e) => onMouseOverPrevButton(e)}
                        onTouchEnd={(e) => onMouseOutPrevButton(e)}
                      >
                        <img src={prevBtnSrc} alt="Previous" />
                      </button>
                      <div
                        className={`what-is-card-content ${isGestureCardUpdated ? "fadeIn" : ""
                          }`}
                      >
                        <h2 className="what-is-card-title">
                          {
                            cardData?.[langLabel]?.[scrollPositions]?.[0]
                              ?.content
                          }
                        </h2>
                        <div className="what-is-card-body">
                          <div className="what-is-card-img-container">
                            <img
                              src={`${API.getImage}${cardData?.[langLabel]?.[scrollPositions]?.[3]?.url}`}
                              alt={""}
                            />
                          </div>
                          <div className="what-is-card-text-container">
                            <p>
                              <strong>
                                {langLabel ? "手勢：" : "Hand Gesture:"}
                              </strong>{" "}
                              {
                                cardData?.[langLabel]?.[scrollPositions]?.[1]
                                  ?.content
                              }
                            </p>
                            <p>
                              <strong>
                                {langLabel ? "意思：" : "Symbolising:"}
                              </strong>{" "}
                              {
                                cardData?.[langLabel]?.[scrollPositions]?.[2]
                                  ?.content
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="what-is-card-button-right"
                        onClick={(e) => handleNextClick()}
                        onMouseOver={(e) => onMouseOverBackButton(e)}
                        onMouseOut={(e) => onMouseOutBackButton(e)}
                        onTouchStart={(e) => onMouseOverBackButton(e)}
                        onTouchEnd={(e) => onMouseOutBackButton(e)}
                      >
                        <img src={backBtnSrc} alt="Next" />
                      </button>
                    </div>
                  )}
                  <div className="article-button-container">
                    {cardData?.[langLabel]?.map((data: any, index: number) => (
                      <img
                        className="article-button"
                        key={index}
                        src={index === scrollPositions ? article1 : article2}
                        alt={`Image ${index}`}
                      />
                    ))}
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: middleData?.[langLabel]?.[3]?.content,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {bottomData.length ? (
          bottomData?.[langLabel]?.[0]?.content != "" ? (
            <div className="what-is-container">
              <div
                id="what-is-bottom-section"
                className="what-is-content what-is-bottom-section"
              >
                <h1>{bottomData?.[langLabel]?.[0]?.content}</h1>
                {bottomCardData.length && (
                  <div className="what-is-evidence-container">
                    {bottomCardData?.[langLabel].map(
                      (card: any[], index: number) => (
                        <div
                          className="what-is-evidence-content-container"
                          key={index}
                        >
                          <div>
                            {card?.[4]?.url.length ? (
                              <img
                                className="what-is-evidence-content-image"
                                src={`${API.getImage}${card?.[4]?.url}`}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="what-is-evidence-content-text">
                            <h5>{card?.[0]?.content}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: card?.[1]?.content,
                              }}
                            />
                            {card?.[2]?.content || card?.[3]?.content ? (
                              <div className="what-is-evidence-content-text-time">
                                <p>{card?.[2]?.content} </p>
                                {card?.[2]?.content && card?.[3]?.content ? (
                                  <p>|</p>
                                ) : (
                                  <></>
                                )}
                                <p>{card?.[3]?.content}</p>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* <p>
                            {card?.[2]?.content} | {card?.[3]?.content}
                          </p> */}
                            {card?.[5]?.url.length ? (
                              <a
                                href={`${API.getPDF}${card?.[5]?.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    language === "en"
                                      ? downloadPDFEN
                                      : downloadPDF
                                  }
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="what-is-container" style={{ height: "0.01px" }}>
              <div
                id="what-is-bottom-section"
                className="what-is-content what-is-bottom-section"
              >
                <h1>{bottomData?.[langLabel]?.[0]?.content}</h1>
                {bottomCardData.length && (
                  <div className="what-is-evidence-container">
                    {bottomCardData?.[langLabel].map(
                      (card: any[], index: number) => (
                        <div
                          className="what-is-evidence-content-container"
                          key={index}
                        >
                          <div>
                            {card?.[4]?.url.length ? (
                              <img
                                className="what-is-evidence-content-image"
                                src={`${API.getImage}${card?.[4]?.url}`}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="what-is-evidence-content-text">
                            <h5>{card?.[0]?.content}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: card?.[1]?.content,
                              }}
                            />
                            <p>
                              {card?.[2]?.content} | {card?.[3]?.content}
                            </p>
                            {card?.[5]?.url.length ? (
                              <a
                                href={`${API.getPDF}${card?.[5]?.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    language === "en"
                                      ? downloadPDFEN
                                      : downloadPDF
                                  }
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default WhatIsACT;
