import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
  language: string;
}

const initialState: LanguageState = {
  language: localStorage.getItem("language") || "en",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setEnglish(state) {
      state.language = "en";
      localStorage.setItem("language", "en");
    },
    setChinese(state) {
      state.language = "zh_hant";
      localStorage.setItem("language", "zh_hant");
    },
  },
});

export const { setEnglish, setChinese } = languageSlice.actions;
export default languageSlice.reducer;
