import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

//customized menu
export const StyledMenu: any = styled((props: any) => (
    <Menu
        elevation={0}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left', 
        //   // horizontal: props.MenuListProps.boxPos
        // }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            // horizontal: props.MenuListProps.boxPos
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        // marginTop: theme.spacing(1),
        border: "1px #e2c067 solid",
        minWidth: 180,
        backgroundColor: "#F4E2AD",
        // color: 'rgb(0,0,0)',
        // backgroundColor: 'yellow',
        //color:
        //theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        // boxShadow:
        //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        // '& .MuiMenu-list': {
        //   padding: '4px 0',
        // },
        '& .MuiMenuItem-root': {
            backgroundColor: "transparent",
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                // backgroundColor: alpha(
                //     theme.palette.primary.main,
                //     theme.palette.action.selectedOpacity,
                // ),
                // textDecoration: "underline",
            },
            '&:hover': {
                textDecoration: "underline",
                backgroundColor: "transparent",
            },
        },
    },
}));