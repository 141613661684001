import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./AboutUs/AboutUs";
import "./App.css";
import Collaborator from "./Collaborator/Collaborator";
import FGACT from "./FGACT/FGACT";
import Login from "./Login/Login";
import Team from "./Team/Team";
// import Module from "./Module/Module";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import Cooperation from "./Cooperation/Cooperation";
import Counselor from "./Counselor/Counselor";
import { fetchFooterData } from "./Footer/fetchFooterData";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import GeneralPage, { GeneralPageDetail } from "./GeneralPage/GeneralPage";
import GetInvolved from "./GetInvolved/GetInvolved";
import Internship from "./Internship/Internship";
import Landing from "./Landing/Landing";
import ModuleFive from "./Module/ModuleFive";
import ModuleFour from "./Module/ModuleFour";
import ModuleThree from "./Module/ModuleThree";
import ModuleTwo from "./Module/ModuleTwo";
import Publicity from "./Publicity/Publicity";
import Recruitment from "./Recruitment/Recruitment";
import Researches from "./Researches/ResearchesTest";
import ScrollToTop from "./ScrollToTop";
import Training from "./Training/Training";
import UserProfile from "./UserProfile/UserProfile";
import WhatIsACT from "./WhatIsACT/WhatIsACT";
import { RootState } from "./store";
// import News from "./News/News";
import Poppage from "./Poppage/Poppage";
import config from "./config";
import Loading from "./Loading/Loading";
import { App as StoreApp } from "./Store/App"

export type StackParams = {
  AboutUs: string;
  // Home: string;
  Login: string;
  Team: string;
  Card2: string;
  Profile: string;
  Collaborator: string;
  Card3: string;
  FGACT: string;
  Researches: string;
  Module: string;
  Counselor: string;
  Publicity: string;
  News: string;

  GetInvolvedPage: string;
  WhatIsACT: string;
  Forget: string;
  UserProfile: string;
  Landing: string;
};
function App() {
  // const [role, setRole] = React.useState("" as string);
  const [pageDetails, setPageDetails] = useState<GeneralPageDetail[]>();
  // const [newsimage, setNewsimage] =useState<NewsImageDetail[]>([]as any);
  const role = useSelector((state: RootState) => state.role.role);
  const language = useSelector((state: RootState) => state.language.language);
  const [loadingText, setLoadingText] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  // console.log(newsimage)

  useEffect(() => {
    // fetch page details and set the state
    fetchFooterData((links, pageDetails) => {
      setPageDetails(pageDetails);
    });
    document.title = "ACTuwise";
  }, []);
  React.useEffect(() => {
    if (role != "") {
      localStorage.setItem("logging", "true");
    } else {
      localStorage.removeItem("logging");
      localStorage.removeItem("nickname");
    }
  });

  // console.log(role);
  // console.log(pageDetails);

  useEffect(() => {
    //fetch news image and set the pop
    //   let newsimage:NewsImageDetail[]=[]as any;
    //   fetchNewsData((News)=>{
    //     newsimage=News;
    //     if(newsimage[0]?.url===""){
    //       dispatch(setPopFalse())
    //     }
    // })
    if (language === "zh_hant") {
      setLoadingText("加載中");
    } else {
      setLoadingText("Loading");
    }
    setIsLoading(true);
    if (pageDetails) {
      setIsLoading(false);
    }
  }, [pageDetails, language]);

  if (isLoading) {
    return (
      <Loading loadingText={loadingText} />
    );
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <nav></nav>
      <Routes>
        {/* TODO: Dunno what is this comment, may be remove this later on */}
        {/* <Route path="/" element={<Navigate to="/main/home" replace />}> */}

        {/* Check enable of language - traditional chinese */}
        {language === "zh_hant" && config.isEnableLangTradChi ?
          (
            <Route path="/" element={<Navigate to="/ch/home/" replace />}></Route>
          ) :
          (<Route path="/" element={<Navigate to="/en/home/" replace />}></Route>)}

        {config.isEnableLangTradChi && pageDetails?.map((page, id) => {
          const path = `${page.url}`;
          // console.log("generalpaths","/ch" + path);
          return (
            <Route
              key={id}
              path={"/ch" + path}
              element={<GeneralPage generalPageDetail={page} key={id} />}
            />
          );
        })}
        {pageDetails?.map((page, id) => {
          const path = `${page.url}`;
          return (
            <Route
              key={id}
              path={"/en" + path}
              element={<GeneralPage generalPageDetail={page} key={id} />}
            />
          );
        })}

        {/* TODO: Dunno what is this comment, may be remove this later on */}
        {/* <Route path="/main" element={<Header />}> */}

        {/* About us - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/about-us" element={<AboutUs />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/about-us/team-lead" element={<Team />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/about-us/our-collaborators" element={<Collaborator />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/about-us/our-people" element={<Counselor />}></Route>}
        {/* About us - EN */}
        <Route path="/en/about-us" element={<AboutUs />}></Route>
        <Route path="/en/about-us/team-lead" element={<Team />}></Route>
        <Route path="/en/about-us/our-collaborators" element={<Collaborator />}></Route>
        <Route path="/en/about-us/our-people" element={<Counselor />}></Route>

        {/* Research - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/research/*" element={<Researches baseRoute={"/ch/research"} />}></Route>}
        {/* Research - EN */}
        <Route path="/en/research/*" element={<Researches baseRoute={"/en/research"} />}></Route>

        {/* News and Event - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/news-and-events/*" element={<Publicity baseRoute={"/ch/news-and-events"} />}></Route>}
        {/* News and Event - EN */}
        <Route path="/en/news-and-events/*" element={<Publicity baseRoute={"/en/news-and-events"} />}></Route>

        {/* Professional training - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/professional-training/*" element={<Training baseRoute="/ch/professional-training" />}></Route>}
        {/* Professional training - EN */}
        <Route path="/en/professional-training/*" element={<Training baseRoute="/en/professional-training" />}></Route>

        {/* TODO: Test this part: Login as participant */}
        {role === "participant" ? (
          <>
            {/* Guided ACT module for parent - CH */}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents" element={<FGACT />}></Route>}
            {/* Guided ACT module for parent - EN */}
            <Route path="/en/guided-act-modules-for-parents" element={<FGACT />}></Route>

            {/* Guided ACT module (1 - 4) - CH */}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module1" element={<ModuleTwo />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module2" element={<ModuleThree />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module3" element={<ModuleFour />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module4" element={<ModuleFive />}></Route>}
            {/* Guided ACT module (1 - 4) - EN */}
            <Route path="/en/guided-act-modules-for-parents/module1" element={<ModuleTwo />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module2" element={<ModuleThree />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module3" element={<ModuleFour />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module4" element={<ModuleFive />}></Route>
          </>
        ) : (
          <>
            {/* Guided ACT module for parent - CH */}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents" element={<Poppage />}></Route>}
            {/* Guided ACT module for parent - EN */}
            <Route path="/en/guided-act-modules-for-parents" element={<Poppage />}></Route>

            {/* Guided ACT module (1 - 4) - CH */}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module1" element={<Poppage />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module2" element={<Poppage />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module3" element={<Poppage />}></Route>}
            {config.isEnableLangTradChi && <Route path="/ch/guided-act-modules-for-parents/module4" element={<Poppage />}></Route>}
            {/* Guided ACT module (1 - 4) - EN */}
            <Route path="/en/guided-act-modules-for-parents/module1" element={<Poppage />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module2" element={<Poppage />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module3" element={<Poppage />}></Route>
            <Route path="/en/guided-act-modules-for-parents/module4" element={<Poppage />}></Route>
          </>
        )}

        {/* Login - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/login" element={<Login />}></Route>}
        {/* Login - EN */}
        <Route path="/en/login" element={<Login />}></Route>

        {/* User profile - CH */}
        {role && config.isEnableLangTradChi ? (
          <Route path="/ch/user-profile" element={<UserProfile />}></Route>
        ) : (
          <></>
        )}
        {/* User profile - EN */}
        {role ? (
          <Route path="/en/user-profile" element={<UserProfile />}></Route>
        ) : (
          <></>
        )}

        {/* forget password - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/forget-password" element={<ForgetPassword />}></Route>}
        {/* forget password - EN */}
        <Route path="/en/forget-password" element={<ForgetPassword />}></Route>

        {/* Home - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/home" element={<Landing />}></Route>}
        {/* Home - EN */}
        <Route path="/en/home" element={<Landing />}></Route>

        {/* What is ACT - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/what-is-act" element={<WhatIsACT />}></Route>}
        {/* What is ACT - EN */}
        <Route path="/en/what-is-act" element={<WhatIsACT />}></Route>
        
        {/* Get involved - CH */}
        {config.isEnableLangTradChi && <Route path="/ch/get-involved" element={<GetInvolved />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/get-involved/collaboration" element={<Cooperation />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/get-involved/volunteer-and-internship" element={<Internship />}></Route>}
        {config.isEnableLangTradChi && <Route path="/ch/get-involved/position-available" element={<Recruitment />}></Route>}
        {/* Get involved - EN */}
        <Route path="/en/get-involved" element={<GetInvolved />}></Route>
        <Route path="/en/get-involved/collaboration" element={<Cooperation />}></Route>
        <Route path="/en/get-involved/volunteer-and-internship" element={<Internship />}></Route>
        <Route path="/en/get-involved/position-available" element={<Recruitment />}></Route>
        {/* Frontend controller to redirect traffic to corresponding store */}
        <Route path="/store/app" element={<StoreApp />}></Route>
        
        <Route path="*" element={<h3 style={{ textAlign: "center" }}>404 : Page Not Found</h3>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

{
  /* ) : (
          <></>
        )} */
}
{
  /* <Route path="/CardSlider" element={<CardSlider />}></Route> */
}
{
  /* <Route path="/Publicity_sub" element={<Publicity_sub />}></Route> */
}

{
  /* 
        <
        <Route path="/landing" element={<Landing />}> </Route> */
}
{
  /* <Route path="/train" element={<Training />}></Route> */
}
{
  /* <Route path="/Training_sub" element={<Training_sub />}></Route> */
}
{
  /* 
        <
        <Route path="/landing" element={<Landing />}> </Route> */
}
{
  /* <Route path="/train" element={<Training />}></Route> */
}
{
  /* <Route path="/Training_sub" element={<Training_sub />}></Route> */
}
{
  /* <Route path="/moduletest" element={<Module/>}></Route> */
}
{
  /* </Route> */
}
{
  /* * <Route
          path="*"
          element={
            <h3 style={{ textAlign: "center" }}>404 : Page Not Found</h3>
          }
        /> */
}
