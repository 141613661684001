import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface News {
  PopImage: string;
}

const initialState: News = {
  PopImage: localStorage.getItem("Pop") || "true",
};

const NewsSlice = createSlice({
  name: "Pop",
  initialState,
  reducers: {
    setPopTrue(state) {
      state.PopImage='true';
      localStorage.setItem("Pop",'true');
    },
    setPopFalse(state) {
      state.PopImage='false';
      localStorage.setItem("Pop",'false');
    },
  },
});

export const {setPopTrue,setPopFalse} = NewsSlice.actions;
export default NewsSlice.reducer;
