import axios from "axios";
import config from "../config";
import { NewsImageDetail } from "./News";
import { resolve } from "path";
import API from "../helper/ApiHelper";

const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

const ImageSet = new Set<NewsImageDetail>();
let isFetched = false;
let fetchDataPromise: Promise<[NewsImageDetail[]]> | null =
  null;

export function fetchNewsData(
  callback:(News:NewsImageDetail[])=>void
){
  if(isFetched){
    const News =Array.from(ImageSet);
    callback(News);
    return;
  }

  if(fetchDataPromise){
    fetchDataPromise.then(([News])=>{
      callback(News);
    });
    return
  }

  fetchDataPromise = new Promise((resolve,reject)=>{
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2,where:$imagesWhere2) {
          url
          priority
          category
          id
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
          priority
          id
          content
          category
          position
          langLabel{
            id
            lang
            index
          }
          isDeleted
        }
        sessionImage
      }
    }
  }`;
        const graphqlQuery = {
        operationName: "Query",
        query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!) ${request}`,
        variables: {
          where: { title: "News" },
          contentsWhere2: {
            isDeleted: {
              equals: false,
            },
          },
          imagesWhere2: {
            isDeleted: {
              equals: false,
            },
          },

          orderBy: [
            {
              session: "asc",
            },
          ],
          contentsOrderBy2: [
            {
              priority: "asc",
            },
          ],
          imagesOrderBy2: [
            {
              priority: "asc",
            },
          ],
        },
        };
        axios({
          url: config.user_base_url,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        }).then((res) => {
          let counter=0;
          ImageSet.add({
            url:res.data.data.menuBar.parentContent[0].images[0].url
          })
          counter++;
          if(counter===1){
            const News =Array.from(ImageSet);
            callback(News);
            isFetched= true;
            resolve([News])
          }

        })
        .catch((err) => {
          console.error("err", err);
          reject(err);
        });

  })
}

