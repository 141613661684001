import React, { useState, useEffect, useRef } from "react";
import Footer from "../Footer/Footer";
import ArrowPrev from "./prevBtn.webp";
import ArrowNext from "./nextBtn.webp";
import scrollToTopBtn from "./Group 193.webp";
import scrollToTopBtnInversed from "./Group_2357.webp";
import ArrowPrevHover from "./Group 2351.webp";
import ArrowNextHover from "./Group 2352.webp";
import "./Publicity.css";
import Header2 from "../Header2/Header2";
import { PublicityCardsProps } from "./PublicityCard";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import API from "../helper/ApiHelper";
import ApiHelper from "../helper/ApiHelper";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Publicity_sub from "../Publicity_sub/Publicity_sub";
import { BeatLoader } from "react-spinners";
import GreyLeft from "./grey_left.webp";
import GreyRight from "./grey_right.webp";
import rect2 from "./rect2.webp";
import slugify from "slugify";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";
import usePrevious from "../helper/UsePrevious"
import { CSSTransition } from 'react-transition-group'

//Data for Publicity page, session 1 contain the page title (Publicity) and three subtitles (news, radio, broadcast interview)
// sesseion1 has all the cards and session1Date contains all the dates for cards
interface PublicityData {
  session1: PublicityCardsProps[];
  session2: PublicityCardsProps[];
  session2Date: string[];
  session3: PublicityCardsProps[];
  session3Date: string[];
  session4: PublicityCardsProps[];
  session4Date: string[];
  pageTitle: string[];
  newsTitle: string[];
  radioTitle: string[];
  interviewTitle: string[];
}

function Publicity({ baseRoute }: { baseRoute: string }) {
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();
  const [isIOS, setIsIOS] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const language = useSelector((state: RootState) => state.language.language);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardPositionArr, setCardPositionArr] = useState([] as any);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOS = Boolean(
      userAgent.match(/iPhone|iPad|iPod/i) ||
      (userAgent.match(/Mac/) && navigator.maxTouchPoints > 2)
    );
    setIsIOS(isIOS);
  }, []);
  // fetch data from backend
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getData(): Promise<PublicityCardsProps[]> {
    const request = `{menuBar(where: $where) {
    id,
    title,
    parentContent(orderBy: $orderBy) {
      title
      session
      template
      images (orderBy: $imagesOrderBy2,where:$imagesWhere2){
        url
        priority
        category
        id
        title
        type
        isDeleted
      }
      id
      contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
        priority
        id
        content
        category
        position
        title
        type
        langLabel {
          id
          lang
          index
        }
        isDeleted
      }
      videos(orderBy: $videosOrderBy2,where: $videosWhere2) {
        url
        priority
        category
        title
        id
        type
        isDeleted
      }
      audios (orderBy: $audiosOrderBy2,where: $audiosWhere2) {
        url
        priority
        category
        title
        id
        type
        isDeleted
      }
      sessionImage
    }
  }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!, $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,$audiosWhere2: AudioDetailWhereInput!,$videosWhere2: VideoDetailWhereInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,

      variables: {
        where: { title: "Publicity" },
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        videosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        audiosWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    //mapping raw data from backend to be in the form of PublicityCardsProps
    return new Promise((resolve, reject) => {
      axios({
        url: API.local,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          // console.log(res.data.data.menuBar)
          let positionArr = [] as any;
          res.data.data.menuBar.parentContent.forEach((e: any) => {
            for (let i = e.contents.length - 1; i >= 0; i--) {
              if (e.contents[i].position === "cardPosition") {
                positionArr.push(e.contents[i]);
                e.contents.splice(i, 1);
              }
            }
          });
          const data = res.data.data.menuBar.parentContent.map(
            (cardData: PublicityCardsProps) => {
              const contentsWithDate = cardData.contents.filter(
                (content) => content.position === "date"
              );
              const pageTitleContents = cardData.contents.filter(
                (content) => content.position === "pageTitle"
              );
              const pageTitle = pageTitleContents.map(
                (pageTitleContent) => pageTitleContent.content
              );
              return {
                id: cardData.id,
                contents: cardData.contents.map((content) => {
                  return {
                    category: content.category,
                    content: content.content,
                    id: content.id,
                    position: content.position,
                    priority: content.priority,
                    langLabel: content.langLabel.map((label) => {
                      return {
                        id: label.id,
                        lang: label.lang,
                        index: label.index,
                      };
                    }),
                  };
                }),
                images:
                  cardData.images && cardData.images.length > 0
                    ? cardData.images.map((image) => {
                      return {
                        category: image.category,
                        id: image.id,
                        priority: image.priority,
                        url: image.url,
                        position: image.position,
                        title: image.title,
                        type: image.type,
                      };
                    })
                    : [],
                audios:
                  cardData.audios && cardData.audios.length > 0
                    ? cardData.audios.map((audio) => {
                      return {
                        category: audio.category,
                        id: audio.id,
                        priority: audio.priority,
                        url: audio.url,
                        position: audio.position,
                        title: audio.title,
                        type: audio.type,
                      };
                    })
                    : [],
                videos:
                  cardData.videos && cardData.videos.length > 0
                    ? cardData.videos.map((video) => {
                      return {
                        category: video.category,
                        id: video.id,
                        priority: video.priority,
                        url: video.url,
                        position: video.position,
                        title: video.title,
                        type: video.type,
                      };
                    })
                    : [],
                session: cardData.session,
                sessionImage: cardData.sessionImage,
                template: cardData.template,
                title: cardData.title,
                date: contentsWithDate.length === 0 ? contentsWithDate : "",
                pageTitle: pageTitle.length != 0 ? pageTitle : "",
                positionArr: positionArr,
              };
            }
          );
          res.data.data.menuBar.parentContent.forEach((cardData: any) => {
            cardData.images.forEach((image: any) => {
              image.url = `${API.getImage}${image.url}`;
            });
          });
          // console.log(data);
          // setCardPositionArr(positionArr);
          resolve(data);
        })
        .catch((error) => {
          // setIsLoading(false);
          reject(error);
        });
    });
  }

  const [publicityData, setPublicityData] = useState<PublicityData>({
    session1: [],
    session2: [],
    session2Date: [],
    session3: [],
    session3Date: [],
    session4: [],
    session4Date: [],
    pageTitle: [],
    newsTitle: [],
    radioTitle: [],
    interviewTitle: [],
  });

  //classify the mapped data to different cards according to their category in each session
  useEffect(() => {
    getData()
      .then((data: PublicityCardsProps[]) => {
        const cardByCategory: Record<string, PublicityCardsProps> = {};

        data.forEach((card) => {
          card.contents.forEach((content) => {
            let cardPosition = -1;
            card.positionArr.forEach((position) => {
              if (position.category === content.category.split("@@")[0]) {
                cardPosition = +position.content;
              }
            });
            const category = content.category
              .split("@@")[0]
              .replace("Publicity_card_", "");

            if (!cardByCategory[category]) {
              cardByCategory[category] = {
                id: card.id,
                contents: [],
                images: card.images.filter(
                  (image) =>
                    image.category
                      .split("@@")[0]
                      .replace("Publicity_card_", "") === category &&
                    image.url !== ""
                ),
                audios: card.audios.filter(
                  (audio) =>
                    audio.category
                      .split("@@")[0]
                      .replace("Publicity_card_", "") === category
                ),
                videos: card.videos.filter(
                  (video) =>
                    video.category
                      .split("@@")[0]
                      .replace("Publicity_card_", "") === category
                ),
                session: card.session,
                sessionImage: card.sessionImage,
                template: card.template,
                title: card.title,
                date: card.date,
                pageTitle: card.pageTitle,
                position: cardPosition,
                positionArr: [],
              };
            }
            let pinnedIsPinnedRelatedItems = content.position == 'pinned';
            let pinnedUnpinnedRelatedItems = content.position === "" && content.content == 'false';
            // only add into "contents" if is not related to pinned items
            if (!pinnedIsPinnedRelatedItems && !pinnedUnpinnedRelatedItems) {
              cardByCategory[category].contents.push({
                id: content.id,
                position: content.position,
                content: content.content,
                priority: content.priority,
                langLabel: content.langLabel,
                category: content.category,
              });
            }

          });
        });

        // find the dates for each card
        const classifiedData = Object.values(cardByCategory).reduce(
          (result, card) => {
            switch (card.session) {
              case 1:
                result.session1.push(card);
                break;
              case 2:
                const dateContents2 = card.contents.find(
                  (content) => content.position === "date"
                )?.content;
                if (dateContents2) result.session2Date.push(dateContents2);
                else result.session2Date.push("");
                if (
                  card?.contents[0]?.position === "title" &&
                  card?.contents[0]?.content !== ""
                  && card?.contents[1]?.position === "title" &&
                  card?.contents[1]?.content !== ""
                ) {
                  result.session2.push(card);
                }

                break;
              case 3:
                const dataContents3 = card.contents.find(
                  (content) => content.position === "date"
                )?.content;
                if (dataContents3) result.session3Date.push(dataContents3);
                else result.session3Date.push("");
                if (
                  card?.contents[0]?.position === "title" &&
                  card?.contents[0]?.content !== "" &&
                  card?.contents[1]?.position === "title" &&
                  card?.contents[1]?.content !== ""
                )
                  result.session3.push(card);
                break;
              case 4:
                const dataContent4 = card.contents.find(
                  (content) => content.position === "date"
                )?.content;
                if (dataContent4) result.session4Date.push(dataContent4);
                else result.session4Date.push("");
                if (
                  card?.contents[0]?.position === "title" &&
                  card?.contents[0]?.content !== "" &&
                  card?.contents[1]?.position === "title" &&
                  card?.contents[1]?.content !== ""
                )
                  result.session4.push(card);
                break;
              default:
                // console.warn(`Unexpected session value: ${card.session}`);
                break;
            }
            card.contents.forEach((content) => {
              switch (content.position) {
                case "pageTitle":
                  result.pageTitle.push(content.content);
                  break;
                case "newsTitle":
                  result.newsTitle.push(content.content);
                  break;
                case "radioTitle":
                  result.radioTitle.push(content.content);
                  break;
                case "broadcastInterviewTitle":
                  result.interviewTitle.push(content.content);
                  break;
                default:
                  break;
              }
            });

            return result;
          },
          {
            session1: [],
            session2: [],
            session2Date: [],

            session3: [],
            session3Date: [],
            session4: [],
            session4Date: [],
            pageTitle: [],
            newsTitle: [],
            radioTitle: [],
            interviewTitle: [],
          } as {
            session1: PublicityCardsProps[];
            session2: PublicityCardsProps[];
            session2Date: string[];
            session3: PublicityCardsProps[];
            session3Date: string[];
            session4: PublicityCardsProps[];
            session4Date: string[];
            pageTitle: string[];
            newsTitle: string[];
            radioTitle: string[];
            interviewTitle: string[];
          }
        );
        classifiedData?.session2?.forEach((card, index) => {
          card.date = classifiedData.session2Date[index];
        });
        classifiedData?.session3?.forEach((card, index) => {
          card.date = classifiedData.session3Date[index];
        });
        classifiedData?.session4?.forEach((card, index) => {
          card.date = classifiedData.session4Date[index];
        });

        classifiedData?.session2?.sort((b, a) => a.position - b.position);
        classifiedData?.session3?.sort((b, a) => a.position - b.position);
        classifiedData?.session4?.sort((b, a) => a.position - b.position);
        setPublicityData(classifiedData);
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log(cardPositionArr);
  }, []);

  const PublicityHome = ({
    baseRoute,
    publicityData,
  }: {
    baseRoute: string;
    publicityData: PublicityData;
  }) => {
    const [radioArrayIndex, setRadioArrayIndex] = useState<number>(0);
    const prevRadioArrayIndex = usePrevious(radioArrayIndex)
    const [radioIndexChanged, setRadioIndexChanged] = useState<boolean>(false)

    const [newsArrayIndex, setNewsArrayIndex] = useState<number>(0);
    const prevNewsArrayIndex = usePrevious(newsArrayIndex)
    const [newsIndexChanged, setNewsIndexChanged] = useState<boolean>(false)

    const [interviewArrayIndex, setInterviewArrayIndex] = useState<number>(0);
    const prevInterviewArrayIndex = usePrevious(interviewArrayIndex)
    const [interviewIndexChanged, setInterviewIndexChanged] = useState<boolean>(false)

    // const [currentWebUrl, setCurrentWebUrl] = useState(
    //   window.location.href.toLowerCase().startsWith("http://localhost:3000")
    //     ? window.location.href.split("3000").at(-1)
    //     : window.location.href.split("com").at(-1)
    // );

    // const [currentUrl, setCurrentUrl] = useState(
    //   window.location.href.toLowerCase().startsWith("http://localhost:3000")
    //     ? window.location.href.split("3000").at(-1)
    //     : window.location.href.split("com").at(-1)
    // );
    const dispatch = useDispatch();

    const [languageLabel, setLanguageLabel] = useState(1 as number);
    useEffect(() => {
      if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "en") {
        dispatch(setEnglish());
      } else {
        dispatch(setChinese());
      }
      // if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "en") {
      //   dispatch(setEnglish());
      // } else {
      //   dispatch(setChinese());
      // }
      if (language === "zh_hant") {
        setLanguageLabel(0);
      } else if (language === "en") {
        setLanguageLabel(1);
      }
    }, [language]);
    const ref = useRef<HTMLImageElement>(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [cardsPerRow, setCardsPerRow] = useState(3 as number);
    // const totalRows = Math.ceil(cards.length / cardsPerRow);
    const currentUrl = window.location.pathname;
    const [btnSrc, setBtnSrc] = useState(scrollToTopBtn);
    const [prevBtnSrc, setPrevBtnSrc] = useState([
      ArrowPrev,
      ArrowPrev,
      ArrowPrev,
    ]);
    const [backBtnSrc, setBackBtnSrc] = useState([
      ArrowNext,
      ArrowNext,
      ArrowNext,
    ]);

    useEffect(() => {
      setNewsIndexChanged(true)
      const tid = setTimeout(() => {
        setNewsIndexChanged(false)
      }, 350)
      return () => { clearTimeout(tid) }
    }, [newsArrayIndex])

    useEffect(() => {
      setRadioIndexChanged(true)
      const tid = setTimeout(() => {
        setRadioIndexChanged(false)
      }, 350)
      return () => { clearTimeout(tid) }
    }, [radioArrayIndex])

    useEffect(() => {
      setInterviewIndexChanged(true)
      const tid = setTimeout(() => {
        setInterviewIndexChanged(false)
      }, 350)
      return () => { clearTimeout(tid) }
    }, [interviewArrayIndex])

    function handleWindowResize() {
      if (ref?.current?.offsetHeight) setHeight(ref.current.offsetHeight);
      if (ref?.current?.offsetWidth) setWidth(ref.current.offsetWidth);
      if (ref?.current?.offsetWidth) {
        const width = ref.current.offsetWidth;
        if (width <= 768) setCardsPerRow(1);
        if (width > 768 && width <= 1200) setCardsPerRow(2);
        if (width > 1200) setCardsPerRow(3);
      }
    }

    useEffect(() => {
      handleWindowResize();
      const getRoadMapSize = setInterval(() => {
        if (!ref?.current?.offsetHeight) return;
        setHeight(ref.current.offsetHeight);
        clearInterval(getRoadMapSize);
      }, 100);

      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, []);
    useEffect(() => {
      if (language === "zh_hant") {
        document.title = "活動新知 - ACTuwise"; // Update the document title on mount
      } else {
        document.title = "News & Event - ACTuwise"; // Update the document title on mount
      }
    }, [language]);
    const handlePrevClick = (
      arrayIndex: number,
      currentIndex: number,
      array: PublicityCardsProps[],
      setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
    ) => {
      // let sectionNumber = arrayIndex + 1
      // TriggerSection(sectionNumber)

      const newIndex = Math.max(0, currentIndex - 1);
      if (array.length <= cardsPerRow) {
        setCurrentIndex(0);
      } else if (newIndex === currentIndex) {
        setCurrentIndex(array.length - cardsPerRow);
      } else {
        setCurrentIndex(newIndex);
      }
      // console.log(currentIndex);
    };
    const handleNextClick = (
      arrayIndex: number,
      currentIndex: number,
      array: PublicityCardsProps[],
      setCurrentIndex: (index: number) => void
    ) => {
      // let sectionNumber = arrayIndex + 1
      // TriggerSection(sectionNumber)

      const maxIndex = array.length - cardsPerRow;
      const newIndex = currentIndex + 1;
      if (array.length <= cardsPerRow) {
        setCurrentIndex(0);
      } else if (newIndex > maxIndex) {
        setCurrentIndex(maxIndex);
      } else {
        setCurrentIndex(newIndex);
      }
    };

    function handleOnClick(url: string) {
      if (currentUrl === url) {
        return;
      }
      if (url == "#") setBtnSrc(scrollToTopBtn);
      window.scrollTo(0, 0);
    }

    function onMouseOutButton() {
      setBtnSrc(scrollToTopBtn);
    }
    function onMouseOverButton() {
      setBtnSrc(scrollToTopBtnInversed);
    }

    function onMouseOverPrevButton(e: any, index: number) {
      if (e.currentTarget?.disabled === true) return;
      const tmp = [...prevBtnSrc];
      tmp[index] = ArrowPrevHover;

      setPrevBtnSrc(tmp);
    }

    function onMouseOutPrevButton(e: any, index: number) {
      const tmp = [...prevBtnSrc];
      tmp[index] = ArrowPrev;
      setPrevBtnSrc(tmp);
    }

    function onMouseOverBackButton(e: any, index: number) {
      if (e.currentTarget?.disabled === true) return;
      const tmp = [...backBtnSrc];
      tmp[index] = ArrowNextHover;
      setBackBtnSrc(tmp);
    }

    function onMouseOutBackButton(e: any, index: number) {
      const tmp = [...backBtnSrc];
      tmp[index] = ArrowNext;
      setBackBtnSrc(tmp);
    }

    interface DisplayCardsProps {
      cards: PublicityCardsProps[];
      currentIndex: number;
      baseRoute: string;
      sectionnumber: number;
      setCurrentIndex: (index: number) => void;
    }

    function DisplayCards({
      cards,
      currentIndex,
      baseRoute,
      sectionnumber,
      setCurrentIndex,
    }: DisplayCardsProps) {
      const start = currentIndex;
      const end =
        start + cardsPerRow > cards.length ? cards.length : start + cardsPerRow;
      const whatsnewpart = sectionnumber;   // Section 1 (News), 2 (Broadcast Interviews), 3 (Press Releases)

      function handleCardClick(card: PublicityCardsProps) {
        const title = card.contents[1].content;
        const slug = slugify(title, { lower: true });
        const path = `${baseRoute}/${slug}-${card.contents[1].id}`;
        navigate(path);
      }

      function getSectionIndexChanged(sectionNumber: number) {
        switch (sectionNumber) {
          case 1: return newsIndexChanged
          case 2: return radioIndexChanged
          case 3: return interviewIndexChanged
          default: return false
        }
      }


      return (
        <div className="scrollContainer" key={`section-${whatsnewpart}`}>
          {cards.slice(start, end).map((card, cardIndex) => {
            let enWidth: number = 0;
            if (width < 424) {
              enWidth = 45;
            } else if (width < 600) {
              enWidth = 80;
            } else if (width < 871) {
              enWidth = 45;
            } else if (width < 1057) {
              enWidth = 80;
            } else if (width < 1200) {
              enWidth = 95;
            } else if (width < 1318) {
              enWidth = 45;
            } else {
              enWidth = 80;
            }
            const maxLength = languageLabel === 0 ? 25 : enWidth;
            const content = card.contents[languageLabel].content;
            const truncatedContent = content; // Disable the ... handling
            // content.length > maxLength
            //   ? content.substring(0, maxLength) + "..."
            //   : content;
            if (
              card.contents &&
              card.contents[languageLabel] &&
              card.contents[languageLabel].content &&
              card.contents[languageLabel].content !== "" &&
              card.images?.length > 0
            ) {
              return (
                <div
                  className={`cardContentContainer ${getSectionIndexChanged(whatsnewpart) ? 'fadeIn' : ''}`}
                  key={`section-${whatsnewpart}-card-${cardIndex}`}
                  onClick={() => handleCardClick(card)}
                >
                  <>
                    {whatsnewpart === 1 ? (
                      <div className="whats-cardImage-container">
                        {card.images.length > 0 && (
                          <img
                            className="whats-cardImage"
                            src={`${ApiHelper.getImage}${card.images[0].url}`}
                            alt={card.title}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="cardImage-container">
                        {card.images.length > 0 && (
                          <img
                            className="cardImage"
                            src={`${ApiHelper.getImage}${card.images[0].url}`}
                            alt={card.title}
                          />
                        )}
                      </div>
                    )}
                    {/* <div className="cardImage-container">
                      {card.images.length > 0 && (
                        <img
                          className="cardImage"
                          src={`${ApiHelper.getImage}${card.images[0].url}`}
                          alt={card.title}
                        />
                      )}
                    </div> */}

                    {card.contents &&
                      card.contents[languageLabel] &&
                      card.contents[languageLabel].content && (
                        <h3 className="cardTitle">
                          {
                            // card.contents[languageLabel].content.length > 24
                            //   ? card.contents[languageLabel].content.substring(
                            //       0,
                            //       25
                            //     ) + "..."
                            //   :
                            truncatedContent
                          }
                        </h3>
                      )}
                    <p className="cardTime">{card.date}</p>
                  </>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    }
    const renderPrevButton = (
      index: number,
      currentIndex: number,
      setCurrentIndex: React.Dispatch<React.SetStateAction<number>>,
      cardsArray: PublicityCardsProps[]
    ) => {
      const totalPage = Math.ceil(cardsArray.length - cardsPerRow + 1);
      // const currentPage = Math.ceil(currentIndex / cardsPerRow) + 1;
      const currentPage = Math.ceil(currentIndex + 1);
      function pageNum() {
        return (
          <div className={"publicty-card-page-number-container"}>
            <div className={"publicty-card-page-number"}>
              {currentPage} / {totalPage}
            </div>

            <img src={rect2}></img>
          </div>
        );
      }
      return (
        <div className="pagination-container">
          {cardsArray.length > cardsPerRow && (
            <>
              {
                <button
                  className="imgControlBtn"
                  disabled={currentIndex === 0}
                  key={`prevBtn-${index}`}
                  onClick={() =>
                    handlePrevClick(
                      index,
                      currentIndex,
                      cardsArray,
                      setCurrentIndex
                    )
                  }
                // Temp disable because this affect our animation
                // {...(!isIOS && {
                //   onMouseOver: (e) => onMouseOverPrevButton(e, index),
                //   onMouseOut: (e) => onMouseOutPrevButton(e, index),
                //   onTouchStart: (e) => onMouseOverPrevButton(e, index),
                //   onTouchEnd: (e) => onMouseOutPrevButton(e, index),
                // })}
                >
                  {currentPage !== 1 ? (
                    <img src={prevBtnSrc[index]} alt="Previous" />
                  ) : (
                    <img src={GreyLeft} alt="Previous" />
                  )}
                </button>
              }
              <div className="page-number-container">{pageNum()}</div>
            </>
          )}
        </div>
      );
    };
    const renderNextButton = (
      index: number,
      currentIndex: number,
      setCurrentIndex: React.Dispatch<React.SetStateAction<number>>,
      cardsArray: PublicityCardsProps[]
    ) => {
      return (
        cardsArray.length > cardsPerRow && (
          <button
            className="imgControlBtn"
            key={`nextBtn-${index}`}
            disabled={currentIndex >= cardsArray.length - cardsPerRow}
            onClick={() =>
              handleNextClick(index, currentIndex, cardsArray, setCurrentIndex)
            }
          // Temp disable because this affect our animation
          // {...(!isIOS && {
          //   onMouseOver: (e) => onMouseOverBackButton(e, index),
          //   onMouseOut: (e) => onMouseOutBackButton(e, index),
          //   onTouchStart: (e) => onMouseOverBackButton(e, index),
          //   onTouchEnd: (e) => onMouseOutBackButton(e, index),
          // })}
          >
            {/* <img src={backBtnSrc[index]} alt="Next" /> */}
            {currentIndex !== cardsArray.length - cardsPerRow ? (
              <img src={backBtnSrc[index]} alt="Next" />
            ) : (
              <img src={GreyRight} alt="next" />
            )}
          </button>
        )
      );
    };
    //[page] [section] 最新動態 page
    return (
      <>
        <div className="publicity-page">
          <div className="general-header">
            <Header2 url="/news-and-events" />
          </div>
          {
            <div className="pageContainer" id="promotion" ref={ref}>
              <div className="pageContentContainer">
                <div className="scrollTop">
                  <div className="containerWrapper">
                    <div className="pageTitle">
                      {/* {isLoading ? <div className="loading">Loading...</div> : ""} */}
                      <h1 className="publicity-title">
                        {publicityData.pageTitle[languageLabel]}
                      </h1>
                    </div>
                    {[0, 1, 2].map((index) => (
                      <div className="cardContainer" key={`cardContainer-${index}`}>
                        {index === 0 && (
                          <h2 className="publicity-subtitle">
                            {publicityData.newsTitle[languageLabel]}
                          </h2>
                        )}
                        {index === 1 && (
                          <h2 className="publicity-subtitle">
                            {publicityData.radioTitle[languageLabel]}
                          </h2>
                        )}
                        {index === 2 && (
                          <h2 className="publicity-subtitle">
                            {publicityData.interviewTitle[languageLabel]}
                          </h2>
                        )}
                        {/* [section] 最新動態cards*/}
                        {index === 0 && (
                          <DisplayCards
                            baseRoute={baseRoute}
                            cards={publicityData.session2}
                            currentIndex={newsArrayIndex}
                            setCurrentIndex={setNewsArrayIndex}
                            sectionnumber={1}
                          />
                        )}

                        {index === 1 && (
                          <DisplayCards
                            baseRoute={baseRoute}
                            cards={publicityData.session3}
                            currentIndex={radioArrayIndex}
                            setCurrentIndex={setRadioArrayIndex}
                            sectionnumber={2}
                          />
                        )}
                        {index === 2 && (
                          <DisplayCards
                            baseRoute={baseRoute}
                            cards={publicityData.session4}
                            currentIndex={interviewArrayIndex}
                            setCurrentIndex={setInterviewArrayIndex}
                            sectionnumber={3}
                          />
                        )}
                        <div className="arrowBtnContainer">
                          {index === 0 &&
                            renderPrevButton(
                              index,
                              newsArrayIndex,
                              setNewsArrayIndex,
                              publicityData.session2
                            )}
                          {index === 1 &&
                            renderPrevButton(
                              index,
                              radioArrayIndex,
                              setRadioArrayIndex,
                              publicityData.session3
                            )}
                          {index === 2 &&
                            renderPrevButton(
                              index,
                              interviewArrayIndex,
                              setInterviewArrayIndex,
                              publicityData.session4
                            )}

                          {index === 0 &&
                            renderNextButton(
                              index,
                              newsArrayIndex,
                              setNewsArrayIndex,
                              publicityData.session2
                            )}
                          {index === 1 &&
                            renderNextButton(
                              index,
                              radioArrayIndex,
                              setRadioArrayIndex,
                              publicityData.session3
                            )}
                          {index === 2 &&
                            renderNextButton(
                              index,
                              interviewArrayIndex,
                              setInterviewArrayIndex,
                              publicityData.session4
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {
                    <div id="publicity-scroll-to-top">
                      <img
                        src={btnSrc}
                        onClick={() => handleOnClick("#")}
                        {...(!isIOS && {
                          onMouseOver: () => onMouseOverButton(),
                          onMouseOut: () => onMouseOutButton(),
                          onTouchStart: () => onMouseOverButton(),
                          onTouchEnd: () => onMouseOutButton(),
                        })}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          }
          <Footer />
        </div>
      </>
    );
  };
  useEffect(() => {
    if (language === "zh_hant") {
      setLoadingText("加載中");
    } else {
      setLoadingText("Loading");
    }
  }, [language]);
  //Loading page
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (
      publicityData.session2.length &&
      publicityData.session3.length &&
      publicityData.session4.length
    ) {
      setIsLoading(false);
    }
  }, [publicityData]);
  {
    /* light yellow #f5df8f */
  }
  {
    /* navy blue #1A237E */
  }
  {
    /* grey #BDBDBD*/
  }
  {
    /* dark green #1E5631 */
  }
  {
    /* light pink #F8BBD0 */
  }
  if (isLoading) {
    return (
      <Loading loadingText={loadingText} />
    );
  }
  return (
    <div>
      <Routes>
        <Route
          //publicity main page route
          path="/"
          element={
            <PublicityHome
              baseRoute={baseRoute}
              publicityData={publicityData}
            />
          }
        />

        {/* dynamically generate url for each card in session2, the same for the following */}
        {publicityData?.session2?.map((publicity, id) => {
          const title = publicity.contents[1].content;
          const slug = slugify(title, { lower: true });
          const path = `/${slug}-${publicity.contents[1].id}`;

          return (
            <Route
              key={id}
              path={path}
              element={
                <Publicity_sub
                  cards={publicity}
                  baseRoute={baseRoute}
                  key={id}
                  innerURL={path}
                />
              }
            />
          );
        })}
        {publicityData?.session3?.map((publicity, id) => {
          const title = publicity.contents[1].content;
          const slug = slugify(title, { lower: true });
          const path = `/${slug}-${publicity.contents[1].id}`;

          return (
            <Route
              key={id}
              path={path}
              element={
                <Publicity_sub
                  cards={publicity}
                  baseRoute={baseRoute}
                  key={id}
                  innerURL={path}
                />
              }
            />
          );
        })}
        {publicityData?.session4?.map((publicity, id) => {
          const title = publicity.contents[1].content;
          const slug = slugify(title, { lower: true });
          const path = `/${slug}-${publicity.contents[1].id}`;

          return (
            <Route
              key={id}
              path={path}
              element={
                <Publicity_sub
                  cards={publicity}
                  baseRoute={baseRoute}
                  key={id}
                  innerURL={path}
                />
              }
            />
          );
        })}

        <Route path="*" element={<h3 className="page404">404 Not Found</h3>} />
      </Routes>
    </div>
  );
}

export default Publicity;
