import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Login from "./Login/Login";
// import Home from './Homepage/Home/Home'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store'
import ReactGA from "react-ga4";
import config from "./config";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
ReactGA.initialize(config.googleAnalyticsApiKey);
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <Provider store={store}>
    <App />
    </Provider>
    {/* </BrowserRouter> */}

    {/* <Login /> */}
  </React.StrictMode>
);
const SendAnalytics = () => {
  ReactGA._gaCommandSendPageview(
    window.location.pathname,
    window.location.search
  );
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
