import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserNameState {
  nickname: string;
}

const initialState: UserNameState = {
  nickname: localStorage.getItem('nickname') || "",
};

const nicknameSlice = createSlice({
  name: 'nickname',
  initialState,
  reducers: {
    setNickname(state, action){
      state.nickname=action.payload;
      localStorage.setItem('nickname',action.payload);
    },
  },
});

export const { setNickname, } = nicknameSlice.actions;
export default nicknameSlice.reducer;
