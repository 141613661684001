import React, { useState, useEffect } from "react";
import "./Training_sub.css";
import Header2 from "../Header2/Header2";
import Footer from "../Footer/Footer";
import test from "./test.jpg";
import pdf from "./PDF1.webp";
import pdfen from "../WhatIsACT/pdf_en.png";
import alarm from "./alarm.webp";
import calendar from "./calendar-alt.webp";
import globe from "./globe.webp";
import pin from "./pin.webp";
import ticket from "./ticket.webp";
import { useLocation } from "react-router-dom";
import { TrainingInfo } from "../TrainingCard/TrainingCard";
import BeatLoader from "react-spinners/BeatLoader";
import slugify from "slugify";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

interface TrainingSubProps {
  info: TrainingInfo;
}

const TrainingDetail = ({
  props,
  baseRoute,
}: {
  props: TrainingInfo;
  baseRoute: string;
}) => {
  const location = useLocation();
  const info = props.info;
  useEffect(() => {
    console.log(info);
  }, []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loadingText, setLoadingText] = useState("Loading");
  const language = useSelector((state: RootState) => state.language.language);
  const dispatch = useDispatch();
  const [languageLabel, setLanguageLabel] = useState(1 as number);
  const [currentUrl, setCurrentUrl] = useState(
    // window.location.href.toLowerCase().startsWith("http://localhost:3000")
    //   ? window.location.href.split("3000").at(-1)
    //   : window.location.href.split("com").at(-1)
    location.pathname
  );
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "en") {
      dispatch(setEnglish());
      // } else if (currentUrl?.match(/^\/([a-z]{2})(\/|$)/i)?.[1] === "ch") {
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      setLanguageLabel(0);
    } else {
      setLoadingText("Loading");
      setLanguageLabel(1);
    }
  }, [language]);
  useEffect(() => {
    document.title = filterHtmlTags(props.info.title) + " - ACTuwise"; // Update the document title on mount
  }, [language]);
  useEffect(() => {
    document.title = filterHtmlTags(props.info.title) + " - ACTuwise"; // Update the document title on mount
  }, [languageLabel]);
  useEffect(() => {
    document.title = filterHtmlTags(props.info.title) + " - ACTuwise"; // Update the document title on mount
  });
  function filterHtmlTags(str: string) {
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  });
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="holder training_sub_wrapper">
      <div className="general-header">
        <Header2
          url={
            "/professional-training/" +
            slugify(info.link, { lower: true }) +
            "-" +
            info.id
          }
        />
      </div>
      <div className="training-sub-content-wrapper">
        <h1
          id="training-detail-title"
          dangerouslySetInnerHTML={{ __html: info.title }}
        ></h1>

        <div id="training-detail-container">
          <div
            style={{ textAlign: "left" }}
            dangerouslySetInnerHTML={{ __html: info.description }}
          ></div>
          <img id="training-detail-image" src={info.innerImage} alt="" />

          <div
            id="training-detail-description"
            dangerouslySetInnerHTML={{ __html: info.paragraph }}
          ></div>

          <div id="training-detail-bottom">
            <div id="training-detail-card">
              {info.time !== null &&
                info.time !== "" &&
                typeof info.time !== "undefined" && (
                  <div className="training-detail">
                    <img
                      className="training-detail-card-icon"
                      src={alarm}
                      alt=""
                    />
                    <div>{info.time}</div>
                  </div>
                )}
              {info.date !== null &&
                info.date !== "" &&
                typeof info.date !== "undefined" && (
                  <div className="training-detail">
                    <img
                      className="training-detail-card-icon"
                      src={calendar}
                      alt=""
                    />
                    <div>{info.date}</div>
                  </div>
                )}
              {info.organization !== null &&
                info.organization !== "" &&
                typeof info.organization !== "undefined" && (
                  <div className="training-detail">
                    <img
                      className="training-detail-card-icon"
                      src={globe}
                      alt=""
                    />
                    <div>{info.organization}</div>
                  </div>
                )}
              {info.specialty !== null &&
                info.specialty !== "" &&
                typeof info.specialty !== "undefined" && (
                  <div className="training-detail">
                    <img className="training-detail-card-icon" src={pin} alt="" />
                    <div>{info.specialty}</div>
                  </div>
                )}
              {info.event !== null &&
                info.event !== "" &&
                typeof info.event !== "undefined" && (
                  <div className="training-detail">
                    <img
                      className="training-detail-card-icon"
                      src={ticket}
                      alt=""
                    />
                    <div>{info.event}</div>
                  </div>
                )}
            </div>

            <div id="training-detail-bottom-right">
              {info.pdf !== null &&
                info.pdf !== "" &&
                typeof info.pdf !== "undefined" &&
                info.pdf !== "https://cms.actweb.dev.hkrnd.com/upload/pdf/" &&
                info.pdf !== `${process.env.REACT_APP_UPLOAD_URL}/pdf/` && (
                  <a href={info.pdf} target="_blank">
                    <img
                      id="training-sub-pdf-img"
                      src={language == "en" ? pdfen : pdf}
                      alt=""
                    />
                  </a>
                )}
              {info.url !== null &&
                info.url !== "" &&
                typeof info.url !== "undefined" && (
                  <a href={info.url} target="_blank">
                    {props.info.languageLabel === 0 ? (
                      <div className="join-event-button-container">
                        <button id="join-event-button">加入！</button>
                      </div>
                    ) : (
                      <div className="join-event-button-container">
                        <button id="join-event-button">Join Now!</button>
                      </div>
                    )}
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>          
      <Footer />
    </div>
  );
};

export default TrainingDetail;
