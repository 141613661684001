import { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header2 from "../Header2/Header2";
import "./Cooperation.css";
import React from "react";
import axios from "axios";
import API from "../helper/ApiHelper";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "../store";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch } from "react-redux";
import { setEnglish, setChinese } from "../languageSlice";
import Loading from "../Loading/Loading";

function Cooperation() {
  const [data, setData] = useState([] as any);
  const [description, setDescription] = useState([] as any);
  const [card, setCard] = useState([] as any);
  const [title, setTitle] = useState([] as any);
  const [loadingText, setLoadingText] = useState("Loading");
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const dispatch = useDispatch();
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  async function getData() {
    const request = `{childMenu(where: $where) {
      id,
      title,
      childContent(orderBy: $orderBy) {
        title
        session
        template
       images(orderBy: $imagesOrderBy2,where:$imagesWhere2) {
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2, where:$contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }

        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "Cooperation" },

        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },

        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.childMenu.childContent: ",
        //   res.data.data.childMenu.childContent
        // );
        // temp filter out the new data first, suppose to add this into each card using category to match which card display position
        // let cardPositionArr = [] as any;
        // res.data.data.childMenu.childContent.forEach((e: any) => {
        //   for (let i = e.contents.length - 1; i >= 0; i--) {
        //     if (e.contents[i].position === "cardPosition") {
        //       cardPositionArr.push(e.contents[i]);
        //       e.contents.splice(i, 1);
        //     }
        //   }
        // });
        // console.log("stored new data in here:", cardPositionArr);
        let rawData = res.data.data.childMenu.childContent;
        let totalCardCount = rawData[1].contents.reduce(
          (acc: any, cur: any) => {
            if (!acc[cur.category]) {
              acc[cur.category] = 1;
            } else {
              acc[cur.category]++;
            }
            return acc;
          },
          {}
        );
        let totalCardArr = [] as any;
        Object.keys(totalCardCount).forEach((e) => {
          totalCardArr.push({
            category: e,
            title: "",
            title_zh: "",
            content: "",
            content_zh: "",
            position: null,
          });
        });
        totalCardArr.forEach((e: any) => {
          rawData[1].contents.forEach((cardElement: any) => {
            if (cardElement.category === e.category) {
              if (
                cardElement.position === "title" &&
                cardElement.langLabel[0] &&
                cardElement.langLabel[0].lang === "en"
              ) {
                e.title = cardElement.content;
              } else if (
                cardElement.position === "content" &&
                cardElement.langLabel[0] &&
                cardElement.langLabel[0].lang === "en"
              ) {
                e.content = cardElement.content;
              } else if (
                cardElement.position === "cardPosition" &&
                cardElement.langLabel[0] &&
                cardElement.langLabel[0].lang === "en"
              ) {
                e.position = +cardElement.content;
              } else if (
                cardElement.position === "title" &&
                cardElement.langLabel[0] &&
                cardElement.langLabel[0].lang === "zh_hant"
              ) {
                e.title_zh = cardElement.content;
              } else if (
                cardElement.position === "content" &&
                cardElement.langLabel[0] &&
                cardElement.langLabel[0].lang === "zh_hant"
              ) {
                e.content_zh = cardElement.content;
              }
            }
          });
        });
        totalCardArr.sort((a: any, b: any) => a.position - b.position);
        // console.log(totalCardArr);
        setData(res.data.data.childMenu.childContent);
        setDescription(res.data.data.childMenu.childContent[0].contents);
        // setCard(res.data.data.childMenu.childContent[1].contents);
        setCard(totalCardArr);
        // console.log(res.data.data.childMenu.childContent[1].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  async function getTitleData() {
    const request = `{menuBar(where: $where) {
      id,
      title,
      parentContent(orderBy: $orderBy) {
        title
        session
        template
        images (orderBy: $imagesOrderBy2, where:$imagesWhere2){
          url
          priority
          category
          id
          title
          type
          isDeleted
        }
        id
        contents(orderBy: $contentsOrderBy2, where:$contentsWhere2) {
          priority
          id
          content
          category
          position
          title
          type
          langLabel{
            lang
            id
            index
          }
          isDeleted
        }

        sessionImage
      }
    }
  }`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!) ${request}`,
      variables: {
        where: { title: "Get Involved" },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        contentsWhere2: {
          isDeleted: {
            equals: false,
          },
        },
        imagesWhere2: {
          isDeleted: {
            equals: false,
          },
        },
      },
    };

    axios({
      url: API.local,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(
        //   "res.data.data.menuBar.parentContent: ",
        //   res.data.data.menuBar.parentContent
        // );
        setTitle(res.data.data.menuBar.parentContent[1].contents);
      })
      .catch((error) => {
        console.error("Thrown Error: ", error);
      });
  }

  const language = useSelector((state: RootState) => state.language.language);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getData();
    getTitleData();
  }, []);
  useEffect(() => {
    if (currentUrl?.split("/")[3] === "en") {
      dispatch(setEnglish());
    } else {
      dispatch(setChinese());
    }
    if (language === "zh_hant") {
      setLoadingText("加載中");
      document.title = "合作機會 - ACTuwise"; // Update the document title on mount
    } else {
      setLoadingText("Loading");
      document.title = "Collaboration - ACT"; // Update the document title on mount
    }
  }, [language]);
  //Loading UI Control
  const [isLoading, setIsLoading] = useState(true);
  let loading = true;
  useEffect(() => {
    setIsLoading(true);
    if (title.length && description.length && card.length) {
      setIsLoading(false);
    }
    /*          setTimeout(() => {
    setIsLoading(false);
  }, 1500); */
  }, [title, description, card]);
  /* // avoid change language error
  if (languageLabel) {
    if (language === "zh_hant") {
      loading = false;
    }
  } else {
    if (language === "en") {
      loading = false;
    }
  } */
  if (isLoading) {
    return <Loading loadingText={loadingText} />;
  }
  return (
    <div className="wrapper">
      <div className="general-header">
        <Header2 url="/get-involved/collaboration" />
      </div>
      <div className="coo-container">
        <div id="middleSection" className="coo-content">
          <h1 className="coo-title">
            {title.map((e: any, idx: number) => {
              return (
                <div key={idx}>
                  {language === "en" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle3" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language &&
                    e.position === "subTitle3" && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </h1>
          <div className="coo-desc">
            {description.map((e: any, idx: number) => {
              // console.log(e.content);
              return (
                <div key={idx}>
                  {language === "en" && e.langLabel[0].lang === language && (
                    <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                  )}
                  {language === "zh_hant" &&
                    e.langLabel[0].lang === language && (
                      <div
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></div>
                    )}
                </div>
              );
            })}
          </div>
          {
            // <div className={"coo-contents-container"}>
            <>
              {card.map((e: any, idx: number) => {
                // console.log(e);
                return (
                  <div
                    key={"cardKey" + idx}
                    className={"coo-contents-container"}
                  >
                    <div className={"coo-callout-container"}>
                      <div className={"coo-callout-title"}>
                        {language === "zh_hant" ? e.title_zh : e.title}
                      </div>

                      <div
                        className={"coo-callout-description"}
                        style={{ marginBottom: "30px" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            language === "zh_hant" ? e.content_zh : e.content,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
              {/* <div className={"coo-callout-title"}>
                  {card.map((e: any, idx: number) => {
                    // console.log(e);
                    return (
                      <div key={idx}>
                        {language === "en" &&
                          e.langLabel[0].lang === language &&
                          e.position === "title" && (
                            // e.priority === 3 &&
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                        {language === "zh_hant" &&
                          e.langLabel[0].lang === language &&
                          e.position === "title" && (
                            // e.priority === 2 &&
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                      </div>
                    );
                  })}
                </div>
                <div>
                  {card.map((e: any, idx: number) => {
                    // console.log(e.content);
                    return (
                      <div key={idx} className={"coo-callout-description"}>
                        {language === "en" &&
                          e.langLabel[0].lang === language &&
                          e.position === "content" && (
                            // e.priority === 5 &&
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                        {language === "zh_hant" &&
                          e.langLabel[0].lang === language &&
                          e.position === "content" && (
                            // e.priority === 4 &&
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                      </div>
                    );
                  })}
                </div> */}
            </>
            // </div>
          }
          {/* {
            <div className={"coo-contents-container"}>
              <div className={"coo-callout-container"}>
                <div className={"coo-callout-title"}>
                  {card.map((e: any, idx: number) => {
                    // console.log(e.content);
                    return (
                      <div key={idx}>
                        {language === "en" &&
                          e.langLabel[0].lang === language &&
                          e.priority === 8 && (
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                        {language === "zh_hant" &&
                          e.langLabel[0].lang === language &&
                          e.priority === 7 && (
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                      </div>
                    );
                  })}
                </div>
                <div className={"coo-callout-description"}>
                  {card.map((e: any, idx: number) => {
                    // console.log(e);
                    return (
                      <div key={idx}>
                        {language === "en" &&
                          e.langLabel[0].lang === language &&
                          e.priority === 10 && (
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                        {language === "zh_hant" &&
                          e.langLabel[0].lang === language &&
                          e.priority === 9 && (
                            <div
                              dangerouslySetInnerHTML={{ __html: e.content }}
                            ></div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          } */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cooperation;

//     const currentUrl = location.pathname
//     function handleOnClick(url: string) {
//       if (currentUrl === url) {
//         return;
//       }
//       window.location.href = url;
//     }
//     const [imageArr, setImageArr] = useState([] as any);
//     const [videoArr, setVideoArr] = useState([] as any);
//     const [calloutArr, setCalloutArr] = useState([] as any);
//     const [audioArr, setAudioArr] = useState([] as any);
//     const receivedData = {
//       id: `newCreateSession_`,
//       contents: [
//           {
//               category: "Cooperation",
//               content: "合作機會",
//               id: "",
//               position: "title",
//               priority: null,
//           },
//           {
//               category: "Cooperation",
//               content:
//                   '<p style="text-align:center;"><span style="font-size: 20px;">我們歡迎與本地和國際機構進行合作，以促進心理健康的發展。 以下是不同的合作方式：</span></p>',
//               id: "",
//               position: "description",
//               priority: null,
//           },
//           {
//               category: "Cooperation",
//               content:
//                   "成為贊助者：",
//               id: "",
//               position: "card1_title",
//               priority: null,
//           },
//           {
//               category: "Cooperation",
//               content:
//                   '<p style="text-align:left;"><span>如果您對我們的活動或研究感興趣，並願意成為我們的贊助者，我們非常樂意與您合作。您的贊助將有助於我們開展更多研究和活動，為社會做出更大的貢獻。<br/><br/>如果您有任何疑問或有意成為我們的贊助者，請通過電子郵件<a href="mailto:hello@abc.com">hello@abc.com</a>與我們聯繫。我們將會向您提供更詳細的贊助信息，以及我們如何使用您的贊助來支持我們的研究和活動。非常感謝您對我們的支持，期待與您合作。</span></p>',
//               id: "",
//               position: "card1_content",
//               priority: null,
//           },
//           {
//             category: "Cooperation",
//             content:
//                 "成為合作夥伴：",
//             id: "",
//             position: "card2_title",
//             priority: null,
//         },
//         {
//             category: "Cooperation",
//             content:
//                 '<p style="text-align:left;"><span>如果您對我們的香港接納與承諾治療 (ACT) 服務有興趣並希望成為我們的合作夥伴，我們非常歡迎您與我們聯繫。我們希望能夠擴大我們的服務受眾，並與更多機構建立夥伴關係。<br/><br/>如果您有任何疑問或有意與我們合作，請通過電子郵件<a href="mailto:hello@abc.com">hello@abc.com</a>與我們聯繫。請在郵件中附上貴機構的簡短介紹及您的聯繫方式，我們將會盡快與您聯繫。期待與您建立夥伴關係，共同為香港的心理健康服務做出貢獻。</span></p>',
//             id: "",
//             position: "card2_content",
//             priority: null,
//         },
//       ],
//       images: [],
//       audios: [],
//       videos: [],
//       session: "",
//       sessionImage: "",
//       template: "",
//       title: "合作機會",
//   }
// const [data, setData] = useState<any>(receivedData);
// let id = "Cooperation" //change;

// {data.contents.find((e:any)=>e.position === "card1_title").content}
